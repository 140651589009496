import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Grow,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * Displays an overview of services tailored to the specific location.
 * Includes additional SEO meta tags for demonstration (optional).
 */
const ServicesOverview = ({ location }) => {
  // Default text (fallback)
  let sectionTitle = 'Our Landscaping Services';
  let descriptionText =
    'From lawn maintenance to landscape design, we offer a wide range of services for Wayne & Johnston County.';
  let metaTitle = 'Landscaping & Lawn Care Services';
  let metaDescription =
    'Explore the range of lawn care and landscaping services available in Wayne & Johnston County.';
  let metaKeywords =
    'landscaping, lawn care, services, design, maintenance, Wayne, Johnston County';

  // Adjust based on location
  if (location === 'goldsboro') {
    sectionTitle = 'Our Landscaping Services in Goldsboro, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the Goldsboro community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in Goldsboro, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in Goldsboro, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, Goldsboro, NC';
  } else if (location === 'clayton') {
    sectionTitle = 'Our Landscaping Services in Clayton, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the Clayton community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in Clayton, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in Clayton, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, Clayton, NC';
  } else if (location === 'smithfield') {
    sectionTitle = 'Our Landscaping Services in Smithfield, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the Smithfield community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in Smithfield, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in Smithfield, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, Smithfield, NC';
  } else if (location === 'mount-olive') {
    sectionTitle = 'Our Landscaping Services in Mount Olive, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the Mount Olive community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in Mount Olive, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in Mount Olive, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, Mount Olive, NC';
  } else if (location === 'pikeville') {
    sectionTitle = 'Our Landscaping Services in Pikeville, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the Pikeville community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in Pikeville, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in Pikeville, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, Pikeville, NC';
  } else if (location === 'la-grange') {
    sectionTitle = 'Our Landscaping Services in La Grange, NC';
    descriptionText =
      'From lawn maintenance to landscape design, we proudly serve the La Grange community with professional, faith-driven services.';
    metaTitle = 'Landscaping & Lawn Care Services in La Grange, NC';
    metaDescription =
      'Explore the range of lawn care and landscaping services available in La Grange, NC, including design, maintenance, and more.';
    metaKeywords =
      'landscaping, lawn care, services, design, maintenance, La Grange, NC';
  }

  /**
   * Updated array of six services to form a 3×2 grid on larger screens.
   * Placeholder images from https://via.placeholder.com.
   */
  const services = [
    {
      title: 'Lawn Maintenance & Mowing',
      description: `Professional lawn maintenance services including mowing, trimming, and edging for a pristine lawn in ${getLocationName(location)}. Our expert lawn care team ensures your property looks its best.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/LawnMowing%3AMaintenance.jpeg',
    },
    {
      title: 'Landscape Design & Installation',
      description: `Transform your ${getLocationName(location)} outdoor space with custom landscape designs and expert installation services. Our professional landscaping team creates beautiful, sustainable landscapes.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/landscape_desing.jpeg',
    },
    {
      title: 'Fertilization & Weed Control',
      description: `Keep your ${getLocationName(location)} lawn lush, green, and weed-free with our comprehensive fertilization and weed control treatments. Our lawn care experts use professional-grade products for optimal results.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/weed_control.jpeg',
    },
    {
      title: 'Tree & Shrub Care',
      description: `Expert tree and shrub care services including pruning, trimming, and health assessments to ensure thriving greenery in ${getLocationName(location)}. Our arborists maintain the beauty and health of your landscape.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/bush_and_tree.jpeg',
    },
    {
      title: 'Seasonal Cleanup',
      description: `Comprehensive spring and fall seasonal cleanups to keep your ${getLocationName(location)} property looking its best year-round. Our thorough cleanup services prepare your landscape for every season.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/seasonal_cleanup.jpeg',
    },
    {
      title: 'Irrigation Systems',
      description: `Professional irrigation system services to design and install custom water systems that keep your ${getLocationName(location)} landscape healthy and hydrated. Our irrigation experts ensure efficient water management.`,
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/irrigation.jpeg',
    },
  ];

  function getLocationName(loc) {
    switch(loc) {
      case 'goldsboro': return 'Goldsboro, NC';
      case 'clayton': return 'Clayton, NC';
      case 'smithfield': return 'Smithfield, NC';
      case 'four-oaks': return 'Four Oaks, NC';
      case 'selma': return 'Selma, NC';
      case 'princeton': return 'Princeton, NC';
      case 'benson': return 'Benson, NC';
      case 'pine-level': return 'Pine Level, NC';
      case 'wilsons-mills': return 'Wilson\'s Mills, NC';
      case 'mount-olive': return 'Mount Olive, NC';
      case 'pikeville': return 'Pikeville, NC';
      case 'la-grange': return 'La Grange, NC';
      default: return 'Wayne & Johnston County, NC';
    }
  }

  const getLocationData = () => {
    switch (location) {
      case 'goldsboro':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Goldsboro, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Goldsboro, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Goldsboro NC, landscaping Goldsboro, lawn maintenance Goldsboro, tree trimming Goldsboro, landscape design Goldsboro, sod installation Goldsboro, Wright Brothers Lawn Care Goldsboro',
          canonicalUrl: 'https://wrightbrotherslawncare.com/goldsboro/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Goldsboro, NC',
          subheading: 'Transform your Goldsboro outdoor space with our expert services'
        };
      case 'clayton':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Clayton, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Clayton, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Clayton NC, landscaping Clayton, lawn maintenance Clayton, tree trimming Clayton, landscape design Clayton, sod installation Clayton, Wright Brothers Lawn Care Clayton, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/clayton/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/clayton-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Clayton, NC',
          subheading: 'Transform your Clayton outdoor space with our expert services'
        };
      case 'smithfield':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Smithfield, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Smithfield, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Smithfield NC, landscaping Smithfield NC, lawn maintenance Smithfield, tree trimming Smithfield, landscape design Smithfield, Wright Brothers Lawn Care Smithfield, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/smithfield/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/smithfield-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Smithfield, NC',
          subheading: 'Transform your Smithfield outdoor space with our expert services'
        };
      case 'four-oaks':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Four Oaks, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Four Oaks, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Four Oaks NC, landscaping Four Oaks NC, lawn maintenance Four Oaks, tree trimming Four Oaks, landscape design Four Oaks, Wright Brothers Lawn Care Four Oaks, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/four-oaks/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/four-oaks-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Four Oaks, NC',
          subheading: 'Transform your Four Oaks outdoor space with our expert services'
        };
      case 'selma':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Selma, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Selma, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Selma NC, landscaping Selma NC, lawn maintenance Selma, tree trimming Selma, landscape design Selma, Wright Brothers Lawn Care Selma, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/selma/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/selma-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Selma, NC',
          subheading: 'Transform your Selma outdoor space with our expert services'
        };
      case 'princeton':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Princeton, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Princeton, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Princeton NC, landscaping Princeton NC, lawn maintenance Princeton, tree trimming Princeton, landscape design Princeton, Wright Brothers Lawn Care Princeton, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/princeton/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/princeton-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Princeton, NC',
          subheading: 'Transform your Princeton outdoor space with our expert services'
        };
      case 'benson':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Benson, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Benson, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Benson NC, landscaping Benson NC, lawn maintenance Benson, tree trimming Benson, landscape design Benson, Wright Brothers Lawn Care Benson, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/benson/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/benson-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Benson, NC',
          subheading: 'Transform your Benson outdoor space with our expert services'
        };
      case 'pine-level':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Pine Level, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Pine Level, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Pine Level NC, landscaping Pine Level NC, lawn maintenance Pine Level, tree trimming Pine Level, landscape design Pine Level, Wright Brothers Lawn Care Pine Level, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/pine-level/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/pine-level-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Pine Level, NC',
          subheading: 'Transform your Pine Level outdoor space with our expert services'
        };
      case 'wilsons-mills':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Wilson\'s Mills, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Wilson\'s Mills, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Wilson\'s Mills NC, landscaping Wilson\'s Mills NC, lawn maintenance Wilson\'s Mills, tree trimming Wilson\'s Mills, landscape design Wilson\'s Mills, Wright Brothers Lawn Care Wilson\'s Mills, Johnston County landscaping',
          canonicalUrl: 'https://wrightbrotherslawncare.com/wilsons-mills/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/wilsons-mills-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Wilson\'s Mills, NC',
          subheading: 'Transform your Wilson\'s Mills outdoor space with our expert services'
        };
      case 'mount-olive':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Mount Olive, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Mount Olive, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Mount Olive NC, landscaping Mount Olive, lawn maintenance Mount Olive, tree trimming Mount Olive, landscape design Mount Olive, Wright Brothers Lawn Care Mount Olive',
          canonicalUrl: 'https://wrightbrotherslawncare.com/mount-olive/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Mount Olive, NC',
          subheading: 'Transform your Mount Olive outdoor space with our expert services'
        };
      case 'pikeville':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Pikeville, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Pikeville, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Pikeville NC, landscaping Pikeville, lawn maintenance Pikeville, tree trimming Pikeville, landscape design Pikeville, Wright Brothers Lawn Care Pikeville',
          canonicalUrl: 'https://wrightbrotherslawncare.com/pikeville/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in Pikeville, NC',
          subheading: 'Transform your Pikeville outdoor space with our expert services'
        };
      case 'la-grange':
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in La Grange, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in La Grange, NC. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care La Grange NC, landscaping La Grange, lawn maintenance La Grange, tree trimming La Grange, landscape design La Grange, Wright Brothers Lawn Care La Grange',
          canonicalUrl: 'https://wrightbrotherslawncare.com/la-grange/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services in La Grange, NC',
          subheading: 'Transform your La Grange outdoor space with our expert services'
        };
      default:
        return {
          pageTitle: 'Professional Lawn Care & Landscaping Services in Wayne & Johnston County, NC | Wright Brothers Lawn Care',
          metaDescription: 'Expert lawn care and landscaping services in Wayne and Johnston County, NC, including Smithfield, Clayton, Four Oaks, Selma, Princeton, Benson, Pine Level, and Wilson\'s Mills. We offer lawn maintenance, landscape design, tree trimming, and more. Free quotes available!',
          metaKeywords: 'lawn care Wayne County NC, landscaping Johnston County NC, lawn maintenance Goldsboro NC, landscaping services Clayton NC, residential lawn care Smithfield NC, commercial landscaping Selma NC, garden design Four Oaks NC, Princeton NC lawn care, Benson NC landscaping, Pine Level NC lawn service, Wilson\'s Mills NC landscaping, Wright Brothers Lawn Care',
          canonicalUrl: 'https://wrightbrotherslawncare.com/services',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/hero-landscaping.jpg',
          heading: 'Professional Lawn Care & Landscaping Services',
          subheading: 'Transform your outdoor space with our expert services'
        };
    }
  };

  const locationData = getLocationData();

  return (
    <>
      {/* SEO meta tags with location data */}
      <Helmet>
        <title>{locationData.pageTitle}</title>
        <meta name="description" content={locationData.metaDescription} />
        <meta name="keywords" content={locationData.metaKeywords} />
        <link rel="canonical" href={locationData.canonicalUrl} />
        <meta property="og:title" content={locationData.pageTitle} />
        <meta property="og:description" content={locationData.metaDescription} />
        <meta property="og:image" content={locationData.ogImageUrl} />
        <meta property="og:url" content={locationData.canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={locationData.pageTitle} />
        <meta name="twitter:description" content={locationData.metaDescription} />
        <meta name="twitter:image" content={locationData.ogImageUrl} />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content={getLocationName(location)} />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="services-overview"
        sx={{
          position: 'relative',
          py: { xs: 4, md: 6 },
        }}
      >
        <Container>
          <Typography
            id="services-overview"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            {locationData.heading}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, color: '#424242' }}>
            {descriptionText}
          </Typography>

          {/* Grid of service cards (3 columns on md+) */}
          <Grid container spacing={3}>
            {services.map((service, index) => (
              <Grow
                in={true}
                timeout={(index + 1) * 400} // Staggered animation
                key={service.title}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: '100%',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={service.image}
                      alt={`${service.title} in ${getLocationName(location)}`}
                    />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {service.title}
                      </Typography>
                      <Typography variant="body2" color="#424242">
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grow>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ServicesOverview;
