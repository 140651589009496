// src/components/Layout/components/Footer.js

import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logo from '../../../../image.png'; // Adjust path as per your project structure

// Import MUI Icons for social media (Optional)
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'white', // Changed to white background
        py: 4, // Vertical padding (theme.spacing(4))
        px: { xs: 2, sm: 4, md: 6 }, // Horizontal padding responsive
        color: theme.palette.primary.main, // Changed text color to primary main (green)
      }}
    >
      <Grid container spacing={2}>

        {/* First Grid Item: Logo, Navigation Links, and Social Media */}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }} // Stack on small screens
          >
            {/* Logo Section */}
            <Box
              display="flex"
              component="a"
              href="/"
              title="Wright Brothers Lawn Care - Expert Lawn Services in Goldsboro, NC"
              sx={{
                width: { xs: 100, sm: 120 }, // Responsive logo width
                mb: { xs: 2, sm: 0 }, // Margin bottom on small screens
              }}
            >
              <Box
                component="img"
                src={logo}
                alt="Wright Brothers Lawn Care Logo"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2, // Rounded corners (theme.spacing(2))
                  objectFit: 'contain',
                }}
              />
            </Box>

            {/* Navigation Links */}
            <Box display="flex" flexWrap="wrap" alignItems="center">
              {/* Home Link */}
              <Box sx={{ mt: { xs: 1, sm: 0 }, mr: 2 }}>
                <Link
                  href="/"
                  underline="none"
                  color="inherit" // Inherit from parent (primary main)
                  variant="subtitle2"
                  title="Home - Wright Brothers Lawn Care, Goldsboro, NC"
                  sx={{
                    '&:hover': {
                      color: theme.palette.secondary.light, // Hover color
                    },
                  }}
                >
                  Home
                </Link>
              </Box>

              {/* Services Link */}
              <Box sx={{ mt: { xs: 1, sm: 0 }, mr: 2 }}>
                <Link
                  href="/services"
                  underline="none"
                  color="inherit"
                  variant="subtitle2"
                  title="Services - Wright Brothers Lawn Care, Goldsboro, NC"
                  sx={{
                    '&:hover': {
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  Services
                </Link>
              </Box>

              {/* About Us Link */}
              <Box sx={{ mt: { xs: 1, sm: 0 }, mr: 2 }}>
                <Link
                  href="/about"
                  underline="none"
                  color="inherit"
                  variant="subtitle2"
                  title="About Us - Wright Brothers Lawn Care, Goldsboro, NC"
                  sx={{
                    '&:hover': {
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  About Us
                </Link>
              </Box>

              {/* Contact Us Button */}
              <Box sx={{ mt: { xs: 1, sm: 0 } }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href="/contact"
                  sx={{
                    fontWeight: 600,
                    borderRadius: 50, // Pill-shaped button
                    px: 3, // Horizontal padding
                    py: 1, // Vertical padding
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Social Media Links (Optional) */}
          <Box display="flex" mt={2} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
            <Link
              href="https://www.facebook.com/WrightBrothersLawnandLandscaping/"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              sx={{
                color: 'inherit',
                mr: 2,
                '&:hover': {
                  color: theme.palette.secondary.light,
                },
              }}
            >
              <FacebookIcon />
            </Link>
            {/* <Link
              href="https://twitter.com/yourprofile"
              target="_blank"
              rel="noopener"
              aria-label="Twitter"
              sx={{
                color: 'inherit',
                mr: 2,
                '&:hover': {
                  color: theme.palette.secondary.light,
                },
              }}
            >
              <TwitterIcon />
            </Link>
            <Link
              href="https://instagram.com/yourprofile"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              sx={{
                color: 'inherit',
                '&:hover': {
                  color: theme.palette.secondary.light,
                },
              }}
            >
              <InstagramIcon />
            </Link> */}
          </Box>
        </Grid>

        {/* Second Grid Item: Descriptive Texts */}
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="subtitle2"
            sx={{ mb: 1 }}
          >
            &copy; {new Date().getFullYear()} Wright Brothers Lawn Care, Goldsboro, NC. All rights reserved.
          </Typography>
          <Typography
            align="center"
            variant="caption"
            component="p"
            sx={{ mb: 1 }}
          >
            Wright Brothers Lawn Care is dedicated to maintaining the beauty and health of your home or business's outdoor spaces. We proudly provide comprehensive lawn care and landscaping services to Goldsboro, NC, and surrounding areas.
          </Typography>
          <Typography
            align="center"
            variant="caption"
            component="p"
            sx={{ mb: 1 }}
          >
            By using our website, you agree to our cookie policy for a faster, safer experience and for marketing purposes. Contact us today for reliable lawn maintenance and landscaping services in Goldsboro, NC.
          </Typography>
          <Typography
            align="center"
            variant="caption"
            component="p"
            sx={{ mt: 2 }}
          >
            <Link
              href="https://skilledstrategies.com"
              target="_blank"
              rel="noopener"
              underline="hover"
              sx={{
                color: 'inherit',
                display: 'inline-flex',
                alignItems: 'center',
                '&:hover': {
                  color: theme.palette.secondary.light,
                },
              }}
            >
              Designed and Developed by Skilled Strategies LLC
              <Box
                component="span"
                sx={{ 
                  display: 'inline-flex',
                  ml: 0.5,
                  fontSize: '0.8em'
                }}
              >
                →
              </Box>
            </Link>
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Footer;
