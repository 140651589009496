import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import { Hero, Services, CommunityHighlight, WhyChooseUs, RelatedLocations } from '../Location/components';
import { useTheme } from "@mui/material/styles";
import { Typography, Container, Grid, Button, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Dedicated page for La Grange lawn care and landscaping services
 * with rich content specific to this location for improved SEO.
 */
const LaGrangeLocation = () => {
  const theme = useTheme();
  const locationName = "la-grange";
  
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>Professional Lawn Care & Landscaping Services in La Grange, NC | Wright Brothers Lawn Care</title>
        <meta 
          name="description" 
          content="Expert lawn care and landscaping in La Grange, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." 
        />
        <meta 
          name="keywords" 
          content="lawn care La Grange NC, landscaping La Grange NC, sod installation Lenoir County, commercial landscaping La Grange, turfcare services La Grange, lawn maintenance La Grange, landscape design Lenoir County, lawn fertilization La Grange, weed control La Grange, professional lawn service Lenoir County, commercial lawn care La Grange, residential landscaping La Grange, Wright Brothers Lawn Care" 
        />
        <link rel="canonical" href="https://wrightbrotherslawncare.com/la-grange" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wrightbrotherslawncare.com/la-grange" />
        <meta property="og:title" content="Professional Lawn Care & Landscaping Services in La Grange, NC | Wright Brothers Lawn Care" />
        <meta property="og:description" content="Expert lawn care and landscaping in La Grange, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." />
        <meta property="og:image" content="https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg" />
        <meta property="og:site_name" content="Wright Brothers Lawn Care" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Lawn Care & Landscaping Services in La Grange, NC | Wright Brothers Lawn Care" />
        <meta name="twitter:description" content="Expert lawn care and landscaping in La Grange, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation." />
        <meta name="twitter:image" content="https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content="La Grange" />
        <meta name="geo.position" content="35.3065;-77.7911" />
        <meta name="ICBM" content="35.3065, -77.7911" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Wright Brothers Lawn Care - La Grange",
            "image": "https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg",
            "description": "Professional lawn care and landscaping services in La Grange, NC. We offer lawn maintenance, landscape design, turfcare, sod installation, and commercial landscaping services.",
            "priceRange": "$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2562 US-70",
              "addressLocality": "Goldsboro",
              "addressRegion": "NC",
              "postalCode": "27530",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "35.3065",
              "longitude": "-77.7911"
            },
            "url": "https://wrightbrotherslawncare.com/la-grange",
            "telephone": "+1-919-252-9130",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "areaServed": [
              {
                "@type": "City",
                "name": "La Grange",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Kinston",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Goldsboro",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              }
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Lawn Care Services in La Grange",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Lawn Maintenance in La Grange",
                    "description": "Regular mowing, edging, and lawn care to keep your La Grange property looking its best."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Landscape Design in La Grange",
                    "description": "Custom landscape designs that enhance your La Grange property's beauty and value."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sod Installation in La Grange",
                    "description": "Expert sod installation for a lush, green lawn in La Grange that lasts for years."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Commercial Landscaping in La Grange",
                    "description": "Professional commercial landscaping services for businesses in La Grange."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Turfcare in La Grange",
                    "description": "Specialized turfcare services including fertilization and weed control for La Grange lawns."
                  }
                }
              ]
            },
            "sameAs": [
              "https://www.facebook.com/wrightbrotherslawncare",
              "https://www.instagram.com/wrightbrotherslawncare",
              "https://twitter.com/wblawncare"
            ],
            "potentialAction": {
              "@type": "ReserveAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://wrightbrotherslawncare.com/contact",
                "inLanguage": "en-US",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.org/AndroidPlatform"
                ]
              },
              "result": {
                "@type": "Reservation",
                "name": "Request a Free Quote"
              }
            }
          })}
        </script>
      </Helmet>

      <Hero location={locationName} />

      <Box sx={{ py: 4, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '2rem', md: '2.5rem' },
              mb: 4
            }}
          >
            Lawn Care & Landscaping in La Grange, NC
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            La Grange's unique character as a historic eastern North Carolina town creates distinctive landscaping needs and opportunities. Located in Lenoir County with a rich agricultural heritage, La Grange features a mix of historic homes and newer developments that benefit from thoughtful, location-specific landscaping approaches.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            The local soil conditions, primarily consisting of sandy loam with clay subsoil, present both advantages and challenges for lawn and garden maintenance. Our landscape designs account for these soil characteristics, incorporating proper drainage solutions and choosing plants that thrive in La Grange's specific growing conditions.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            La Grange's climate, characterized by hot, humid summers and mild winters, allows for diverse planting options but requires strategic maintenance plans. We specialize in creating sustainable landscapes that include drought-resistant native plants, efficient irrigation systems, and environmentally friendly lawn care practices. Our approach balances classic Southern garden aesthetics with modern, low-maintenance design elements that enhance La Grange's community charm while providing practical benefits to homeowners and businesses year-round.
          </Typography>
        </Container>
      </Box>

      <Services location={locationName} />
      <Box bgcolor={theme.palette.primary.main}>
        <CommunityHighlight location={locationName} />
      </Box>
      <WhyChooseUs location={locationName} />
      
      {/* Add Related Locations section for internal linking
      <RelatedLocations location={locationName} /> */}
      
      <Box sx={{ py: 6, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h2" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              mb: 5
            }}
          >
            Contact Our La Grange Team
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', borderRadius: 2 }}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13009.01261215233!2d-77.80589347180574!3d35.30647679573834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89aec4287db93a15%3A0x5e444cd8553594a2!2sLa%20Grange%2C%20NC%2028551!5e0!3m2!1sen!2sus!4v1649290371624!5m2!1sen!2sus" 
                  width="100%" 
                  height="350" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  title="La Grange, NC Map"
                ></iframe>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 2, background: theme.palette.background.paper }}>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: theme.palette.primary.main
                  }}
                >
                  Contact Us for La Grange Lawn Care
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    2562 US-70, Goldsboro, NC 27530
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    <a href="tel:+19192529130" style={{ color: 'inherit', textDecoration: 'none' }}>
                      (919) 252-9130
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AccessTimeIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    Mon-Fri: 8AM-6PM
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  href="/contact"
                  size="large"
                  sx={{ 
                    py: 1.5, 
                    fontWeight: 600,
                    fontSize: '1.05rem',
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  Request a Free Quote in La Grange
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default LaGrangeLocation; 