import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {CTA } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';
import Approach from './components/Approach/Approach';
import Examples from './components/Examples/Examples';
const CommercialServices = () => {
  const theme = useTheme();
  return (
    <Main>
      <Hero 
        heading="Commercial Lawn Maintenance" 
        subheading="Professional commercial lawn care services with flexible maintenance options. We ensure your property maintains a positive first impression for clients, employees, and visitors." 
        metaTitle="Commercial Lawn Maintenance Services | Wright Brothers" 
        metaDescription="Professional commercial lawn maintenance including mowing, edging, weedeating, and cleanup. Flexible service schedules available for businesses." 
        metaKeywords="Commercial Lawn Maintenance, Commercial Landscaping, Business Property Maintenance, Commercial Mowing, Wayne County, Johnston County" 
      />
      <Approach />
      <Box bgcolor={theme.palette.primary.main}>
        <Examples />
      </Box>
      <CTA />
    </Main>
  );
};

export default CommercialServices;
