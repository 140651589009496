import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import { Hero, Services, CommunityHighlight, WhyChooseUs, RelatedLocations } from '../Location/components';
import { useTheme } from "@mui/material/styles";
import { Typography, Container, Grid, Button, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Dedicated page for Pikeville lawn care and landscaping services
 * with rich content specific to this location for improved SEO.
 */
const PikevilleLocation = () => {
  const theme = useTheme();
  const locationName = "pikeville";
  
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>Professional Lawn Care & Landscaping Services in Pikeville, NC | Wright Brothers Lawn Care</title>
        <meta 
          name="description" 
          content="Expert lawn care and landscaping in Pikeville, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." 
        />
        <meta 
          name="keywords" 
          content="lawn care Pikeville NC, landscaping Pikeville NC, sod installation Wayne County, commercial landscaping Pikeville, turfcare services Pikeville, lawn maintenance Pikeville, landscape design Wayne County, lawn fertilization Pikeville, weed control Pikeville, professional lawn service Wayne County, commercial lawn care Pikeville, residential landscaping Pikeville, Wright Brothers Lawn Care" 
        />
        <link rel="canonical" href="https://wrightbrotherslawncare.com/pikeville" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wrightbrotherslawncare.com/pikeville" />
        <meta property="og:title" content="Professional Lawn Care & Landscaping Services in Pikeville, NC | Wright Brothers Lawn Care" />
        <meta property="og:description" content="Expert lawn care and landscaping in Pikeville, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." />
        <meta property="og:image" content="https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg" />
        <meta property="og:site_name" content="Wright Brothers Lawn Care" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Lawn Care & Landscaping Services in Pikeville, NC | Wright Brothers Lawn Care" />
        <meta name="twitter:description" content="Expert lawn care and landscaping in Pikeville, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation." />
        <meta name="twitter:image" content="https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content="Pikeville" />
        <meta name="geo.position" content="35.4968;-77.9883" />
        <meta name="ICBM" content="35.4968, -77.9883" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Wright Brothers Lawn Care - Pikeville",
            "image": "https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg",
            "description": "Professional lawn care and landscaping services in Pikeville, NC. We offer lawn maintenance, landscape design, turfcare, sod installation, and commercial landscaping services.",
            "priceRange": "$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2562 US-70",
              "addressLocality": "Goldsboro",
              "addressRegion": "NC",
              "postalCode": "27530",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "35.4968",
              "longitude": "-77.9883"
            },
            "url": "https://wrightbrotherslawncare.com/pikeville",
            "telephone": "+1-919-252-9130",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "areaServed": [
              {
                "@type": "City",
                "name": "Pikeville",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Goldsboro",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Fremont",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              }
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Lawn Care Services in Pikeville",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Lawn Maintenance in Pikeville",
                    "description": "Regular mowing, edging, and lawn care to keep your Pikeville property looking its best."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Landscape Design in Pikeville",
                    "description": "Custom landscape designs that enhance your Pikeville property's beauty and value."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sod Installation in Pikeville",
                    "description": "Expert sod installation for a lush, green lawn in Pikeville that lasts for years."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Commercial Landscaping in Pikeville",
                    "description": "Professional commercial landscaping services for businesses in Pikeville."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Turfcare in Pikeville",
                    "description": "Specialized turfcare services including fertilization and weed control for Pikeville lawns."
                  }
                }
              ]
            },
            "sameAs": [
              "https://www.facebook.com/wrightbrotherslawncare",
              "https://www.instagram.com/wrightbrotherslawncare",
              "https://twitter.com/wblawncare"
            ],
            "potentialAction": {
              "@type": "ReserveAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://wrightbrotherslawncare.com/contact",
                "inLanguage": "en-US",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.org/AndroidPlatform"
                ]
              },
              "result": {
                "@type": "Reservation",
                "name": "Request a Free Quote"
              }
            }
          })}
        </script>
      </Helmet>

      <Hero location={locationName} />

      <Box sx={{ py: 4, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '2rem', md: '2.5rem' },
              mb: 4
            }}
          >
            Lawn Care & Landscaping in Pikeville, NC
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Pikeville's close-knit community and rich agricultural heritage create unique opportunities for distinctive landscaping. Located in Wayne County, this charming town combines rural character with convenient access to nearby Goldsboro, resulting in a diverse range of residential properties from historic farmhouses to modern suburban homes.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            The area's predominantly sandy loam soil provides excellent drainage but requires specific care strategies to maintain healthy lawns and gardens throughout North Carolina's hot, humid summers. Our approach to Pikeville landscaping addresses these local conditions with drought-resistant plant selections and efficient irrigation designs that conserve water while keeping properties looking their best.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Pikeville's four distinct seasons offer opportunities to create year-round landscape interest. We specialize in designs that incorporate seasonal color changes with spring flowering trees, summer perennials, autumn foliage displays, and winter structural elements. Our maintenance programs are tailored to the specific seasonal needs of Pikeville properties, ensuring beautiful, healthy landscapes throughout the year.
          </Typography>
        </Container>
      </Box>

      <Services location={locationName} />
      <Box bgcolor={theme.palette.primary.main}>
        <CommunityHighlight location={locationName} />
      </Box>
      <WhyChooseUs location={locationName} />
      
      {/* Add Related Locations section for internal linking */}
      <RelatedLocations location={locationName} />
      
      <Box sx={{ py: 6, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h2" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              mb: 5
            }}
          >
            Contact Our Pikeville Team
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', borderRadius: 2 }}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12998.32882917543!2d-78.0!3d35.4968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89abefc04dcc7a85%3A0x82c193966ab1b6eb!2sPikeville%2C%20NC%2027863!5e0!3m2!1sen!2sus!4v1649288371624!5m2!1sen!2sus" 
                  width="100%" 
                  height="350" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  title="Pikeville, NC Map"
                ></iframe>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 2, background: theme.palette.background.paper }}>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: theme.palette.primary.main
                  }}
                >
                  Contact Us for Pikeville Lawn Care
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    2562 US-70, Goldsboro, NC 27530
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    <a href="tel:+19192529130" style={{ color: 'inherit', textDecoration: 'none' }}>
                      (919) 252-9130
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AccessTimeIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    Mon-Fri: 8AM-6PM
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  href="/contact"
                  size="large"
                  sx={{ 
                    py: 1.5, 
                    fontWeight: 600,
                    fontSize: '1.05rem',
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  Request a Free Quote in Pikeville
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default PikevilleLocation; 