export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#388e3c', // Green 500
    light: '#81c784', // Green 300
    dark: '#2e7d32', // Green 700 (Adjusted to be different from main)
    contrastText: '#fff',
  },
  secondary: {
    main: '#8bc34a',  // Light Green 500
    light: '#aed581', // Light Green 300
    dark: '#689f38',  // Light Green 700
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#388e3c', // Changed from object to string
    secondary: '#E8E8E8', // Changed from object to string
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
  },
};
