// create a page that has the info with image component and the info with image component has an image of a lawn on the left side

import React from 'react';
import { Approach, Examples, CTA } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';

const TurfCare = () => {
  return (
    <Main>
      <Hero 
        heading="Professional Turf Care Services"
        subheading="Comprehensive herbicide, pesticide, and fertilization treatments tailored to your lawn's specific needs. We ensure your yard gets the right nutrients in the right amounts for optimal growth."
        metaTitle="Professional Turf Care Services | Herbicide & Fertilization"
        metaDescription="Expert herbicide, pesticide, and fertilization services in Wayne & Johnston Counties. Customized treatments based on soil analysis and yard-specific needs."
        metaKeywords="Herbicide, Pesticide, Fertilization, Turf Care, Weed Control, Soil Analysis, Wayne County, Johnston County"
        backgroundImage="https://via.placeholder.com/1920x1080?text=Turf+Care"
      />
      <Approach />
      <Examples />
      <CTA />
    </Main>
  );
};

export default TurfCare;