// MaintenanceGuides.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * A grid of maintenance guides or resources for homeowners.
 */
const MaintenanceGuides = () => {
  // Sample guide data
  const guides = [
    {
      title: 'Summer Lawn Care 101',
      image: 'https://via.placeholder.com/600x400?text=Summer+Lawn+Care',
      description:
        'Learn how to keep your lawn green and healthy during the hottest months.',
      link: '/resources/summer-lawn-care',
    },
    {
      title: 'Fall Cleanup Checklist',
      image: 'https://via.placeholder.com/600x400?text=Fall+Cleanup',
      description:
        'Get ready for winter with our step-by-step autumn cleanup guide.',
      link: '/resources/fall-cleanup',
    },
    {
      title: 'Choosing the Right Fertilizer',
      image: 'https://via.placeholder.com/600x400?text=Fertilizer+Guide',
      description:
        'Understand different fertilizer types and application methods for best results.',
      link: '/resources/fertilizer-guide',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Maintenance Guides for Homeowners | Wayne & Johnston County</title>
        <meta
          name="description"
          content="Explore our seasonal and topical landscaping guides tailored to homeowners in Wayne and Johnston Counties."
        />
        <meta
          name="keywords"
          content="Maintenance Guides, Lawn Care, Seasonal Tips, Wayne County, Johnston County"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="maintenance-guides"
        sx={{
          py: { xs: 4, md: 6 },
        }}
      >
        <Container>
          <Typography
            id="maintenance-guides"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            Maintenance Guides
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Explore our expert tips and resources to keep your property looking its best all year:
          </Typography>

          <Grid container spacing={4}>
            {guides.map((guide, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="180"
                    image={guide.image}
                    alt={guide.title}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {guide.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {guide.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="text"
                      color="primary"
                      href={guide.link}
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MaintenanceGuides;
