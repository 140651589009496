import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alpha } from '@mui/material/styles';

const ResourceFAQ = () => {
  // Updated list of FAQ items for homeowner resources
  const faqItems = [
    {
      question: 'How often should I mow my lawn?',
      answer:
        'During the growing season, we recommend mowing at least once per week. Adjust the frequency based on grass type and weather conditions.',
    },
    {
      question: 'What is the best time to water my lawn?',
      answer:
        'Early morning (before 10 AM) is ideal to allow water to penetrate the soil without excessive evaporation.',
    },
    {
      question: 'How do I prevent weeds and pests?',
      answer:
        'Regular fertilization, proper mowing height, and preventive treatments can help keep weeds and pests under control.',
    },
    {
      question: 'What types of grass grow best in Wayne & Johnston Counties?',
      answer:
        'Bermuda, Zoysia, and Fescue grass types typically thrive in our region. The choice depends on sun exposure, maintenance requirements, and aesthetic preferences.',
    },
    {
      question: 'Do I need to aerate my lawn?',
      answer:
        'Aeration helps relieve soil compaction and promotes healthy root growth. In most cases, yearly or biennial aeration is beneficial for cool-season grasses.',
    },
    {
      question: 'How often should I fertilize my lawn?',
      answer:
        'Most lawns benefit from fertilization 3-4 times a year, though frequency varies by grass type. Look for a balanced fertilizer and follow label instructions.',
    },
  ];

  return (
    <>
      {/* Optional SEO metadata for this FAQ section */}
      <Helmet>
        <title>Homeowner Resources FAQ | Wayne & Johnston County</title>
        <meta
          name="description"
          content="Get answers to frequently asked homeowner questions about lawn care, landscaping, and property maintenance in Wayne and Johnston Counties."
        />
        <meta
          name="keywords"
          content="Homeowner Resources, FAQ, Lawn Care, Landscaping, Wayne County, Johnston County"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="homeowner-resources-faq"
        sx={{
          py: { xs: 4, md: 6 },
        }}
      >
        <Container>
          <Typography
            id="homeowner-resources-faq"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700, mb: 2 }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Get quick answers to some of the most common landscaping and lawn care
            questions from homeowners in Wayne and Johnston Counties:
          </Typography>

          <Grid container spacing={4} alignItems="flex-start">
            {/* Left Column: Image */}
            <Grid item xs={12} md={5}>
              <Box
                component="img"
                src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/commercial.jpeg"
                alt="Homeowner Resources FAQ"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>

            {/* Right Column: FAQ Accordions */}
            <Grid item xs={12} md={7}>
              {faqItems.map((item, index) => (
                <Accordion
                  key={index}
                  sx={{ mb: 2 }} // Extra margin between each FAQ
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 600 }}
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">{item.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ResourceFAQ;
