// src/components/Layout/components/Topbar.js
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'; // Import Typography for text
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import Logo from '../../../../image.png'; // Adjust the path as needed
import NavItem from './components/NavItem'; // Ensure the correct path to NavItem

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  logoImage: {
    height: '60px', // Adjust logo height as needed
    width: 'auto',
  },
  phoneNumber: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column', // Stack texts vertically
    alignItems: 'flex-start', // Align texts to the start (left)
    textDecoration: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide phone number on small screens if needed
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  callText: {
    fontSize: '0.75rem', // Smaller font for "Call Us:"
    color: alpha(theme.palette.primary.main, 0.8), // Slightly faded color
  },
  numberText: {
    fontWeight: 'bold',
    fontSize: '1rem', // Larger font for the phone number
  },
}));

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
      sx={{
        backgroundColor: 'common.white', // Changed to white background
        paddingY: 1, // Vertical padding
        paddingX: { xs: 2, md: 4 }, // Responsive horizontal padding
      }}
    >
      {/* Logo and Phone Number Section */}
      <Box display={'flex'} alignItems="center">
        {/* Logo */}
        <Box
          component="a"
          href="/"
          title="Wright Brother Lawncare"
          className={classes.logo}
          sx={{
            borderRadius: 2, // Adds rounded corners
            overflow: 'hidden' // Ensures the image respects the border radius
          }}
        >
          <img src={Logo} alt="Wright Brother Lawncare Logo" className={classes.logoImage} />
        </Box>

        {/* Phone Number */}
        <Box
          component="a"
          href="tel:+9192529130" // Replace with your actual phone number
          className={classes.phoneNumber}
        >
          <Typography variant="body2" className={classes.callText}>
            Call Us:
          </Typography>
          <Typography variant="body1" className={classes.numberText}>
            (919) 252-9130
          </Typography>
        </Box>
      </Box>

      {/* Navigation Links */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
        {/* Render NavItem components for each navigation category */}
        {Object.keys(pages).map((category) => (
          <Box key={category} marginLeft={4}>
            <NavItem
              // if it is serviceAreas, then change the title to "Service Areas"
              title={category === 'serviceAreas' ? 'Service Areas' : category.charAt(0).toUpperCase() + category.slice(1)}
              id={`${category}-pages`}
              items={pages[category]}
              colorInvert={colorInvert} // Adjust if you have different color schemes
            />
          </Box>
        ))}

        {/* Create a button that looks similar to navLink but it is a singular button that says pay ur bill */}
        <Box paddingLeft={4}>
          <Button
            size="large"
            variant="text"
            component="a"
            target="_blank"
            href="/pay" // Replace with relevant link or remove if not needed
            sx={{
              textTransform: 'none',
              color: theme.palette.primary.main,
            }}
          >
            Pay Your Bill
          </Button>
        </Box>
      
        <Box paddingLeft={4}>
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href="/contact"
            sx={{
              color: 'white',
              fontWeight: 600,
              '&:hover': {
                bgcolor: theme.palette.secondary.dark,
              },
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      {/* Sidebar Toggle for Mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, alignItems: 'center' }}>
        <Button
          onClick={onSidebarOpen}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func.isRequired,
  pages: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default Topbar;
