import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';

const Pay = () => {
  return (
    <Main>
      <Hero 
        heading="Pay Online" 
        subheading="Secure and convenient payment options for our valued customers."
        metaTitle="Online Payment Portal | Wright Brothers Lawn & Landscaping"
        metaDescription="Make secure online payments for Wright Brothers Lawn & Landscaping services through our PCI-compliant payment portal."
        metaKeywords="Online Payment, Secure Payment, Lawn Care Payment, Landscaping Payment, Wright Brothers"
        showForm={false}
        size="small"
      />
      <Box
        component="section"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body1" paragraph sx={{ textAlign: 'center', mb: 4 }}>
            Thank you for choosing Wright Brothers Lawn & Landscaping for your yard needs! We value the security of your payment information. The online billing platform and payment system used at Wright Brothers is a PCI safe and secure company that is nationally recognized and secure. Our number priority when processing payment is to provide a secure and safe place for your information.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="https://swipesimple.com/links/lnk_f8552106"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proceed to Payment Portal
            </Button>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default Pay; 