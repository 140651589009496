// TurfCareServices.js
import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Examples = () => {
  // Turf care services data array
  const turfServices = [
    {
      title: 'Herbicide/Pesticide Care',
      description: 'Specialized treatments for weed control with focused applications in fall and spring. Our treatments are tailored to your specific soil type and location needs.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/herb.jpeg',
    },
    {
      title: 'Fertilization Programs',
      description: 'Essential nutrients that promote healthy, lush grass growth. Improves root development and creates thick, green lawns resistant to drought and disease.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/fert_main.jpeg',
    },
  ];

  return (
    <Box
      component="section"
      aria-labelledby="turf-care-services"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[100], 0.5),
      }}
    >
      <Container>
        <Typography
          id="turf-care-services"
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Comprehensive Turf Care Solutions
        </Typography>
        <Typography 
          variant="h6" 
          component="p" 
          sx={{ 
            mb: 4, 
            textAlign: 'center', 
            color: '#424242'
          }}
        >
          Customized treatments for your lawn's specific needs
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {turfServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={5} key={index}>
              <Card
                sx={{
                  height: '450px', // Increased height from default
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="300" // Increased height from 250px
                  image={service.image}
                  alt={service.title}
                  sx={{ 
                    objectPosition: service.title === 'Herbicide/Pesticide Care' ? 'center center' : 'top center',
                    objectFit: 'cover'
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#000000' }}>
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples;