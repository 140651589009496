import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import emailjs from 'emailjs-com';

const positions = [
  'Lawn Care Technician',
  'Landscaping Specialist', 
  'Maintenance Crew Member',
  'Team Leader',
  'Other',
];

const ApplicationForm = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    position: '',
    experience: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS Submission
    emailjs
      .send(
        'service_n1nje7l',
        'template_pzkvv5k',
        {
          from_name: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          position: formData.position,
          experience: formData.experience,
          message: formData.message
        },
        'jUOsVoDOX9ze4COQ5'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Application submitted successfully!');
          // Reset form
          setFormData({
            fullName: '',
            email: '',
            phone: '',
            position: '',
            experience: '',
            message: ''
          });
        },
        (error) => {
          console.log(error.text);
          alert('There was an error submitting your application. Please try again later.');
        }
      );
  };

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: '#f8f9fa', // Light gray background
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 700,
            color: theme.palette.primary.main,
            mb: 4,
          }}
        >
          Apply Now
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            '& .MuiTextField-root': { mb: 2 },
            '& .MuiInputBase-input': { color: '#424242' }, // Dark gray text
            '& .MuiInputLabel-root': { color: '#424242' }, // Dark gray labels
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#ffffff', // White background for inputs
              '& fieldset': {
                borderColor: '#e0e0e0', // Light gray border
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Position Interested In"
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              >
                {positions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Years of Experience"
                name="experience"
                type="number"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Tell us about yourself"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ mt: 2 }}
              >
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ApplicationForm;