import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Examples = () => {
  const theme = useTheme();
  const services = [
    {
      title: 'French Drains',
      description: 'French drains effectively manage excess water in landscapes and lawns by redirecting it away from foundations and low-lying areas. This reduces erosion, flooding, and structural damage risks while preventing standing water that attracts pests and causes root rot in plants.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/FrenchDrains.jpeg',
    },
    {
      title: 'Gutter Downspout Burying',
      description: "Burying gutter downspouts efficiently directs rainwater away from your home's foundation, reducing erosion and flooding risks. This method prevents pooling in your yard, protects landscaping, and creates a cleaner exterior by eliminating exposed pipes. It's a cost-effective way to prevent foundation damage and maintain a healthy crawlspace.",
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/gutter_example.jpeg',
    },
    {
      title: 'Yard Grading',
      description: "Yard grading is essential for maintaining proper drainage, preventing water damage, and improving overall landscape health. By creating gentle slopes away from your home's foundation, we help direct rainwater and runoff, reducing flooding risks and soil erosion. Proper grading promotes healthier grass growth and creates level surfaces for landscaping features.",
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/grading_example.jpeg',
    },
    {
      title: 'Seeding Services',
      description: 'While we recommend sod when possible, seeding offers a cost-efficient alternative for establishing lawns, especially in larger areas. Though results take longer to see, our professional seeding services provide a budget-friendly option without compromising on quality. We ensure proper technique and timing for optimal growth.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/seeding.jpeg',
    },
    {
      title: 'Tree Removal',
      description: 'We provide professional tree removal services for dead, diseased, or unstable trees, including stump grinding options. Our team works with local arborists to assess each situation, ensuring we only remove hazardous trees that pose safety risks. This service prevents property damage, eliminates pest habitats, improves aesthetics, and increases sunlight for surrounding plants.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/tree_removal_example.jpeg',
    },
    {
      title: 'Junk Removal',
      description: "In addition to our many landscaping offerings, we can also provide junk removal for a fully clean yard! Whether it's the unwanted building that needs demolition or simply an extra hand with a dump trailer to haul that debris to the landfill. We are here to help our customers with their specific needs beyond the lawn and landscaping field.",
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/other1.jpeg',
    },
  ];

  return (
    <Box
      component="section"
      aria-labelledby="other-services-examples"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Typography
          id="other-services-examples"
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Specialized Services
        </Typography>
        <Typography 
          variant="h6" 
          component="p" 
          sx={{ 
            mb: 4, 
            textAlign: 'center', 
            color: '#424242'
          }}
        >
          Professional Solutions for Common Landscape Challenges
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="280"
                  image={service.image}
                  alt={service.title}
                  sx={{ 
                    objectPosition: 'center',
                    objectFit: 'cover'
                  }}
                />
                <CardContent sx={{ flexGrow: 1, overflow: 'auto' }}>
                  <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
                    {service.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: '#000000',
                      display: '-webkit-box',
                      WebkitLineClamp: 8,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples; 