import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from './Container';
import { Grid, TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';
import emailjs from 'emailjs-com';

const Hero = ({
  heading = 'Industry Specific Solutions',
  subheading = 'We cater to the unique needs of businesses in Wayne and Johnston Counties. From healthcare campuses to retail centers, our specialized commercial landscaping services make a lasting impression.',
  metaTitle = 'Industry-Specific Commercial Landscaping Solutions',
  metaDescription = 'Explore tailored commercial landscaping services for various industries in Wayne and Johnston Counties, including healthcare, retail, and more.',
  metaKeywords = 'Commercial Landscaping, Industry-Specific Solutions, Wayne County, Johnston County, Healthcare, Retail, Corporate',
  showForm = true,
  size = 'default',
}) => {
  // -------------- FORM STATE --------------
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    services: {
      lawnCare: false,
      landscapeDesign: false,
      treeBushTrimming: false,
      pinestrawMulch: false,
      sprayingWeeds: false,
      seasonalCleanup: false,
      sod: false,
      other: false
    },
    otherService: '',
    comments: '',
    honeyPot: '', // hidden anti-spam field
  });
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);

  // -------------- VALIDATION --------------
  const validateStep1 = () => {
    const { firstName, lastName, phone, email, honeyPot } = formData;
    const phoneRegex = /^[0-9()\-+\s]{7,}$/; // basic phone format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // basic email format

    if (!firstName || !lastName || !phone || !email) {
      return 'All fields are required.';
    }
    if (!phoneRegex.test(phone)) {
      return 'Please enter a valid phone number.';
    }
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }
    if (honeyPot !== '') {
      return 'Spam detected.';
    }
    return '';
  };

  const validateStep2 = () => {
    const { address, services, honeyPot } = formData;
    if (!address) {
      return 'Address is required.';
    }
    if (!Object.values(services).some(value => value === true)) {
      return 'Please select at least one service.';
    }
    if (honeyPot !== '') {
      return 'Spam detected.';
    }
    return '';
  };

  // -------------- HANDLERS --------------
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleServiceChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  const handleNext = () => {
    const error = validateStep1();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setErrorMessage('');
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateStep2();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setErrorMessage('');

    // Format selected services for email
    const selectedServices = Object.entries(formData.services)
      .filter(([_, isSelected]) => isSelected)
      .map(([service, _]) => {
        // Convert camelCase to readable format
        switch(service) {
          case 'lawnCare': return 'Lawn Care';
          case 'landscapeDesign': return 'Landscape Design';
          case 'treeBushTrimming': return 'Tree & Bush Trimming';
          case 'pinestrawMulch': return 'Pinestraw/Mulch';
          case 'sprayingWeeds': return 'Spraying for Weeds';
          case 'seasonalCleanup': return 'Seasonal Cleanup';
          case 'sod': return 'Sod';
          case 'other': return `Other: ${formData.otherService}`;
          default: return service;
        }
      })
      .join(', ');

    // EmailJS Submission
    emailjs
      .send(
        'service_n1nje7l', // e.g. service_xxx
        'template_ghcfh5n', // e.g. template_xxx
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
          address: formData.address,
          serviceType: selectedServices,
          comments: formData.comments,
        },
        'jUOsVoDOX9ze4COQ5' // e.g. user_xxx
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Form submitted successfully!');
          // Reset form
          setStep(1);
          setFormData({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
            services: {
              lawnCare: false,
              landscapeDesign: false,
              treeBushTrimming: false,
              pinestrawMulch: false,
              sprayingWeeds: false,
              seasonalCleanup: false,
              sod: false,
              other: false
            },
            otherService: '',
            comments: '',
            honeyPot: '',
          });
        },
        (error) => {
          console.log(error.text);
          alert('There was an error sending your form. Please try again later.');
        }
      );
  };

  // Reusable field style for slight white background
  const textFieldStyles = {
    color: 'common.white',
    backgroundColor: alpha('#fff', 0.3),
    '::placeholder': {
      color: 'common.white',
    },
  };

  // Determine height based on size prop
  const getHeroHeight = () => {
    switch(size) {
      case 'small':
        return { xs: 300, sm: 400, md: 500 };
      case 'large':
        return { xs: 500, sm: 600, md: 700 };
      case 'default':
      default:
        return { xs: 400, sm: 500, md: 600 };
    }
  };

  return (
    <>
      {/* Basic SEO metadata */}
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>

      <Box
        component="section"
        aria-label="Hero section"
        position="relative"
        minHeight={getHeroHeight()}
        display="flex"
        alignItems="center"
        marginTop={-13}
        paddingTop={13}
        sx={{
          backgroundImage:
            'url(https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Landscaping+Suburbs.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        id="industry-specific__hero-item--js-scroll"
      >
        {/* Overlay for text contrast */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            width: 1,
            height: 1,
            background: alpha('#161c2d', 0.6),
            zIndex: 1,
          }}
        />
        <Container position="relative" zIndex={2}>
          <Grid container spacing={4} alignItems="flex-start">
            {/* LEFT SIDE: HEADING/SUBHEADING */}
            <Grid item xs={12} md={showForm ? 7 : 12}>
              <Box sx={{ mb: { xs: 4, md: 0 } }}>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    color: 'common.white',
                    textTransform: 'uppercase',
                  }}
                >
                  {heading}
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    maxWidth: { xs: '100%', md: showForm ? '80%' : '60%' },
                    color: 'common.white',
                  }}
                >
                  {subheading}
                </Typography>
              </Box>
            </Grid>

            {/* RIGHT SIDE: MULTI-STEP FORM */}
            {showForm && (
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ mb: 2, fontWeight: 700, color: 'common.white' }}
                  >
                    Request Your Free Quote Today!
                  </Typography>
                  <form ref={formRef} onSubmit={handleSubmit}>
                    {/* HONEYPOT FIELD (hidden) */}
                    <TextField
                      type="text"
                      name="honeyPot"
                      value={formData.honeyPot}
                      onChange={handleChange}
                      sx={{ display: 'none' }}
                    />

                    {step === 1 && (
                      <>
                        <TextField
                          label="First Name*"
                          placeholder="Enter your first name"
                          name="firstName"
                          fullWidth
                          margin="normal"
                          size="small"
                          value={formData.firstName}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: { color: 'common.white' },
                          }}
                          InputProps={{
                            sx: textFieldStyles,
                          }}
                        />
                        <TextField
                          label="Last Name*"
                          placeholder="Enter your last name"
                          name="lastName"
                          fullWidth
                          margin="normal"
                          size="small"
                          value={formData.lastName}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: { color: 'common.white' },
                          }}
                          InputProps={{
                            sx: textFieldStyles,
                          }}
                        />
                        <TextField
                          label="Phone*"
                          placeholder="(123) 456-7890"
                          name="phone"
                          fullWidth
                          margin="normal"
                          size="small"
                          value={formData.phone}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: { color: 'common.white' },
                          }}
                          InputProps={{
                            sx: textFieldStyles,
                          }}
                        />
                        <TextField
                          label="Email*"
                          placeholder="email@example.com"
                          type="email"
                          name="email"
                          fullWidth
                          margin="normal"
                          size="small"
                          value={formData.email}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: { color: 'common.white' },
                          }}
                          InputProps={{
                            sx: textFieldStyles,
                          }}
                        />

                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: 'common.white' }}
                        >
                          STEP 1 OF 2
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{ mt: 2, color: 'common.white' }}
                          color="success"
                          onClick={handleNext}
                        >
                          NEXT
                        </Button>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <TextField
                          label="Address*"
                          placeholder="123 Main St"
                          name="address"
                          fullWidth
                          margin="normal"
                          size="small"
                          value={formData.address}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: { color: 'common.white' },
                          }}
                          InputProps={{
                            sx: textFieldStyles,
                          }}
                        />
                        
                        <FormLabel component="legend" sx={{ mt: 2, color: 'common.white' }}>
                          Select Services*
                        </FormLabel>
                        <FormGroup sx={{ mt: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.lawnCare}
                                onChange={handleServiceChange}
                                name="lawnCare"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Lawn Care"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.landscapeDesign}
                                onChange={handleServiceChange}
                                name="landscapeDesign"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Landscape Design"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.treeBushTrimming}
                                onChange={handleServiceChange}
                                name="treeBushTrimming"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Tree & Bush Trimming"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.pinestrawMulch}
                                onChange={handleServiceChange}
                                name="pinestrawMulch"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Pinestraw/Mulch"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.sprayingWeeds}
                                onChange={handleServiceChange}
                                name="sprayingWeeds"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Spraying for Weeds"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.seasonalCleanup}
                                onChange={handleServiceChange}
                                name="seasonalCleanup"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Seasonal Cleanup"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.sod}
                                onChange={handleServiceChange}
                                name="sod"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Sod"
                            sx={{ color: 'common.white' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={formData.services.other}
                                onChange={handleServiceChange}
                                name="other"
                                sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                              />
                            }
                            label="Other"
                            sx={{ color: 'common.white' }}
                          />
                          {formData.services.other && (
                            <TextField
                              label="Please specify"
                              name="otherService"
                              fullWidth
                              margin="normal"
                              size="small"
                              value={formData.otherService}
                              onChange={handleChange}
                              InputLabelProps={{
                                sx: { color: 'common.white' },
                              }}
                              InputProps={{
                                sx: textFieldStyles,
                              }}
                            />
                          )}
                          <TextField
                            label="Comments"
                            name="comments"
                            fullWidth
                            margin="normal"
                            size="small"
                            value={formData.comments}
                            onChange={handleChange}
                            InputLabelProps={{
                              sx: { color: 'common.white' },
                            }}
                            InputProps={{
                              sx: textFieldStyles,
                            }}
                          />
                        </FormGroup>

                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: 'common.white' }}
                        >
                          STEP 2 OF 2
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              color: 'common.white',
                              borderColor: 'common.white',
                            }}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ color: 'common.white' }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </>
                    )}
                  </form>
                  {errorMessage && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Hero;
