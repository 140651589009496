export const pages = {
 about: [
    { title: 'Mission', href: '/about' },
    { title: 'Meet the Owners', href: '/meet-the-team' },
    { title: 'Careers', href: '/about/careers' },
    { title: 'Portfolio', href: '/residential/portfolio' },
    { title: 'Testimonials', href: '/testimonials' },
  ],
  serviceAreas: [
    { title: 'Goldsboro, NC', href: '/goldsboro' },
    { title: 'Smithfield, NC', href: '/smithfield' },
    { title: 'Clayton, NC', href: '/clayton' },
    { title: 'Four Oaks, NC', href: '/fouroaks' },
    { title: 'Selma, NC', href: '/selma' },
    { title: 'Princeton, NC', href: '/princeton' },
    { title: 'Pikeville, NC', href: '/pikeville' },
    { title: 'Mount Olive, NC', href: '/mount-olive' },
    { title: 'La Grange, NC', href: '/la-grange' },
    // { title: 'Hillsborough, NC', href: '/location/hillsborough' },
    // { title: 'Kinston, NC', href: '/location/kinston' },
    // { title: 'New Bern, NC', href: '/location/new-bern' },
    // { title: 'Morehead City, NC', href: '/location/morehead-city' },
    // { title: 'Morehead City, NC', href: '/location/morehead-city' },


  ],
  services: [
    { title: 'Lawn Maintenance', href: '/services/lawn-maintenance' },
    { title: 'Landscape Design', href: '/services/landscape-design' },
    { title: 'Commercial Services', href: '/services/commercial-services' },
    {title: 'Landscape Maintenance', href: '/services/landscape-maintenance'},
    {title: 'Turf Care', href: '/services/turf-care'},
    {title: 'Sod Installation', href: '/services/sod-installation'},
    {title: 'Other Services', href: '/services/other-services'},
  ],
  // residential: [
  //   { title: 'Services Overview', href: '/residential/services' },
  //   { title: 'Portfolio / Gallery', href: '/residential/portfolio' },
  //   { title: 'Homeowner Resources', href: '/residential/resources' },
  // ],
  // commercial: [
  //   { title: 'Services Overview', href: '/commercial/services' },
  //   { title: 'Industry-Specific Solutions', href: '/commercial/industries' },
  //   { title: 'Commercial Portfolio', href: '/commercial/portfolio' },
  // ]
};
