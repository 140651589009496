import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '../../../../components/Container.js';
import { Grid, TextField, Button, MenuItem, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import emailjs from 'emailjs-com';

/**
 * Hero component that:
 *  - Dynamically sets SEO (title, meta tags, OG tags, etc.)
 *  - Displays a location-based hero heading & subheading.
 *  - Includes a form for user inquiries
 */
const Hero = ({ location }) => {
  // -------------- FORM STATE --------------
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    services: {
      lawnCare: false,
      landscapeDesign: false,
      treeBushTrimming: false,
      pinestrawMulch: false,
      sprayingWeeds: false,
      seasonalCleanup: false,
      sod: false,
      other: false
    },
    otherService: '',
    comments: '',
    honeyPot: '', // hidden anti-spam field
  });
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);

  // -------------- VALIDATION --------------
  const validateStep1 = () => {
    const { firstName, lastName, phone, email, honeyPot } = formData;
    const phoneRegex = /^[0-9()\-+\s]{7,}$/; // basic phone format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // basic email format

    if (!firstName || !lastName || !phone || !email) {
      return 'All fields are required.';
    }
    if (!phoneRegex.test(phone)) {
      return 'Please enter a valid phone number.';
    }
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }
    if (honeyPot !== '') {
      return 'Spam detected.';
    }
    return '';
  };

  const validateStep2 = () => {
    const { address, services, honeyPot } = formData;
    if (!address) {
      return 'Address is required.';
    }
    if (!Object.values(services).some(value => value === true)) {
      return 'Please select at least one service.';
    }
    if (honeyPot !== '') {
      return 'Spam detected.';
    }
    return '';
  };

  // -------------- HANDLERS --------------
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleServiceChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  const handleNext = () => {
    const error = validateStep1();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setErrorMessage('');
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateStep2();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setErrorMessage('');

    // Format selected services for email
    const selectedServices = Object.entries(formData.services)
      .filter(([_, isSelected]) => isSelected)
      .map(([service, _]) => {
        // Convert camelCase to readable format
        switch(service) {
          case 'lawnCare': return 'Lawn Care';
          case 'landscapeDesign': return 'Landscape Design';
          case 'treeBushTrimming': return 'Tree & Bush Trimming';
          case 'pinestrawMulch': return 'Pinestraw/Mulch';
          case 'sprayingWeeds': return 'Spraying for Weeds';
          case 'seasonalCleanup': return 'Seasonal Cleanup';
          case 'sod': return 'Sod';
          case 'other': return `Other: ${formData.otherService}`;
          default: return service;
        }
      })
      .join(', ');

    // EmailJS Submission
    emailjs
      .send(
        'service_n1nje7l', // e.g. service_xxx
        'template_ghcfh5n', // e.g. template_xxx
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
          address: formData.address,
          serviceType: selectedServices,
          comments: formData.comments,
          location: location || 'Wayne & Johnston County', // Include the location in the email
        },
        'jUOsVoDOX9ze4COQ5' // e.g. user_xxx
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Form submitted successfully!');
          // Reset form
          setStep(1);
          setFormData({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
            services: {
              lawnCare: false,
              landscapeDesign: false,
              treeBushTrimming: false,
              pinestrawMulch: false,
              sprayingWeeds: false,
              seasonalCleanup: false,
              sod: false,
              other: false
            },
            otherService: '',
            comments: '',
            honeyPot: '',
          });
        },
        (error) => {
          console.log(error.text);
          alert('There was an error sending your form. Please try again later.');
        }
      );
  };

  // Reusable field style for slight white background
  const textFieldStyles = {
    color: 'common.white',
    backgroundColor: alpha('#fff', 0.3),
    '::placeholder': {
      color: 'common.white',
    },
  };

  // Get location-specific SEO content
  const getSeoContent = () => {
    // Default SEO content for Home/root page
    if (!location) {
      return {
        pageTitle:
          'Professional Lawn Care & Landscaping Services in Wayne & Johnston County, NC | Wright Brothers Lawn Care',
        metaDescription:
          'Expert lawn care and landscaping services in Wayne and Johnston County, NC. Serving Goldsboro, Clayton, Smithfield, Four Oaks, Selma, Princeton, Benson, Pine Level, Wilson\'s Mills, Mount Olive, Pikeville, and La Grange. Get a free quote today!',
        metaKeywords:
          'lawn care Wayne County NC, landscaping Johnston County NC, residential landscaping Goldsboro NC, commercial lawn care Clayton NC, lawn maintenance Smithfield NC, garden design Four Oaks NC, tree trimming Selma NC, sod installation Princeton NC, lawn service Benson NC, Pine Level NC lawn care, Wilson\'s Mills landscaping, Wright Brothers Lawn Care',
        canonicalUrl: 'https://wrightbrotherslawncare.com/',
        ogImageUrl: 'https://wrightbrotherslawncare.com/images/hero-landscaping.jpg',
        ogImageAlt: 'Professional lawn care and landscaping services by Wright Brothers',
        heading: 'Professional Lawn Care & Landscaping Services',
        subheading: 'Serving Wayne & Johnston County, NC',
      };
    }

    // Location specific SEO
    switch (location.toLowerCase()) {
      case 'goldsboro':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Goldsboro, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Goldsboro, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Goldsboro NC, landscaping Goldsboro NC, lawn maintenance Goldsboro, residential landscaping Wayne County, commercial lawn care Goldsboro, garden design Goldsboro, tree trimming Goldsboro NC, sod installation Goldsboro, Wright Brothers Lawn Care Goldsboro',
          canonicalUrl: 'https://wrightbrotherslawncare.com/goldsboro',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Goldsboro, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Goldsboro, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'clayton':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Clayton, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Clayton, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Clayton NC, landscaping Clayton NC, lawn maintenance Clayton, residential landscaping Johnston County, commercial lawn care Clayton, garden design Clayton, tree trimming Clayton NC, sod installation Clayton, Wright Brothers Lawn Care Clayton',
          canonicalUrl: 'https://wrightbrotherslawncare.com/clayton',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/clayton-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Clayton, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Clayton, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'smithfield':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Smithfield, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Smithfield, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Smithfield NC, landscaping Smithfield NC, lawn maintenance Smithfield, residential landscaping Johnston County, commercial lawn care Smithfield, garden design Smithfield, tree trimming Smithfield NC, sod installation Smithfield, Wright Brothers Lawn Care Smithfield',
          canonicalUrl: 'https://wrightbrotherslawncare.com/smithfield',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/smithfield-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Smithfield, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Smithfield, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'four-oaks':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Four Oaks, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Four Oaks, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Four Oaks NC, landscaping Four Oaks NC, lawn maintenance Four Oaks, residential landscaping Johnston County, commercial lawn care Four Oaks, garden design Four Oaks, tree trimming Four Oaks NC, sod installation Four Oaks, Wright Brothers Lawn Care Four Oaks',
          canonicalUrl: 'https://wrightbrotherslawncare.com/four-oaks',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/four-oaks-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Four Oaks, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Four Oaks, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'selma':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Selma, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Selma, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Selma NC, landscaping Selma NC, lawn maintenance Selma, residential landscaping Johnston County, commercial lawn care Selma, garden design Selma, tree trimming Selma NC, sod installation Selma, Wright Brothers Lawn Care Selma',
          canonicalUrl: 'https://wrightbrotherslawncare.com/selma',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/selma-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Selma, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Selma, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'princeton':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Princeton, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Princeton, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Princeton NC, landscaping Princeton NC, lawn maintenance Princeton, residential landscaping Johnston County, commercial lawn care Princeton, garden design Princeton, tree trimming Princeton NC, sod installation Princeton, Wright Brothers Lawn Care Princeton',
          canonicalUrl: 'https://wrightbrotherslawncare.com/princeton',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/princeton-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Princeton, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Princeton, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'benson':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Benson, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Benson, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Benson NC, landscaping Benson NC, lawn maintenance Benson, residential landscaping Johnston County, commercial lawn care Benson, garden design Benson, tree trimming Benson NC, sod installation Benson, Wright Brothers Lawn Care Benson',
          canonicalUrl: 'https://wrightbrotherslawncare.com/benson',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/benson-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Benson, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Benson, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'pine-level':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Pine Level, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Pine Level, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Pine Level NC, landscaping Pine Level NC, lawn maintenance Pine Level, residential landscaping Johnston County, commercial lawn care Pine Level, garden design Pine Level, tree trimming Pine Level NC, sod installation Pine Level, Wright Brothers Lawn Care Pine Level',
          canonicalUrl: 'https://wrightbrotherslawncare.com/pine-level',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/pine-level-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Pine Level, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Pine Level, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'wilsons-mills':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Wilson\'s Mills, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Wilson\'s Mills, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Wilson\'s Mills NC, landscaping Wilson\'s Mills NC, lawn maintenance Wilson\'s Mills, residential landscaping Johnston County, commercial lawn care Wilson\'s Mills, garden design Wilson\'s Mills, tree trimming Wilson\'s Mills NC, sod installation Wilson\'s Mills, Wright Brothers Lawn Care Wilson\'s Mills',
          canonicalUrl: 'https://wrightbrotherslawncare.com/wilsons-mills',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/wilsons-mills-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Wilson\'s Mills, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Wilson\'s Mills, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'mount-olive':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Mount Olive, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Mount Olive, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Mount Olive NC, landscaping Mount Olive NC, lawn maintenance Mount Olive, residential landscaping Wayne County, commercial lawn care Mount Olive, garden design Mount Olive, tree trimming Mount Olive NC, sod installation Mount Olive, Wright Brothers Lawn Care Mount Olive',
          canonicalUrl: 'https://wrightbrotherslawncare.com/mount-olive',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Mount Olive, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Mount Olive, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'pikeville':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Pikeville, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Pikeville, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care Pikeville NC, landscaping Pikeville NC, lawn maintenance Pikeville, residential landscaping Wayne County, commercial lawn care Pikeville, garden design Pikeville, tree trimming Pikeville NC, sod installation Pikeville, Wright Brothers Lawn Care Pikeville',
          canonicalUrl: 'https://wrightbrotherslawncare.com/pikeville',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in Pikeville, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in Pikeville, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      case 'la-grange':
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in La Grange, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in La Grange, NC. Family-owned and operated since 2010, we provide residential and commercial lawn maintenance, landscape design, and more with free quotes and competitive rates.',
          metaKeywords:
            'lawn care La Grange NC, landscaping La Grange NC, lawn maintenance La Grange, residential landscaping Wayne County, commercial lawn care La Grange, garden design La Grange, tree trimming La Grange NC, sod installation La Grange, Wright Brothers Lawn Care La Grange',
          canonicalUrl: 'https://wrightbrotherslawncare.com/la-grange',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg',
          ogImageAlt: 'Lawn maintenance and landscaping services in La Grange, North Carolina',
          heading: 'Professional Lawn Care & Landscaping in La Grange, NC',
          subheading: 'Transform your outdoor space with our expert services',
        };
      default:
        return {
          pageTitle:
            'Professional Lawn Care & Landscaping Services in Wayne & Johnston County, NC | Wright Brothers Lawn Care',
          metaDescription:
            'Expert lawn care and landscaping services in Wayne and Johnston County, NC. Serving Goldsboro, Clayton, Smithfield, Four Oaks, Selma, Princeton, Benson, Pine Level, Wilson\'s Mills, Mount Olive, Pikeville, and La Grange. Get a free quote today!',
          metaKeywords:
            'lawn care Wayne County NC, landscaping Johnston County NC, residential landscaping Goldsboro NC, commercial lawn care Clayton NC, lawn maintenance Smithfield NC, garden design Four Oaks NC, tree trimming Selma NC, sod installation Princeton NC, lawn service Benson NC, Pine Level NC lawn care, Wilson\'s Mills landscaping, Wright Brothers Lawn Care',
          canonicalUrl: 'https://wrightbrotherslawncare.com/',
          ogImageUrl: 'https://wrightbrotherslawncare.com/images/hero-landscaping.jpg',
          ogImageAlt: 'Professional lawn care and landscaping services by Wright Brothers',
          heading: 'Professional Lawn Care & Landscaping Services',
          subheading: 'Serving Wayne & Johnston County, NC',
        };
    }
  };

  // Default / fallback for unknown location
  let pageTitle = 'Landscaping Services in Wayne & Johnston County';
  let metaDescription =
    'We provide top-quality lawn care services in Wayne and Johnston County.';
  let metaKeywords = 'landscaping, lawn care, Wayne County, Johnston County';
  let canonicalUrl = 'https://example.com/locations'; // Update to your domain
  let ogImageUrl = 'https://wrightbrotherslawncare.com/images/hero-landscaping.jpg';
  let ogImageAlt = 'Professional lawn care services';
  let heading = 'Landscaping Services in Wayne & Johnston County';
  let subheading =
    "We're a faith-driven landscaping company serving Wayne and Johnston Counties with top-quality lawn care and genuine community focus.";

  // Adjust based on location prop
  if (location === 'goldsboro') {
    pageTitle = 'Landscaping Services in Goldsboro, NC';
    metaDescription =
      'We provide top-quality lawn care services in Goldsboro, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, Goldsboro, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/goldsboro';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in Goldsboro, North Carolina';
    heading = 'Landscaping and Lawn Care Services in Goldsboro';
    subheading =
      'Serving Goldsboro with top-quality lawn care, design, and maintenance.';
  } else if (location === 'clayton') {
    pageTitle = 'Landscaping Services in Clayton, NC';
    metaDescription =
      'We provide top-quality lawn care services in Clayton, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, Clayton, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/clayton';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/clayton-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in Clayton, North Carolina';
    heading = 'Landscaping and Lawn Care Services in Clayton';
    subheading =
      'Serving Clayton with top-quality lawn care, design, and maintenance.';
  } else if (location === 'smithfield') {
    pageTitle = 'Landscaping Services in Smithfield, NC';
    metaDescription =
      'We provide top-quality lawn care services in Smithfield, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, Smithfield, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/smithfield';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/smithfield-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in Smithfield, North Carolina';
    heading = 'Landscaping and Lawn Care Services in Smithfield';
    subheading =
      'Serving Smithfield with top-quality lawn care, design, and maintenance.';
  } else if (location === 'mount-olive') {
    pageTitle = 'Landscaping Services in Mount Olive, NC';
    metaDescription =
      'We provide top-quality lawn care services in Mount Olive, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, Mount Olive, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/mount-olive';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in Mount Olive, North Carolina';
    heading = 'Landscaping and Lawn Care Services in Mount Olive';
    subheading =
      'Serving Mount Olive with top-quality lawn care, design, and maintenance.';
  } else if (location === 'pikeville') {
    pageTitle = 'Landscaping Services in Pikeville, NC';
    metaDescription =
      'We provide top-quality lawn care services in Pikeville, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, Pikeville, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/pikeville';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/pikeville-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in Pikeville, North Carolina';
    heading = 'Landscaping and Lawn Care Services in Pikeville';
    subheading =
      'Serving Pikeville with top-quality lawn care, design, and maintenance.';
  } else if (location === 'la-grange') {
    pageTitle = 'Landscaping Services in La Grange, NC';
    metaDescription =
      'We provide top-quality lawn care services in La Grange, NC. Our faith-driven landscaping company is proud to serve the local community with expert lawn maintenance, design, and more.';
    metaKeywords = 'landscaping, lawn care, La Grange, NC, design, maintenance';
    canonicalUrl = 'https://wrightbrotherslawncare.com/locations/la-grange';
    ogImageUrl = 'https://wrightbrotherslawncare.com/images/la-grange-landscaping.jpg';
    ogImageAlt = 'Lawn maintenance and landscaping services in La Grange, North Carolina';
    heading = 'Landscaping and Lawn Care Services in La Grange';
    subheading =
      'Serving La Grange with top-quality lawn care, design, and maintenance.';
  }

  // Determine which location metadata to use
  const metadata = getSeoContent() || {
    pageTitle,
    metaDescription,
    metaKeywords,
    canonicalUrl,
    ogImageUrl,
    ogImageAlt,
    heading,
    subheading,
  };

  return (
    <>
      {/* SEO metadata */}
      <Helmet>
        <title>{metadata.pageTitle}</title>
        <meta name="description" content={metadata.metaDescription} />
        <meta name="keywords" content={metadata.metaKeywords} />

        {/* Canonical URL */}
        <link rel="canonical" href={metadata.canonicalUrl} />

        {/* Open Graph (OG) tags */}
        <meta property="og:title" content={metadata.pageTitle} />
        <meta property="og:description" content={metadata.metaDescription} />
        <meta property="og:url" content={metadata.canonicalUrl} />
        <meta property="og:image" content={metadata.ogImageUrl} />
        <meta property="og:image:alt" content={metadata.ogImageAlt} />
        <meta property="og:type" content="website" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metadata.pageTitle} />
        <meta name="twitter:description" content={metadata.metaDescription} />
        <meta name="twitter:image" content={metadata.ogImageUrl} />
        <meta name="twitter:image:alt" content={metadata.ogImageAlt} />
      </Helmet>

      {/* Hero Section */}
      <Box
        component="section"
        aria-label={`Landscaping Services in ${location ?? 'Wayne & Johnston County'}`}
        position="relative"
        minHeight={{ xs: 400, sm: 500, md: 600 }}
        display="flex"
        alignItems="center"
        marginTop={-13}
        paddingTop={13}
        sx={{
          backgroundImage:
            'url(https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Landscaping+Suburbs.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        {/* Dark overlay to enhance contrast */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: 1,
            height: 1,
            background: alpha('#161c2d', 0.6),
            zIndex: 1,
          }}
        />
        <Container position="relative" zIndex={2}>
          <Grid container spacing={4} alignItems="flex-start">
            {/* LEFT SIDE: HEADING/SUBHEADING */}
            <Grid item xs={12} md={7}>
              <Box>
                {/* Heading (H1) */}
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    color: 'common.white',
                    textTransform: 'uppercase',
                  }}
                >
                  {metadata.heading}
                </Typography>
                {/* Subheading */}
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    maxWidth: { xs: '100%', md: '80%' },
                    color: 'common.white',
                  }}
                >
                  {metadata.subheading}
                </Typography>
              </Box>
            </Grid>

            {/* RIGHT SIDE: MULTI-STEP FORM */}
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  backgroundColor: 'transparent',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ mb: 2, fontWeight: 700, color: 'common.white' }}
                >
                  Request Your Free Quote Today!
                </Typography>
                <form ref={formRef} onSubmit={handleSubmit}>
                  {/* HONEYPOT FIELD (hidden) */}
                  <TextField
                    type="text"
                    name="honeyPot"
                    value={formData.honeyPot}
                    onChange={handleChange}
                    sx={{ display: 'none' }}
                  />

                  {step === 1 && (
                    <>
                      <TextField
                        label="First Name*"
                        placeholder="Enter your first name"
                        name="firstName"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={formData.firstName}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { color: 'common.white' },
                        }}
                        InputProps={{
                          sx: textFieldStyles,
                        }}
                      />
                      <TextField
                        label="Last Name*"
                        placeholder="Enter your last name"
                        name="lastName"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={formData.lastName}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { color: 'common.white' },
                        }}
                        InputProps={{
                          sx: textFieldStyles,
                        }}
                      />
                      <TextField
                        label="Phone*"
                        placeholder="(123) 456-7890"
                        name="phone"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={formData.phone}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { color: 'common.white' },
                        }}
                        InputProps={{
                          sx: textFieldStyles,
                        }}
                      />
                      <TextField
                        label="Email*"
                        placeholder="email@example.com"
                        type="email"
                        name="email"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={formData.email}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { color: 'common.white' },
                        }}
                        InputProps={{
                          sx: textFieldStyles,
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{ mt: 2, color: 'common.white' }}
                      >
                        STEP 1 OF 2
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 2, color: 'common.white' }}
                        color="success"
                        onClick={handleNext}
                      >
                        NEXT
                      </Button>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <TextField
                        label="Address*"
                        placeholder="123 Main St"
                        name="address"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={formData.address}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { color: 'common.white' },
                        }}
                        InputProps={{
                          sx: textFieldStyles,
                        }}
                      />
                      
                      <FormLabel component="legend" sx={{ mt: 2, color: 'common.white' }}>
                        Select Services*
                      </FormLabel>
                      <FormGroup sx={{ mt: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.lawnCare}
                              onChange={handleServiceChange}
                              name="lawnCare"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Lawn Care"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.landscapeDesign}
                              onChange={handleServiceChange}
                              name="landscapeDesign"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Landscape Design"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.treeBushTrimming}
                              onChange={handleServiceChange}
                              name="treeBushTrimming"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Tree & Bush Trimming"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.pinestrawMulch}
                              onChange={handleServiceChange}
                              name="pinestrawMulch"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Pinestraw/Mulch"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.sprayingWeeds}
                              onChange={handleServiceChange}
                              name="sprayingWeeds"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Spraying for Weeds"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.seasonalCleanup}
                              onChange={handleServiceChange}
                              name="seasonalCleanup"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Seasonal Cleanup"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.sod}
                              onChange={handleServiceChange}
                              name="sod"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Sod"
                          sx={{ color: 'common.white' }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={formData.services.other}
                              onChange={handleServiceChange}
                              name="other"
                              sx={{ color: 'common.white', '&.Mui-checked': { color: 'common.white' } }}
                            />
                          }
                          label="Other"
                          sx={{ color: 'common.white' }}
                        />
                        {formData.services.other && (
                          <TextField
                            label="Please specify"
                            name="otherService"
                            fullWidth
                            margin="normal"
                            size="small"
                            value={formData.otherService}
                            onChange={handleChange}
                            InputLabelProps={{
                              sx: { color: 'common.white' },
                            }}
                            InputProps={{
                              sx: textFieldStyles,
                            }}
                          />
                        )}
                        <TextField
                            label="Comments"
                            name="comments"
                            fullWidth
                            margin="normal"
                            size="small"
                            value={formData.comments}
                            onChange={handleChange}
                            InputLabelProps={{
                              sx: { color: 'common.white' },
                            }}
                            InputProps={{
                              sx: textFieldStyles,
                            }}
                          />
                      </FormGroup>

                      <Typography
                        variant="body2"
                        sx={{ mt: 2, color: 'common.white' }}
                      >
                        STEP 2 OF 2
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            color: 'common.white',
                            borderColor: 'common.white',
                          }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{ color: 'common.white' }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
                {errorMessage && (
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Hero;
