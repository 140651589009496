import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Approach = () => {
  return (
    <Box
      component="section"
      aria-labelledby="sod-installation-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/sod.jpeg"
              alt="Professional sod installation process"
              sx={{
                width: '100%',
                height: '350px',
                borderRadius: 2,
                boxShadow: 3,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Our Installation Process
            </Typography>
            <Typography variant="body1" paragraph>
              At Wright Brothers we have multiple application approaches of sod installation. We offer entire yard renovation with regrading of yard to remove the water away from the home's foundation and into desired areas. This helps to provide a level, smooth, and well-drained yard away from the most asset your home. If entire install is not necessary we also provide customized service with specific area installation from thinning areas of the lawn to patching areas that have never grown or killed.
            </Typography>
            <Typography variant="body1">
              Our number one concern through the entire process is meeting the needs of the customer. That is why we will provide an after care package for the customer that meets their needs. Whether that includes assistance in temporary irrigation or installing permanent before the sod installation. Our goal is to provide you an instant lawn so you can get back to hosting, playing and making memories in a safe and lush lawn!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Approach; 