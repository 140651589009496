import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  Paper,
} from '@mui/material';
import emailjs from 'emailjs-com';

import Main from '../../layouts/Main';
import Hero from '../../components/Hero';

const ContactUs = () => {
  // Access the theme
  const theme = useTheme();

  // FORM STATE
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    message: '',
    honeyPot: '', // hidden field for spam check
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // SIMPLE VALIDATION
  const phoneRegex = /^[0-9()\-+\s]{7,}$/; // minimal phone check
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // SPAM / Basic validation
    const { name, phone, email, honeyPot } = formData;
    if (honeyPot) {
      setErrorMessage('Spam detected.');
      return;
    }
    if (!name || !phone || !email) {
      setErrorMessage('Name, Phone, and Email are required.');
      return;
    }
    if (!phoneRegex.test(phone)) {
      setErrorMessage('Invalid phone format.');
      return;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    // Prepare data for EmailJS
    const templateParams = {
      firstName: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
      serviceType: formData.message,
    };

    // EmailJS submission
    emailjs
      .send(
        'service_n1nje7l', // e.g. service_xxx
        'template_ghcfh5n', // e.g. template_xxx
        templateParams,
        'jUOsVoDOX9ze4COQ5'   // e.g., user_xxx
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage('Your message was sent successfully!');
          // Reset form
          setFormData({
            name: '',
            phone: '',
            email: '',
            address: '',
            message: '',
            honeyPot: '',
          });
        },
        (error) => {
          console.log(error.text);
          setErrorMessage(
            'There was an error sending your form. Please try again later.'
          );
        }
      );
  };

  // Common SX override for text fields
  const textFieldStyle = {
    '& .MuiFormLabel-root': { color: '#4D9C41' },
    '& .MuiOutlinedInput-root': {
      color: '#444444',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4D9C41',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4D9C41',
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#777777',
    },
  };

  return (
    <Main>
      {/* Basic SEO */}
      <Helmet>
        <title>Contact Our Landscaping Company</title>
        <meta
          name="description"
          content="Get in touch with our landscaping experts. Call or fill out our form for reliable lawn care, design, and maintenance services."
        />
        <meta
          name="keywords"
          content="Landscaping, Lawn Care, Landscape Design, Wayne County, Johnston County, Contact"
        />
      </Helmet>

      <Hero
        heading="Contact Our Landscaping Company"
        subheading="Have questions or need a quote? Reach out to us for top-quality lawn care, landscape design, and maintenance in Wayne & Johnston Counties."
        metaTitle="Contact Our Landscaping Company | Wayne & Johnston Counties"
        metaDescription="Get in touch with our landscaping experts. Fill out our form or call us for reliable lawn care, design, and maintenance services in Wayne & Johnston Counties."
        metaKeywords="Landscaping, Contact, Lawn Care, Wayne County, Johnston County, Landscape Design, Maintenance"
        backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
      />

      <Box
        position="relative"
        minHeight="100vh"
        marginTop={-13}
        display="flex"
        flexDirection="column"
        paddingTop={20}
      >
        <Container>
          <Box marginBottom={4}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, marginBottom: 2, color: '#4D9C41' }}
            >
              Contact Our Landscaping Company
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{ marginBottom: 3, color: '#444444' }}
            >
              We provide top-quality lawn care, landscape design, and maintenance
              services. Call us during business hours or fill out the form below,
              and we'll be happy to assist!
            </Typography>

            <Typography
              variant="h6"
              component="p"
              sx={{ marginBottom: 2, color: '#444444' }}
            >
              <strong style={{ color: '#4D9C41' }}>Phone Number:</strong> (919) 252-9130
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{ marginBottom: 2, color: '#444444' }}
            >
              <strong style={{ color: '#4D9C41' }}>Hours of Operation:</strong>
              <br />
              Monday - Friday: 8:00 AM - 5:00 PM
              <br />
              Saturday & Sunday: Closed
            </Typography>
            <Typography variant="h6" component="p" sx={{ color: '#444444' }}>
              Feel free to give us a call or complete the form below for a free quote
              or any general inquiries.
            </Typography>
          </Box>
        </Container>

        {/* Contact Form */}
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.grey[100], 0.7),
            py: 5,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Container>
            <Paper 
              elevation={0}
              sx={{ 
                p: 4, 
                borderRadius: 2,
                backgroundColor: 'white',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
              }}
            >
              <Typography 
                variant="h5" 
                sx={{ 
                  fontWeight: 700, 
                  mb: 3, 
                  color: '#4D9C41',
                  borderBottom: '2px solid #4D9C41',
                  paddingBottom: 1,
                  display: 'inline-block'
                }}
              >
                Send Us a Message
              </Typography>
              <form onSubmit={handleSubmit}>
                {/* Hidden honeypot field */}
                <TextField
                  type="text"
                  name="honeyPot"
                  value={formData.honeyPot}
                  onChange={handleChange}
                  sx={{ display: 'none' }}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Full Name*"
                      name="name"
                      variant="outlined"
                      fullWidth
                      value={formData.name}
                      onChange={handleChange}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Phone*"
                      name="phone"
                      variant="outlined"
                      fullWidth
                      value={formData.phone}
                      onChange={handleChange}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email*"
                      name="email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      value={formData.email}
                      onChange={handleChange}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Full Address"
                      name="address"
                      variant="outlined"
                      fullWidth
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Street, City, State, Zip"
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      name="message"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      value={formData.message}
                      onChange={handleChange}
                      sx={textFieldStyle}
                    />
                  </Grid>
                </Grid>

                {/* Error/Success Messages */}
                {errorMessage && (
                  <Typography variant="body1" sx={{ mt: 2, color: 'error.main', fontWeight: 500 }}>
                    {errorMessage}
                  </Typography>
                )}
                {successMessage && (
                  <Typography variant="body1" sx={{ mt: 2, color: '#4D9C41', fontWeight: 500 }}>
                    {successMessage}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  type="submit"
                  sx={{ 
                    mt: 3,
                    backgroundColor: '#4D9C41',
                    color: 'white',
                    fontWeight: 600,
                    px: 4,
                    py: 1,
                    '&:hover': {
                      backgroundColor: '#3d7c34',
                    }
                  }}
                >
                  Submit
                </Button>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
    </Main>
  );
};

export default ContactUs;
