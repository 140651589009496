import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { Main as MainSection, Partners, Contact } from './components';
import {useParams} from "react-router-dom";
import Hero from "../../components/Hero";

const RmockLeftGrid = [
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+1.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+2.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+3.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+4.jpg',
    title: '',
  },
];

const RmockMiddleGrid = [
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+5.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+6.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+7.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+8.jpg',
    title: '',
  },
];

const RmockRightGrid = [
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+9.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+10.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+11.jpg',
    title: '',
  },
  {
    image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+12.jpg',
    title: '',
  },
];

// const CmockLeftGrid = [
//   {
//     image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+10.jpg',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
//     title: 'Lorem ipsum dolor sit amet,',
//   },
//   {
//     image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+11.jpg',
//     description: 'Excepteur sint occaecat cupidatat non proident',
//     title: 'Consectetur adipiscing elit',
//   },
//   {
//     image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+12.jpg',
//     description: 'Eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
//     title: 'Labore et dolore magna aliqua',
//   },
//   {
//     image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright+13.jpg',
//     description:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
//     title: 'Eiusmod tempor incididunt',
//   },
// ];

// const CmockRightGrid = [
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img11.jpg',
//     description: 'At vero eos et accusamus et iusto odio dignissimos ducimus',
//     title: 'Sed ut perspiciatis',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img12.jpg',
//     description:
//       'Qui blanditiis praesentium voluptatum deleniti atque corrupti',
//     title: 'Unde omnis iste natus',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img9.jpg',
//     description:
//       'On the other hand, we denounce with righteous indignation and dislike',
//     title: 'Sit voluptatem',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img8.jpg',
//     description: 'Quos dolores et quas molestias excepturi',
//     title: 'Accusantium doloremque',
//   },
// ];

// const CmockMiddleGrid = [
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img12.jpg',
//     description: 'Et harum quidem rerum facilis est et expedita distinctio',
//     title: 'Totam rem aperiam',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img11.jpg',
//     description: 'Nam libero tempore, cum soluta nobis est eligendi optio',
//     title: 'Uae ab illo inventore',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img8.jpg',
//     description: 'Itaque earum rerum hic tenetur a sapiente delectus',
//     title: 'Beatae vitae dicta',
//   },
//   {
//     image: 'https://assets.maccarianagency.com/backgrounds/img9.jpg',
//     description:
//       'On the other hand, we denounce with righteous indignation and dislike',
//     title: 'Nemo enim ipsam',
//   },
// ];

const data = {
    residential : {left: RmockLeftGrid, middle: RmockMiddleGrid, right: RmockRightGrid},
    // commercial : {left: CmockLeftGrid, middle: CmockMiddleGrid, right: CmockRightGrid}
}
const ResidentialPortfolio = () => {
  const theme = useTheme();
  const {type} = useParams();

  return (
    <Main colorInvert={true}>
      <Hero
  heading="Our Portfolio"
  subheading="Discover how we transform residential and commercial properties across Wayne and Johnston Counties with exceptional landscaping expertise."
  metaTitle="Landscaping Portfolio in Wayne & Johnston County"
  metaDescription="Explore our landscaping portfolio showcasing top-quality projects across Wayne and Johnston Counties—from lawn transformations to intricate landscape designs."
  metaKeywords="Landscaping, Portfolio, Wayne County, Johnston County, Lawn Care, Landscape Design"
  backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
  showForm={false}
/>

      {/*<Container>*/}
      {/*  <Partners />*/}
      {/*</Container>*/}
      <Container>
        <MainSection mockLeftGrid={data[type].left} mockMiddleGrid={data[type].middle} mockRightGrid={data[type].right}/>
      </Container>
      <Box
        position={'relative'}
        marginTop={{ xs: 4, md: 6 }}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 2,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
        <Container>
          <Contact />
        </Container>
      </Box>
    </Main>
  );
};

export default ResidentialPortfolio;
