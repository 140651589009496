import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import Hero from '../../components/Hero'
import {
  Faq,
    Guides,
    Links

} from './components';
import {useTheme} from "@mui/material/styles";


const OurStory = () => {
    const theme = useTheme();
  return (
    <Main colorInvert={true}>
      <Hero
  heading="Homeowner Resources"
  subheading="Everything you need to maintain a beautiful property in Wayne and Johnston Counties—from expert lawn care tips to seasonal maintenance guides and FAQs."
  metaTitle="Homeowner Resources in Wayne & Johnston County"
  metaDescription="Access essential landscaping resources, maintenance guides, and FAQs tailored to homeowners in Wayne and Johnston Counties."
  metaKeywords="Homeowner Resources, Landscaping Tips, Wayne County, Johnston County, Lawn Care, Maintenance Guides"
  backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
/>

      <Faq/>
      <Guides/>
      <Container maxWidth={800} paddingY={'0 !important'}>
        <Divider />
      </Container>
      <Links/>

    </Main>
  );
};

export default OurStory;
