import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Page from "./components/Page";
import Service from './views/Service/Service';
import React from 'react';
import ContactUs from './views/ContactPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import OurStory from "./views/OurStory";
import Faq from "./views/Faq";
import Location from "./views/Location";
import Testimonials from "./views/Testimonials2";
import ResidentialServices from "./views/ResidentialServices/ResidentialServices";
import ResidentialPortfolio from "./views/ResidentialPortfolio";
import HomeownerResources from "./views/HomeownerResources";
import CommercialServices from "./views/CommercialServices";
import LawnMaintenance from "./views/Lawn/LawnMaintenance";
import IndustrySolutions from "./views/IndustrySolutions";
import LandscapeDesign from "./views/LandscapeDesign/LandscapeDesign";
import LandscapeMaintenance from "./views/LandscapeMaintenance/LandscapeMaintenance";
import TurfCare from "./views/TurfCare/TurfCare";
import SodInstallation from "./views/SodInstallation/SodInstallation";
import MeetTheTeam from "./views/MeetTheTeam/MeetTheTeam";
import Careers from "./views/Careers/Careers";
import OtherServices from "./views/OtherServices/OtherServices";
import Pay from "./views/Pay/Pay";

// Import location components
import SmithfieldLocation from './views/Locations/Smithfield';
import FourOaksLocation from './views/Locations/FourOaks';
import SelmaLocation from './views/Locations/Selma';
import PrincetonLocation from './views/Locations/Princeton';
import GoldsboroLocation from './views/Locations/Goldsboro';
import ClaytonLocation from './views/Locations/Clayton';
import MountOliveLocation from './views/Locations/MountOlive';
import LaGrangeLocation from './views/Locations/LaGrange';
import PikevilleLocation from './views/Locations/Pikeville';
function App() {
  return (
    <Page>
      <Router>
          <Routes>
            <Route path="/pay" element={<Pay />} />
              <Route path="/" element={<Service />} />
              {/*<Route path={"/contact"} element={<ContactUs />} />*/}
            {/*<Route path="/signup" element={<SignUp />} />*/}

            {/*<Route path={"/login"} element={<Login/>} />*/}
              {/*<Route path="/login" element={<Login />} />*/}
            <Route path='/about' element={<OurStory />} />
              <Route path='/faq' element={<Faq />} />
              <Route path='/services/other-services' element={<OtherServices/>}/>
              <Route path='/residential/resources' element={<HomeownerResources/>}/>

              <Route path='/:type/portfolio' element={<ResidentialPortfolio/>}/>
              <Route path='/commercial/industries' element={<IndustrySolutions/>}/>
              <Route path='testimonials' element={<Testimonials />} />
            {/* Catch-All Route: Redirect to Home if no match is found */}
              <Route path={"/contact"} element={<ContactUs />} />
              
              {/* Location Routes */}
              <Route path='/smithfield' element={<SmithfieldLocation />} />
              <Route path='/fouroaks' element={<FourOaksLocation />} />
              <Route path='/selma' element={<SelmaLocation />} />
              <Route path='/princeton' element={<PrincetonLocation />} />
              <Route path='/goldsboro' element={<GoldsboroLocation />} /> 
              <Route path='/clayton' element={<ClaytonLocation />} />
              <Route path='/mount-olive' element={<MountOliveLocation />} />
              <Route path='/la-grange' element={<LaGrangeLocation />} />
              <Route path='/pikeville' element={<PikevilleLocation />} />
              {/* <Route path={'/location/:location'} element={<Location />} /> */}
              
              <Route path={'/meet-the-team'} element={<MeetTheTeam/>} />
              {/* Use the nav links to create the routes for the pages especially for the services pages */}
              <Route path='/services/lawn-maintenance' element={<LawnMaintenance/>}/>
              <Route path='/services/landscape-design' element={<LandscapeDesign/>}/>
              <Route path='/services/commercial-services' element={<CommercialServices/>}/>
              <Route path='/services/landscape-maintenance' element={<LandscapeMaintenance/>}/>
              <Route path='/services/turf-care' element={<TurfCare/>}/>
              <Route path='/services/sod-installation' element={<SodInstallation/>}/>
             
              <Route path='/about/careers' element={<Careers/>}/>
              
            <Route path="*" element={<Navigate to="/" replace />} />
              {/*<Route path={"/products"} element={<Pricing />} />*/}
          </Routes>

      </Router>
    </Page>
  );
}

export default App;