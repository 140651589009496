import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Divider,
  Avatar,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import EventIcon from '@mui/icons-material/Event';
import GrassIcon from '@mui/icons-material/Grass';

/**
 * Highlights local community news and testimonials for Goldsboro & Clayton,
 * or shows a default highlight card for other locations.
 */
const CommunityHighlight = ({ location }) => {
  // Default / fallback
  let title = 'Serving the Community';
  let metaDescription =
    'Learn how our landscaping services support the local communities of Wayne & Johnston County.';
  let content =
    'We take pride in supporting the local community through charitable initiatives, volunteering, and providing top-notch landscaping services.';

  // Goldsboro-specific metadata
  if (location === 'goldsboro') {
    title = 'Goldsboro Community News & Testimonials';
    metaDescription =
      'Discover local Goldsboro news and testimonials about our landscaping services.';
  }
  // Clayton-specific metadata
  else if (location === 'clayton') {
    title = 'Clayton Community News & Testimonials';
    metaDescription =
      'Discover local Clayton news and testimonials about our landscaping services.';
    content =
      'We love Clayton! Our team invests time and resources into local events and non-profit organizations, ensuring our landscaping work also gives back to the community.';
  }
  // Smithfield-specific metadata
  else if (location === 'smithfield') {
    title = 'Smithfield Community News & Testimonials';
    metaDescription =
      'Discover local Smithfield news and testimonials about our landscaping services.';
    content =
      'We are proud to serve Smithfield! Our team is committed to beautifying the community through quality landscaping and active participation in local initiatives.';
  }
  // Four Oaks-specific metadata
  else if (location === 'fouroaks') {
    title = 'Four Oaks Community News & Testimonials';
    metaDescription =
      'Discover local Four Oaks news and testimonials about our landscaping services.';
    content =
      'Four Oaks holds a special place in our hearts! We take pride in enhancing the natural beauty of this community through our dedicated landscaping services.';
  }
  // Selma-specific metadata
  else if (location === 'selma') {
    title = 'Selma Community News & Testimonials';
    metaDescription =
      'Discover local Selma news and testimonials about our landscaping services.';
    content =
      'Serving Selma is our privilege! Our team is dedicated to creating beautiful outdoor spaces while supporting the vibrant community that makes this town special.';
  }
  // Princeton-specific metadata
  else if (location === 'princeton') {
    title = 'Princeton Community News & Testimonials';
    metaDescription =
      'Discover local Princeton news and testimonials about our landscaping services.';
    content =
      'Princeton residents deserve exceptional service! We\'re committed to maintaining and enhancing the beauty of this charming community through our landscaping expertise.';
  }
  // Mount Olive-specific metadata
  else if (location === 'mount-olive') {
    title = 'Mount Olive Community News & Testimonials';
    metaDescription =
      'Discover local Mount Olive news and testimonials about our landscaping services.';
    content =
      'Mount Olive is a special community to us! We take pride in providing exceptional landscaping services while supporting local events and organizations.';
  }
  // Pikeville-specific metadata
  else if (location === 'pikeville') {
    title = 'Pikeville Community News & Testimonials';
    metaDescription =
      'Discover local Pikeville news and testimonials about our landscaping services.';
    content =
      'Pikeville residents deserve the best! Our team is dedicated to enhancing the beauty of your properties while giving back to this wonderful community.';
  }
  // La Grange-specific metadata
  else if (location === 'la-grange') {
    title = 'La Grange Community News & Testimonials';
    metaDescription =
      'Discover local La Grange news and testimonials about our landscaping services.';
    content =
      'We love serving La Grange! Our team is committed to providing top-quality landscaping services while supporting local initiatives that make this community special.';
  }

  // Sample arrays for Goldsboro
  const goldsboroNews = [
    {
      id: 1,
      title: 'Goldsboro Spring Festival',
      date: 'April 12, 2025',
      snippet:
        'Join us for the annual Spring Festival in downtown Goldsboro, featuring live music, food trucks, and family-friendly activities.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Community Garden Launch',
      date: 'May 1, 2025',
      snippet:
        "We're excited to partner with local volunteers to launch a new community garden—growing fresh produce for neighbors in need.",
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const goldsboroTestimonials = [
    {
      id: 1,
      name: 'John D.',
      quote:
        'Absolutely amazing service! My lawn has never looked better. The team is friendly, professional, and really cares about the community.',
    },
    {
      id: 2,
      name: 'Sarah W.',
      quote:
        'They transformed my backyard into an oasis. I love how they also give back to local charities here in Goldsboro.',
    },
  ];

  // Sample arrays for Clayton
  const claytonNews = [
    {
      id: 1,
      title: 'Clayton Summer Bash',
      date: 'June 10, 2025',
      snippet:
        'Enjoy live music, local food vendors, and fun activities at the Clayton Summer Bash—supporting area charities.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Farmers Market Reopening',
      date: 'July 5, 2025',
      snippet:
        'The Clayton Farmers Market reopens this summer, providing fresh produce and crafts from local vendors every Saturday.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const claytonTestimonials = [
    {
      id: 1,
      name: 'Emily R.',
      quote:
        'The best landscaping team in Clayton! They turned my yard around in no time, and I love their commitment to the community.',
    },
    {
      id: 2,
      name: 'Tom G.',
      quote:
        'Their dedication to quality and fair pricing is unmatched. My lawn has never looked healthier!',
    },
  ];

  // Sample arrays for Smithfield
  const smithfieldNews = [
    {
      id: 1,
      title: 'Smithfield Heritage Festival',
      date: 'September 15, 2025',
      snippet:
        'Join the celebration of Smithfield\'s rich heritage with local food, crafts, and entertainment for the whole family.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Community Cleanup Day',
      date: 'October 5, 2025',
      snippet:
        'Volunteer with us as we partner with local organizations to beautify parks and public spaces throughout Smithfield.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const smithfieldTestimonials = [
    {
      id: 1,
      name: 'Robert M.',
      quote:
        'Wright Brothers transformed our property completely. Their attention to detail and knowledge of plants native to our area made all the difference.',
    },
    {
      id: 2,
      name: 'Lisa K.',
      quote:
        'I\'ve tried several lawn care companies in Smithfield, but none compare to Wright Brothers. They\'re reliable, professional, and truly care about their work.',
    },
  ];

  // Sample arrays for Four Oaks
  const fourOaksNews = [
    {
      id: 1,
      title: 'Four Oaks Peach Festival',
      date: 'July 20, 2025',
      snippet:
        'Don\'t miss the annual Peach Festival with local vendors, activities, and fresh produce from area farms.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Park Revitalization Project',
      date: 'August 8, 2025',
      snippet:
        'We\'re partnering with the town to revitalize the community park with new landscaping, trees, and sustainable garden areas.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const fourOaksTestimonials = [
    {
      id: 1,
      name: 'Mark B.',
      quote:
        'Wright Brothers has been maintaining our property for over two years and we couldn\'t be happier. Their attention to detail is remarkable.',
    },
    {
      id: 2,
      name: 'Amanda C.',
      quote:
        'The landscape design they created for our new home perfectly captures our style while using plants that thrive in our local conditions. Highly recommend!',
    },
  ];

  // Sample arrays for Selma
  const selmaNews = [
    {
      id: 1,
      title: 'Selma Railroad Days',
      date: 'September 30, 2025',
      snippet:
        'Celebrate Selma\'s railroad heritage with this annual festival featuring train displays, live entertainment, and family activities.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Downtown Beautification',
      date: 'October 15, 2025',
      snippet:
        'We\'re proud to contribute to Selma\'s downtown beautification efforts with sustainable landscaping along Main Street.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const selmaTestimonials = [
    {
      id: 1,
      name: 'Richard T.',
      quote:
        'Wright Brothers completely transformed our commercial property\'s curb appeal. Their maintenance program keeps everything looking pristine year-round.',
    },
    {
      id: 2,
      name: 'Nicole H.',
      quote:
        'After struggling with drainage issues for years, Wright Brothers implemented a solution that completely solved the problem and improved our lawn\'s health.',
    },
  ];

  // Sample arrays for Princeton
  const princetonNews = [
    {
      id: 1,
      title: 'Princeton Community Day',
      date: 'June 25, 2025',
      snippet:
        'Join us for Princeton\'s annual Community Day with games, food, and activities celebrating our town\'s close-knit spirit.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Native Plant Initiative',
      date: 'July 12, 2025',
      snippet:
        'We\'re launching a program to encourage the use of native plants in residential landscapes, promoting sustainability and local ecosystems.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const princetonTestimonials = [
    {
      id: 1,
      name: 'Daniel W.',
      quote:
        'Wright Brothers created a beautiful, low-maintenance landscape for our home that has thrived despite the challenging weather conditions we face in Princeton.',
    },
    {
      id: 2,
      name: 'Stephanie J.',
      quote:
        'Their lawn care team is always reliable, courteous, and thorough. My lawn has never looked better, and they\'re always ready with helpful advice.',
    },
  ];

  // Sample arrays for Mount Olive
  const mountOliveNews = [
    {
      id: 1,
      title: 'Mount Olive Pickle Festival',
      date: 'April 25, 2025',
      snippet:
        'Don\'t miss the annual Pickle Festival with food, games, and entertainment celebrating Mount Olive\'s famous pickle heritage.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Downtown Beautification Project',
      date: 'May 20, 2025',
      snippet:
        'We\'re proud to participate in the downtown beautification initiative, enhancing public spaces with sustainable landscaping.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const mountOliveTestimonials = [
    {
      id: 1,
      name: 'David W.',
      quote:
        'Wright Brothers completely revitalized our commercial property. Their team was professional, efficient, and the results exceeded our expectations.',
    },
    {
      id: 2,
      name: 'Patricia H.',
      quote:
        'I\'ve been a customer for three years now, and my lawn has never looked better. Their seasonal care program is worth every penny!',
    },
  ];

  // Sample arrays for Pikeville
  const pikevilleNews = [
    {
      id: 1,
      title: 'Pikeville Summer Concert Series',
      date: 'July 10, 2025',
      snippet:
        'Enjoy live music in the park every Friday evening throughout July, featuring local musicians and food vendors.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Tree Planting Initiative',
      date: 'August 15, 2025',
      snippet:
        'Join us as we partner with the town to plant 100 new trees throughout Pikeville, enhancing our community\'s green spaces.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const pikevilleTestimonials = [
    {
      id: 1,
      name: 'James T.',
      quote:
        'The irrigation system Wright Brothers installed has made such a difference in our lawn\'s health. Their knowledge and expertise are unmatched in the area.',
    },
    {
      id: 2,
      name: 'Karen B.',
      quote:
        'From the initial consultation to the final walkthrough, Wright Brothers provided exceptional service. Our new landscape design has transformed our home.',
    },
  ];

  // Sample arrays for La Grange
  const laGrangeNews = [
    {
      id: 1,
      title: 'La Grange Fall Festival',
      date: 'October 20, 2025',
      snippet:
        'Celebrate autumn at the annual Fall Festival with hayrides, pumpkin carving, and family activities throughout downtown La Grange.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      id: 2,
      title: 'Community Garden Expansion',
      date: 'November 5, 2025',
      snippet:
        'We\'re helping expand the community garden with new raised beds and irrigation systems to support local food security initiatives.',
      icon: <GrassIcon fontSize="large" />,
    },
  ];
  const laGrangeTestimonials = [
    {
      id: 1,
      name: 'Michael S.',
      quote:
        'Wright Brothers completely transformed our backyard into an outdoor living space we use year-round. Their design team listened to our needs and delivered beyond our expectations.',
    },
    {
      id: 2,
      name: 'Jennifer L.',
      quote:
        'The lawn maintenance program has been a game-changer for us. Our yard is the envy of the neighborhood, and their team is always professional and courteous.',
    },
  ];

  // Determine which location logic applies
  const isGoldsboro = location === 'goldsboro';
  const isClayton = location === 'clayton';
  const isSmithfield = location === 'smithfield';
  const isFourOaks = location === 'fouroaks';
  const isSelma = location === 'selma';
  const isPrinceton = location === 'princeton';
  const isMountOlive = location === 'mount-olive';
  const isPikeville = location === 'pikeville';
  const isLaGrange = location === 'la-grange';

  return (
    <>
      {/* Optional section-specific SEO */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="community-highlight"
        sx={{
          backgroundColor: 'primary.main',
          py: { xs: 4, md: 6 },
          color: 'white'
        }}
      >
        <Container>
          <Typography
            variant="h4"
            component="h2"
            id="community-highlight"
            gutterBottom
            sx={{ fontWeight: 700, color: 'white' }}
          >
            {title}
          </Typography>

          {isGoldsboro ? (
            <>
              {/* NEWS SECTION (Goldsboro) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {goldsboroNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Goldsboro) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {goldsboroTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isClayton ? (
            <>
              {/* NEWS SECTION (Clayton) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {claytonNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Clayton) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {claytonTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isSmithfield ? (
            <>
              {/* NEWS SECTION (Smithfield) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {smithfieldNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Smithfield) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {smithfieldTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isFourOaks ? (
            <>
              {/* NEWS SECTION (Four Oaks) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {fourOaksNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Four Oaks) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {fourOaksTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isSelma ? (
            <>
              {/* NEWS SECTION (Selma) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {selmaNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Selma) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {selmaTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isPrinceton ? (
            <>
              {/* NEWS SECTION (Princeton) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {princetonNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Princeton) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {princetonTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isMountOlive ? (
            <>
              {/* NEWS SECTION (Mount Olive) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {mountOliveNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Mount Olive) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {mountOliveTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isPikeville ? (
            <>
              {/* NEWS SECTION (Pikeville) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {pikevilleNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (Pikeville) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {pikevilleTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : isLaGrange ? (
            <>
              {/* NEWS SECTION (La Grange) */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 600, color: 'white' }}>
                Local News & Updates
              </Typography>
              <Grid container spacing={3}>
                {laGrangeNews.map((newsItem) => (
                  <Grid item xs={12} md={6} key={newsItem.id}>
                    <Card sx={{ display: 'flex', boxShadow: 3, bgcolor: 'primary.main' }}>
                      <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center', width: 80 }}>
                        <Avatar sx={{ bgcolor: 'primary.light', width: 60, height: 60 }}>
                          {newsItem.icon}
                        </Avatar>
                      </Box>
                      <CardContent>
                        <Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>
                          {newsItem.title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {newsItem.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {newsItem.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.2)' }} />

              {/* TESTIMONIALS SECTION (La Grange) */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: 'white' }}>
                What Our Neighbors Are Saying
              </Typography>
              <Grid container spacing={3}>
                {laGrangeTestimonials.map((testimonial) => (
                  <Grid item xs={12} md={6} key={testimonial.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'primary.dark',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" fontStyle="italic" sx={{ color: 'white' }}>
                        "{testimonial.quote}"
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, alignSelf: 'flex-end', color: 'rgba(255, 255, 255, 0.7)' }}
                      >
                        – {testimonial.name}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            // DEFAULT / OTHER LOCATIONS
            <Card
              sx={{
                backgroundColor: 'primary.dark',
                boxShadow: 3,
              }}
            >
              <CardContent>
                <Typography variant="body1" sx={{ color: 'white' }}>{content}</Typography>
              </CardContent>
            </Card>
          )}
        </Container>
      </Box>
    </>
  );
};

export default CommunityHighlight;
