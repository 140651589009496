import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Examples = () => {
  const theme = useTheme();
  const clientTypes = [
    {
      title: 'Business Owners',
      description: 'For retail and customer-facing businesses, curb appeal can directly impact the volume of foot traffic. People are more likely to visit a business if the storefront looks inviting and well-maintained. A well-maintained property contributes to the branding of the business as well, offering an aligned experience between the exterior and interior.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/commercial.jpeg',
    },
    {
      title: 'Property Managers',
      description: 'The exterior of commercial property is the first thing potential tenants will see. A well-maintained and visually appealing building can make it more likely that prospective tenants will consider the space their home. Tenants are more likely to stay longer in a property that is consistently maintained and looks good. It creates a positive environment that tenants are proud to be part of, which can lead to better retention rates.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/commercial1.jpeg',
    }
  ];

  return (
    <Box
      component="section"
      aria-labelledby="commercial-services"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Who We Serve
        </Typography>
        <Grid container spacing={4}>
          {clientTypes.map((client, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="240"
                  image={client.image}
                  alt={client.title}
                  sx={{ objectPosition: 'center' }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
                    {client.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000000' }}>
                    {client.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples; 