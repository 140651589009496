// create a page that has the info with image component and the info with image component has an image of a lawn on the left side

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Examples, CTA, } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';
import PruningSection from './components/Approach/PruningSection';
import MulchSection from './components/Approach/MulchSection';

const LandscapeMaintenance = () => {
  const theme = useTheme();
  return (
    // Here is the hero components expected props, make them relevant to the lawn maintenance page
//     heading = 'Industry Specific Solutions',
//   subheading = 'We cater to the unique needs of businesses in Wayne and Johnston Counties. From healthcare campuses to retail centers, our specialized commercial landscaping services make a lasting impression.',
//   metaTitle = 'Industry-Specific Commercial Landscaping Solutions',
//   metaDescription = 'Explore tailored commercial landscaping services for various industries in Wayne and Johnston Counties, including healthcare, retail, and more.',
//   metaKeywords = 'Commercial Landscaping, Industry-Specific Solutions, Wayne County, Johnston County, Healthcare, Retail, Corporate',
    // Update the hero component with relevant props for Landscape Maintenance and all relevant words and phrases, for SEO
    <Main>
      <Hero 
        heading="Landscape Maintenance" 
        subheading="Professional pruning and mulching services to enhance your property's health, appearance, and sustainability. We focus on creating beautiful, well-maintained outdoor spaces that thrive in all seasons." 
        metaTitle="Professional Landscape Maintenance Services | Bush & Tree Pruning, Mulch & Pine Straw" 
        metaDescription="Expert landscape maintenance including bush pruning, tree care, and mulch installation. Enhance your property's beauty and health with our professional services." 
        metaKeywords="Landscape Maintenance, Bush Pruning, Tree Pruning, Mulch Installation, Pine Straw, Garden Beds, Wayne County, Johnston County" 
      />
      <PruningSection />
      <MulchSection />
      <Box bgcolor={theme.palette.primary.main}>
        <Examples />
      </Box>
      <CTA />
    </Main>
  );
};

export default LandscapeMaintenance;