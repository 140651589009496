import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const Gallery = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  /**
   * Each photo now has a descriptive alt tag that references key towns in
   * Wayne County (e.g., Goldsboro, Mount Olive, Fremont) and Johnston County
   * (e.g., Smithfield, Clayton, Selma) for SEO and accessibility.
   */
  const photos = [
    {
      src: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image0.jpeg',
      source: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image0.jpeg',
      rows: 2,
      cols: 1,
      alt: 'Lush lawn and vibrant garden beds in Goldsboro, Wayne County, showcasing professional landscaping',
    },
    {
      src: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image1.jpeg',
      source: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image1.jpeg',
      rows: 1,
      cols: 2,
      alt: 'Expertly landscaped front yard in Clayton, Johnston County, with flower beds and a stone pathway',
    },
    {
      src: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image4.jpeg',
      source: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image4.jpeg',
      rows: 1,
      cols: 1,
      alt: 'Beautiful backyard redesign in Mount Olive, Wayne County, including a cozy seating area and lush greenery',
    },
    {
      src: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image11.jpeg',
      source: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image11.jpeg',
      rows: 1,
      cols: 1,
      alt: 'Stylish patio and lawn renovation in Smithfield, Johnston County, perfect for outdoor entertaining',
    },
    {
      src: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image6.jpeg',
      source: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/image6.jpeg',
      rows: 1,
      cols: 2,
      alt: 'Stone walkway in Fremont, Wayne County, surrounded by freshly manicured lawn, highlighting landscaping services',
    },
  ];

  // Show fewer photos on smaller screens
  const photosToShow = isMd ? photos : photos.slice(0, photos.length - 1);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'text.secondary'}
          align={'center'}
        >
          Gallery
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          color={'text.secondary'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Small team. Big hearts.
        </Typography>
        <Typography variant="h6" align={'center'} color={'text.secondary'}>
          Our focus is always on delivering exceptional landscaping solutions that bring your outdoor vision to life.
          We set the bar high, but if you're ready to transform your yard or property into a space that truly stands out,
          we’re excited to help make it happen. We proudly serve Wayne County (Goldsboro, Mount Olive, Fremont) and
          Johnston County (Smithfield, Clayton, Selma, and beyond), offering a range of services from lawn maintenance
          to full-scale landscape design.
        </Typography>
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={4}
          rowHeight={isMd ? 300 : 220}
          gap={isMd ? 16 : 8}
        >
          {photosToShow.map((item, i) => (
            <ImageListItem
              key={i}
              cols={isMd ? item.cols || 1 : 2}
              rows={isMd ? item.rows || 1 : 1}
            >
              <img
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt={item.alt}
                loading="lazy"
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'pointer',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
};

export default Gallery;
