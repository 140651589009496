// LandscapeDesignServices.js
import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Examples = () => {
  const theme = useTheme();
  const benefits = [
    {
      title: 'Lifestyle Enhancement',
      description: "Good landscape design helps you maximize the use of your outdoor space. Whether you want a functional space for entertaining, a quiet retreat for relaxation, or an area for kids and pets to play, thoughtful landscape design helps you create the right atmosphere and make the most of your yard.",
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/landscape_install2.jpeg',
    },
    {
      title: 'Property Value',
      description: 'Landscape design can have numerous benefits from increased property value, beauty from blooms, enhanced color, and added curb appeal! Property planned landscapes can provide color throughout the year along with memories and pictures that will last a lifetime.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/wright_property_value.jpeg',
    },
    {
      title: 'Functionality',
      description: 'It can help increase privacy with features like hedges, privacy shrubs, or strategically placed trees. Strategically placed shrubbery, trees, flowers, pavers & landscape material not only increase value but also lifestyle.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Wright_functionallity.jpeg',
    }
  ];

  return (
    <Box
      component="section"
      aria-labelledby="design-benefits"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Benefits of Professional Design
        </Typography>
        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="240"
                  image={benefit.image}
                  alt={benefit.title}
                  sx={{ objectPosition: 'center' }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000000' }}>
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples;