// SuccessStories.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

const SuccessStories = () => {
  // Example data: real projects/testimonials
  const stories = [
    {
      industry: 'Healthcare',
      title: 'Hospital Grounds Revamp',
      description:
        'We revitalized the entrance gardens and walkways for a local hospital, creating a soothing environment for patients and visitors.',
      image: 'https://via.placeholder.com/600x400?text=Healthcare+Case+Study',
    },
    {
      industry: 'Corporate',
      title: 'Office Park Transformation',
      description:
        'Enhanced curb appeal and employee satisfaction with an updated landscape design, including water features and native plantings.',
      image: 'https://via.placeholder.com/600x400?text=Corporate+Case+Study',
    },
    {
      industry: 'Retail',
      title: 'Shopping Center Landscape Refresh',
      description:
        'Improved foot traffic and customer experience by adding vibrant seasonal flowers, better lighting, and clear signage.',
      image: 'https://via.placeholder.com/600x400?text=Retail+Case+Study',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Commercial Landscaping Success Stories | Industry Solutions</title>
        <meta
          name="description"
          content="Explore real-world case studies showcasing our commercial landscaping success across various industries, including healthcare, corporate, and retail."
        />
        <meta
          name="keywords"
          content="Commercial Landscaping, Case Studies, Success Stories, Healthcare, Corporate, Retail"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="success-stories"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.grey[50], 0.4),
        }}
      >
        <Container>
          <Typography
            id="success-stories"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700, mb: 3 }}
          >
            Success Stories
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            See how our industry-focused solutions have made a positive impact on
            businesses throughout Wayne and Johnston Counties:
          </Typography>

          <Grid container spacing={4}>
            {stories.map((story, idx) => (
              <Grid item xs={12} md={4} key={idx}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 3,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={story.title}
                    height="180"
                    image={story.image}
                  />
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      {story.industry}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700, mt: 1 }}>
                      {story.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {story.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SuccessStories;
