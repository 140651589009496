import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-content--${i}`}
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography>{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Landscaping Services Basics'}
          items={[
            {
              title: 'What areas do you serve?',
              subtitle:
                'We proudly serve cities in Wayne and Johnston County, NC, including Goldsboro, Smithfield, Clayton, and Selma.',
            },
            {
              title: 'Do you offer free estimates?',
              subtitle:
                'Yes, we provide free estimates for all landscaping services. Contact us to schedule an appointment in Goldsboro or any nearby area.',
            },
            {
              title: 'What services do you provide?',
              subtitle:
                'We offer lawn care, landscaping, hardscaping, tree trimming, and seasonal clean-up services in Wayne and Johnston County.',
            },
            {
              title: 'Are your services customizable?',
              subtitle:
                'Absolutely! We tailor our services to meet your specific needs, whether you need regular lawn maintenance or a one-time landscaping project.',
            },
            {
              title: 'Do you provide emergency storm clean-up?',
              subtitle:
                'Yes, we offer storm clean-up services in cities like Goldsboro and Clayton to quickly restore your yard to its best condition.',
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Scheduling and Payments'}
          items={[
            {
              title: 'How do I schedule a service?',
              subtitle:
                'You can schedule a service by calling us or using our online booking form. We serve clients in Goldsboro, Smithfield, and surrounding areas.',
            },
            {
              title: 'What payment methods do you accept?',
              subtitle:
                'We accept cash, checks, and major credit cards for your convenience.',
            },
            {
              title: 'Do you offer recurring service plans?',
              subtitle:
                'Yes, we offer weekly, bi-weekly, and monthly plans for lawn care and landscaping in Johnston and Wayne County.',
            },
            {
              title: 'Can I reschedule or cancel my appointment?',
              subtitle:
                'Yes, please contact us at least 24 hours in advance to reschedule or cancel your appointment.',
            },
            {
              title: 'Do you offer any discounts?',
              subtitle:
                'We provide discounts for senior citizens, military personnel, and first-time customers in Goldsboro and nearby areas.',
            },
          ]}
        />
      </Box>
      <Box>
        <FaqGroupItem
          title={'Lawn Care and Maintenance'}
          items={[
            {
              title: 'How often should I mow my lawn?',
              subtitle:
                'We recommend mowing your lawn every 1-2 weeks depending on the season and growth rate.',
            },
            {
              title: 'Do you offer weed control services?',
              subtitle:
                'Yes, we provide effective weed control services to keep your lawn looking pristine throughout Wayne and Johnston County.',
            },
            {
              title: 'Can you help with irrigation system installation?',
              subtitle:
                'Yes, we specialize in installing and maintaining irrigation systems in cities like Clayton and Selma.',
            },
            {
              title: 'What is included in seasonal clean-up?',
              subtitle:
                'Seasonal clean-up includes leaf removal, trimming, and preparing your lawn for the upcoming season.',
            },
            {
              title: 'Do you provide pest control for lawns?',
              subtitle:
                'Yes, we offer pest control solutions to protect your lawn from common pests in Goldsboro and surrounding areas.',
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
