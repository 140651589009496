import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GrassIcon from '@mui/icons-material/Grass';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import ScheduleIcon from '@mui/icons-material/Schedule';

const reasons = [
  {
    title: 'Experienced Professionals',
    subtitle: 'Our skilled team brings years of expertise to every lawn we care for.',
    icon: <VerifiedUserIcon fontSize="large" />,
  },
  {
    title: 'Eco-Friendly Practices',
    subtitle: 'We use environmentally conscious methods and products.',
    icon: <GrassIcon fontSize="large" />,
  },
  {
    title: 'Customer Satisfaction',
    subtitle: 'We take pride in building lasting relationships with our clients.',
    icon: <ThumbUpIcon fontSize="large" />,
  },
  {
    title: 'Quality Guaranteed',
    subtitle: "We stand behind our work to ensure you're always happy with the results.",
    icon: <CheckCircleIcon fontSize="large" />,
  },
  {
    title: 'Affordable Pricing',
    subtitle: 'Competitive rates that fit your budget without compromising quality.',
    icon: <AttachMoneyIcon fontSize="large" />,
  },
  {
    title: 'Comprehensive Services',
    subtitle: 'From mowing to landscaping, we handle all aspects of lawn care.',
    icon: <BuildIcon fontSize="large" />,
  },
  {
    title: 'Reliable Scheduling',
    subtitle: 'Timely and consistent service appointments you can depend on.',
    icon: <ScheduleIcon fontSize="large" />,
  },
  {
    title: 'Licensed & Insured',
    subtitle: 'Fully certified and insured to provide top-quality service.',
    icon: <VerifiedUserIcon fontSize="large" />,
  },
];

const OurMissionAndValues = () => {
  return (
    <Box>
      <Box marginBottom={6} textAlign="center">
        <Typography
          sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
          gutterBottom
          color="white"
        >
          Our Mission & Values
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700, color: 'white' }}>
          Faith-Based Service
        </Typography>
      </Box>

      {/* Mission Statement Section with Divider */}
      <Box marginBottom={6}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={5}>
              <Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, color: 'white' }}>
                  Our Mission
                </Typography>
                <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
                  At Wright Brothers Lawn Care, we believe in caring for our customers the way Jesus taught us to care for one another. We strive to provide exceptional service with love, integrity, and dedication.
                </Typography>
                <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
                  Our foundation is built on biblical principles of honesty, hard work, and treating others as we would want to be treated.
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={2} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              <Divider orientation="vertical" sx={{ 
                height: '100%', 
                minHeight: '200px',
                borderColor: 'rgba(255, 255, 255, 0.5)', 
                borderRightWidth: 2,
                mx: 'auto'
              }} />
            </Grid>
            
            <Grid item xs={12} md={5}>
              <Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, color: 'white' }}>
                  Our Promise
                </Typography>
                <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
                  "Love your neighbor as yourself" isn't just a saying for us—it's how we do business. We're committed to serving our community with excellence and compassion.
                </Typography>
                <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
                  When you choose Wright Brothers, you're not just getting lawn care; you're partnering with a team that genuinely cares about you and your property. We're here to support our community and build lasting relationships based on trust and quality service.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Box>
  );
};

export default OurMissionAndValues;
