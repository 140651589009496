import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import { Hero, Services, CommunityHighlight, WhyChooseUs, RelatedLocations, ServiceLinks } from '../Location/components';
import { useTheme } from "@mui/material/styles";
import { Typography, Container, Grid, Button, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Dedicated page for Goldsboro lawn care and landscaping services
 * with rich content specific to this location for improved SEO.
 */
const GoldsboroLocation = () => {
  const theme = useTheme();
  const locationName = "goldsboro";
  
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>Professional Lawn Care & Landscaping Services in Goldsboro, NC | Wright Brothers Lawn Care</title>
        <meta 
          name="description" 
          content="Expert lawn care and landscaping in Goldsboro, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." 
        />
        <meta 
          name="keywords" 
          content="lawn care Goldsboro NC, landscaping Goldsboro NC, sod installation Wayne County, commercial landscaping Goldsboro, turfcare services Goldsboro, lawn maintenance Goldsboro, landscape design Wayne County, lawn fertilization Goldsboro, weed control Goldsboro, professional lawn service Wayne County, commercial lawn care Goldsboro, residential landscaping Goldsboro, Wright Brothers Lawn Care" 
        />
        <link rel="canonical" href="https://wrightbrotherslawncare.com/goldsboro" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wrightbrotherslawncare.com/goldsboro" />
        <meta property="og:title" content="Professional Lawn Care & Landscaping Services in Goldsboro, NC | Wright Brothers Lawn Care" />
        <meta property="og:description" content="Expert lawn care and landscaping in Goldsboro, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." />
        <meta property="og:image" content="https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg" />
        <meta property="og:site_name" content="Wright Brothers Lawn Care" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Lawn Care & Landscaping Services in Goldsboro, NC | Wright Brothers Lawn Care" />
        <meta name="twitter:description" content="Expert lawn care and landscaping in Goldsboro, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation." />
        <meta name="twitter:image" content="https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content="Goldsboro" />
        <meta name="geo.position" content="35.3846;-77.9927" />
        <meta name="ICBM" content="35.3846, -77.9927" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Wright Brothers Lawn Care - Goldsboro",
            "image": "https://wrightbrotherslawncare.com/images/goldsboro-landscaping.jpg",
            "description": "Professional lawn care and landscaping services in Goldsboro, NC. We offer lawn maintenance, landscape design, turfcare, sod installation, and commercial landscaping services.",
            "priceRange": "$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2562 US-70",
              "addressLocality": "Goldsboro",
              "addressRegion": "NC",
              "postalCode": "27530",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "35.3846",
              "longitude": "-77.9927"
            },
            "url": "https://wrightbrotherslawncare.com/goldsboro",
            "telephone": "+1-919-252-9130",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "areaServed": [
              {
                "@type": "City",
                "name": "Goldsboro",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Pikeville",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Mount Olive",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "La Grange",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              }
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Lawn Care Services in Goldsboro",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Lawn Maintenance in Goldsboro",
                    "description": "Regular mowing, edging, and lawn care to keep your Goldsboro property looking its best."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Landscape Design in Goldsboro",
                    "description": "Custom landscape designs that enhance your Goldsboro property's beauty and value."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sod Installation in Goldsboro",
                    "description": "Expert sod installation for a lush, green lawn in Goldsboro that lasts for years."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Commercial Landscaping in Goldsboro",
                    "description": "Professional commercial landscaping services for businesses in Goldsboro."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Turfcare in Goldsboro",
                    "description": "Specialized turfcare services including fertilization and weed control for Goldsboro lawns."
                  }
                }
              ]
            },
            "sameAs": [
              "https://www.facebook.com/wrightbrotherslawncare",
              "https://www.instagram.com/wrightbrotherslawncare",
              "https://twitter.com/wblawncare"
            ],
            "potentialAction": {
              "@type": "ReserveAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://wrightbrotherslawncare.com/contact",
                "inLanguage": "en-US",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.org/AndroidPlatform"
                ]
              },
              "result": {
                "@type": "Reservation",
                "name": "Request a Free Quote"
              }
            }
          })}
        </script>
      </Helmet>

      <Hero location={locationName} />

      <Box sx={{ py: 4, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '2rem', md: '2.5rem' },
              mb: 4
            }}
          >
            Lawn Care & Landscaping in Goldsboro, NC
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Goldsboro's unique climate, characterized by hot summers and mild winters, creates specific challenges and opportunities for lawn care. Our warm-season grasses like Bermuda and Zoysia thrive in this environment, while the clay-heavy soil typical of Wayne County requires special attention to drainage and soil amendment.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            As a historic military community home to Seymour Johnson Air Force Base, Goldsboro properties often feature established landscapes that require expert maintenance and renovation. Our services are tailored to handle both the traditional Southern gardens common in downtown Goldsboro and the modern landscaping needs of newer developments.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            The area's susceptibility to both drought conditions and occasional heavy rains means we emphasize sustainable landscaping practices and proper irrigation management. We work with native plants that are well-adapted to local conditions and implement smart watering solutions to maintain beautiful, resilient landscapes year-round.
          </Typography>
        </Container>
      </Box>

      <Services location={locationName} />
      <Box bgcolor={theme.palette.primary.main}>
        <CommunityHighlight location={locationName} />
      </Box>
      <WhyChooseUs location={locationName} />
      
      {/* Add ServiceLinks component for improved internal linking structure
      <ServiceLinks location={locationName} /> */}
      
      <RelatedLocations location={locationName} />
      
      <Box sx={{ py: 6, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h2" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              mb: 5
            }}
          >
            Contact Our Goldsboro Team
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', borderRadius: 2 }}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103221.97318567073!2d-77.99389744179688!3d35.384598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ae9ac9924b5da7%3A0xc359daaad94b06c!2sGoldsboro%2C%20NC!5e0!3m2!1sen!2sus!4v1649126611650!5m2!1sen!2sus" 
                  width="100%" 
                  height="350" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  title="Goldsboro, NC Map"
                ></iframe>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 2, background: theme.palette.background.paper }}>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: theme.palette.primary.main
                  }}
                >
                  Contact Us for Goldsboro Lawn Care
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    2562 US-70, Goldsboro, NC 27530
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    <a href="tel:+19192529130" style={{ color: 'inherit', textDecoration: 'none' }}>
                      (919) 252-9130
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AccessTimeIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    Mon-Fri: 8AM-6PM
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  href="/contact"
                  size="large"
                  sx={{ 
                    py: 1.5, 
                    fontWeight: 600,
                    fontSize: '1.05rem',
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  Request a Free Quote in Goldsboro
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default GoldsboroLocation; 