// LawnMaintenanceExamplesOverview.js
import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * Showcases various lawn maintenance service examples
 * in a card layout.
 */
const Examples = () => {
  const maintenanceServices = [
    {
      title: 'Weekly Maintenance',
      description: 'Our standard lawn maintenance service includes mowing, edging, weedeating, blowing of hard surfaces, and maintenance of landscape beds. Recommended during the growing season for optimal grass health, as research shows one-third of grass blades should be cut at a time.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/LawnMowing%3AMaintenance.jpeg',
    },
    {
      title: 'Biweekly Service',
      description: 'Alternative maintenance schedule with comprehensive care every other week. Includes the same services as weekly maintenance but on a less frequent schedule for lawns with slower growth patterns.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/maintainence1.jpeg',
    },
    {
      title: 'À La Carte Services',
      description: 'Pay-per-service option where we charge per service for flexible lawn maintenance needs. Perfect for homeowners who need occasional maintenance or have variable seasonal requirements.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/maintenance.jpeg',
    },
    {
      title: 'Contract Services',
      description: 'Monthly flat rate contracts with consistent pricing throughout the season. Upon request, contracts can also include hedge trimming and spraying lawn for weeds! Our number one priority is finding a program that fits your specific needs.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/weed_control.jpeg',
    },
  ];

  return (
    <Box
      component="section"
      aria-labelledby="lawn-maintenance-services"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[100], 0.5),
      }}
    >
      <Container>
        <Typography
          id="lawn-maintenance-services"
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Service Options
        </Typography>
        <Typography 
          variant="h6" 
          component="p" 
          sx={{ 
            mb: 4, 
            textAlign: 'center', 
            color: '#424242'
          }}
        >
          Flexible Maintenance Programs Tailored to Your Needs
        </Typography>
        <Grid container spacing={4}>
          {maintenanceServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={service.image}
                  alt={service.title}
                  sx={{ 
                    objectPosition: service.title === 'À La Carte Services' ? 'center bottom' : 'top center',
                    objectFit: 'cover'
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#000000' }}>
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples;