import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha } from '@mui/material/styles';

/**
 * Highlights the reasons to choose this landscaping service.
 */
const WhyChooseUs = ({ location }) => {
  let title = 'Why Choose Our Landscaping Services?';
  let metaDescription =
    'Reasons to choose our faith-driven, high-quality landscaping services in Wayne & Johnston County.';

  let reasonsList = [
    'Faith-Driven, Community-Focused',
    'Expert and Certified Team',
    'Customized Lawn Care Plans',
    'Transparent Pricing',
    'Outstanding Customer Service',
  ];

  let imageUrl =
    'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/personalPic.jpeg'; // Default/fallback image

  if (location === 'goldsboro') {
    title = 'Why Choose Our Landscaping Services in Goldsboro, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in Goldsboro, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in Goldsboro Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
    // imageUrl =
    //   'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/lawn_maintenance.jpeg';
  } else if (location === 'clayton') {
    title = 'Why Choose Our Landscaping Services in Clayton, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in Clayton, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in Clayton Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
    // imageUrl =
    //   'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/lawn_maintenance.jpeg';
  } else if (location === 'smithfield') {
    title = 'Why Choose Our Landscaping Services in Smithfield, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in Smithfield, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in Smithfield Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
  } else if (location === 'mount-olive') {
    title = 'Why Choose Our Landscaping Services in Mount Olive, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in Mount Olive, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in Mount Olive Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
  } else if (location === 'pikeville') {
    title = 'Why Choose Our Landscaping Services in Pikeville, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in Pikeville, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in Pikeville Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
  } else if (location === 'la-grange') {
    title = 'Why Choose Our Landscaping Services in La Grange, NC?';
    metaDescription =
      'Discover the top reasons to choose our faith-driven landscaping services in La Grange, NC, from expert care to community involvement.';
    reasonsList = [
      'Local Experts in La Grange Climate & Soil',
      'Community Support & Involvement',
      'Faith-Driven & Values-Based Approach',
      'Advanced Equipment & Techniques',
      'Transparent Pricing & Superior Service',
    ];
  }

  return (
    <>
      {/* Optional local SEO */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="why-choose-us"
        sx={{
          py: { xs: 4, md: 6 },
        }}
      >
        <Container>
          <Grid container spacing={4} alignItems="center">
            {/* Left Column: Image */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={imageUrl}
                alt="Why Choose Us"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  boxShadow: 4,
                }}
              />
            </Grid>

            {/* Right Column: Title + Content Card */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 3,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.common.white, 0.9),
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h2"
                    id="why-choose-us"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {title}
                  </Typography>

                  <Typography variant="body1" paragraph sx={{ color: '#424242' }}>
                    We're dedicated to providing top-quality, faith-driven
                    landscaping services. Below are some of the reasons our
                    clients choose us:
                  </Typography>

                  <List dense>
                    {reasonsList.map((reason, index) => (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                          <CheckCircleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={reason} sx={{ color: '#424242' }} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default WhyChooseUs;
