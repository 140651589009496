// create a page that has the info with image component and the info with image component has an image of a lawn on the left side

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {Examples, CTA } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';
import Approach from './components/Approach/Approach';
const LandscapeDesign = () => {
  const theme = useTheme();
  return (
    // Here is the hero components expected props, make them relevant to the lawn maintenance page
//     heading = 'Industry Specific Solutions',
//   subheading = 'We cater to the unique needs of businesses in Wayne and Johnston Counties. From healthcare campuses to retail centers, our specialized commercial landscaping services make a lasting impression.',
//   metaTitle = 'Industry-Specific Commercial Landscaping Solutions',
//   metaDescription = 'Explore tailored commercial landscaping services for various industries in Wayne and Johnston Counties, including healthcare, retail, and more.',
//   metaKeywords = 'Commercial Landscaping, Industry-Specific Solutions, Wayne County, Johnston County, Healthcare, Retail, Corporate',
    //update the hero component with relevant props for Landscape Design and all relevant words and phrases
    <Main>
        <Hero 
          heading="Landscape Design" 
          subheading="Memories are made in your yard, let us set the scene! We create thoughtfully designed outdoor spaces that enhance your lifestyle and property value." 
          metaTitle="Professional Landscape Design Services | Wayne & Johnston County" 
          metaDescription="Custom landscape design services that create functional, beautiful outdoor spaces for entertaining, relaxation, and family activities." 
          metaKeywords="Landscape Design, Outdoor Living, Garden Design, Property Enhancement, Wayne County, Johnston County" 
        />
        <Approach />
        <Box bgcolor={theme.palette.primary.main}>
          <Examples />
        </Box>
        <CTA />
    </Main>
  );
};

export default LandscapeDesign;