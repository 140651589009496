// LawnMaintenanceApproach.js
import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const LawnMaintenanceApproach = () => {
  return (
    <Box
      component="section"
      aria-labelledby="lawn-care-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Left Column: Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/lawen_maintenance.jpeg"
              alt="Professional lawn maintenance process showing mowing and edging services"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                maxHeight: '400px',
                borderRadius: 2,
                boxShadow: 4,
              }}
            />
          </Grid>

          {/* Right Column: Content */}
          <Grid item xs={12} md={6}>
            <Typography
              id="lawn-care-approach"
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Routine Services
            </Typography>
            <Typography variant="body1" paragraph>
              Our standard lawn maintenance service includes mowing, edging, weedeating, blowing of hard surfaces, and maintenance of landscape beds. We offer both weekly and biweekly maintenance programs.
            </Typography>
            <Typography variant="body1" paragraph>
              We recommend weekly maintenance during the growing season, since research shows that one-third of grass blades should be cut at a time for the healthiest yard. We offer à la carte services, where we charge per service, or contracts, where we will charge a flat rate per month.
            </Typography>
            <Typography variant="body1">
              Upon request, contracts can also include hedge trimming and spraying lawn for weeds! Our number one priority is finding a program that fits your specific needs for your lawn!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LawnMaintenanceApproach;