import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const CTA = () => {
  return (
    <Box
      component="section"
      aria-labelledby="other-services-cta"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography
          id="other-services-cta"
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Solve Your Outdoor Challenges
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
          From drainage solutions to yard grading and seeding, let our experts help you create a functional and beautiful outdoor space.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/contact"
        >
          Get a Free Consultation
        </Button>
      </Container>
    </Box>
  );
};

export default CTA; 