// create a page that has the info with image component and the info with image component has an image of a lawn on the left side

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Examples, CTA } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';
import Approach from './components/Approach/Approach';

const SodInstallation = () => {
  const theme = useTheme();
  return (
    // Here is the hero components expected props, make them relevant to the lawn maintenance page
//     heading = 'Industry Specific Solutions',
//   subheading = 'We cater to the unique needs of businesses in Wayne and Johnston Counties. From healthcare campuses to retail centers, our specialized commercial landscaping services make a lasting impression.',
//   metaTitle = 'Industry-Specific Commercial Landscaping Solutions',
//   metaDescription = 'Explore tailored commercial landscaping services for various industries in Wayne and Johnston Counties, including healthcare, retail, and more.',
//   metaKeywords = 'Commercial Landscaping, Industry-Specific Solutions, Wayne County, Johnston County, Healthcare, Retail, Corporate',
    // Update the hero component with relevant props for Sod Installation and all relevant words and phrases, for SEO
    <Main>
      <Hero 
        heading="Sod Installation" 
        subheading="Get a fully established lawn immediately after installation. Unlike grass seed, sod provides instant curb appeal and functionality for your outdoor space." 
        metaTitle="Professional Sod Installation Services | Wayne & Johnston County" 
        metaDescription="Expert sod installation services providing instant, beautiful lawns. We offer complete solutions from site preparation to aftercare for residential and commercial properties." 
        metaKeywords="Sod Installation, Instant Lawn, Lawn Installation, Site Preparation, Wayne County, Johnston County" 
      />
      <Approach />
      <Box bgcolor={theme.palette.primary.main}>
        <Examples />
      </Box>
      <CTA />
    </Main>
  );
};

export default SodInstallation;