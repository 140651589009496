import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from '../../layouts/Main';
import Hero from '../../components/Hero';

import {
    CTA,
    Segments,
    Stories
} from './components';
import {useTheme} from "@mui/material/styles";

const IndustrySolutions = () => {
    const theme = useTheme();
  return (
    <Main colorInvert={true}>
      <Hero />
        <Segments/>
        <Stories />
        <CTA />



    </Main>
  );
};

export default IndustrySolutions;
