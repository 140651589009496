import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';

import Container from '../../../../components/Container'; // Ensure this path matches your project structure

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '80vh', md: '100vh' },
        overflow: 'hidden',
      }}
    >
      {/* Background video covering the entire Hero section */}
      <Box
        component="video"
        src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Pristine+Suburban+Oasis.mp4" // Replace with your actual video URL
        autoPlay
        muted
        loop
        playsInline
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 0,
          filter: 'brightness(0.7)', // Darkening the video
        }}
      />

      {/* Dark overlay to make text stand out */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0)', // Increased opacity for darker overlay
          zIndex: 1,
        }}
      />

      {/* Gradient overlay - only at the bottom now */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%, 
            rgba(0, 0, 0, 0) 70%, 
            ${alpha(theme.palette.primary.main, 1)} 100% /* Primary light at the bottom */
          )`,
          zIndex: 1,
        }}
      />

      {/* Foreground content */}
      <Container
        paddingX={0}
        paddingY={0}
        maxWidth={{ sm: 1, md: 1236 }}
        sx={{
          position: 'relative',
          zIndex: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start', // Align content to the left
          padding: { xs: 2, md: 4 },
        }}
      >
        <Box
          sx={{
            maxWidth: 900,
            textAlign: 'left',
            borderRadius: 2,
            padding: { xs: 2, md: 4 },
          }}
          data-aos={isMd ? 'fade-right' : 'fade-up'} // Animation direction for left-side placement
        >
          <Box marginBottom={2}>
            <Typography variant="h2" color="#ffffff" sx={{ fontWeight: 700 }}>
              Transforming Landscaping and Lawn Care with a Customer-First Approach
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="#f5f5f5">
              Wright Brothers Lawn Care prioritizes your needs with personalized service and attention to detail. We offer exceptional lawn care and landscaping across Wayne County and Johnston County.
            </Typography>
          </Box>
          <Button
              variant="contained"
              size="large"
              href="/contact"
              sx={{
                backgroundColor: theme.palette.secondary.main, // Button background color
                color: '#ffffff', // Button text color
                padding: '12px 30px', // Increased padding
                fontSize: '1.2rem', // Larger font size
                fontWeight: 'bold', // Bold text
                borderRadius: '8px', // Slightly rounded corners
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // More prominent shadow
                '&:hover': {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8), // Slightly darker on hover
                  transform: 'scale(1.05)', // Slight grow effect on hover
                  transition: 'all 0.3s ease',
                },
              }}
            >
              Get Quote
            </Button>

        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Hero;
