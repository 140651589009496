/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box component="section" aria-label="Who We Are and Our Landscaping Process">
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        {/* Left Column: "Who are we?" */}
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Who are we?
            </Typography>
            <Typography component={'p'}>
              We’re a dedicated <strong>landscaping company</strong> serving
              <strong> Wayne County</strong> and <strong>Johnston County</strong>,
              including the surrounding communities of <strong>Goldsboro</strong>,
              <strong> Mount Olive</strong>, <strong>Fremont</strong>,
              <strong> Smithfield</strong>, <strong>Clayton</strong>, and
              <strong> Selma</strong>. Our passion lies in delivering
              <strong> top-quality lawn care</strong> and <strong>landscape design </strong>
              services that elevate outdoor spaces and foster a healthier environment.
            </Typography>
            <Typography component={'p'}>
              Our team is committed to excellence and customer satisfaction, ensuring
              that every project is completed to the highest standards.
            </Typography>
          </Box>
        </Grid>

        {/* Right Column: "Our process" */}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our process
            </Typography>
            <Typography component={'p'}>
              We begin with a thorough <strong>consultation</strong> to understand your
              vision and goals. Our team then crafts a custom <strong>landscaping plan </strong>
              tailored to your unique property—covering everything from <strong>lawn
              maintenance</strong> and <strong>irrigation</strong> to <strong>hardscaping </strong>
               and <strong>flower bed design</strong>. Throughout each step, we maintain
              transparent communication, so you’ll always know what’s happening and when.
              Our goal is simple: to transform your yard into a vibrant, welcoming space
              you can enjoy year-round.
            </Typography>
            <Typography component={'p'}>
              We pride ourselves on using sustainable practices and high-quality materials
              to ensure the longevity and beauty of your outdoor spaces.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
