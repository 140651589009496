// CallToAction.js
import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * A call-to-action prompting homeowners to get a free estimate or consultation.
 */
const CallToAction = () => {
  return (
    <Box
      component="section"
      aria-labelledby="lawn-maintenance-cta"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography
          id="lawn-maintenance-cta"
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Find the Perfect Maintenance Program
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
          Let us help you determine the best maintenance schedule for your lawn's specific needs.
          Contact us today for a personalized service plan.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/contact"
        >
          Get Your Custom Plan
        </Button>
      </Container>
    </Box>
  );
};

export default CallToAction;
