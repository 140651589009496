import React from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import Main from '../../layouts/Main';
import { useTheme } from '@mui/material/styles';
import Hero from '../../components/Hero';

/**
 * Component with photo on the left and text on the right.
 */
const TeamMemberLeft = ({ photo, name, title, description }) => (
  <Grid container spacing={4} alignItems="center" sx={{ my: 4 }}>
    {/* Photo on the left */}
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          width: '100%',
          height: '400px', // Adjustable height
          position: 'relative',
          borderRadius: 2,
          boxShadow: 3,
          overflow: 'hidden',
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Box
          component="img"
          src={photo}
          alt={`${name}'s photo`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
    </Grid>
    {/* Description on the right */}
    <Grid item xs={12} md={6}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        {name}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: '#424242' }}>
        {description}
      </Typography>
    </Grid>
  </Grid>
);

/**
 * Component with text on the left and photo on the right.
 */
const TeamMemberRight = ({ photo, name, title, description }) => (
  <Grid container spacing={4} alignItems="center" sx={{ my: 4 }}>
    {/* Description on the left */}
    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
        {name}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: '#424242' }}>
        {description}
      </Typography>
    </Grid>
    {/* Photo on the right */}
    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
      <Box
        sx={{
          width: '100%',
          height: '400px', // Adjustable height
          position: 'relative',
          borderRadius: 2,
          boxShadow: 3,
          overflow: 'hidden',
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Box
          component="img"
          src={photo}
          alt={`${name}'s photo`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
    </Grid>
  </Grid>
);

/**
 * Main Meet the Team page that uses the above components.
 */
const MeetTheTeam = () => {
  const theme = useTheme();

  return (
    <Main>
      <Hero
        heading="Meet The Team"
        subheading="Get to know the faces behind Wright Brothers Lawn Care's excellence in Eastern NC"
        metaTitle="Meet Our Landscaping Team in Wayne & Johnston County"
        metaDescription="Meet the Wright Brothers team providing top-quality lawn care services in Wayne County and Johnston County."
        metaKeywords="Landscaping Team, Lawn Care Experts, Wayne County, Johnston County"
        backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
        showForm={false}
      />
      <Box
        component="article"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: 'white',
        }}
      >
        <Container>
          {/* Company Story Section */}
          <Box sx={{ mt: 8, mb: 6, maxWidth: '900px', mx: 'auto' }}>
            <Typography variant="h4" component="h2" gutterBottom 
              sx={{ 
                fontWeight: 700, 
                color: theme.palette.primary.main,
                textAlign: 'center',
                mb: 4
              }}
            >
              Our Story & Mission
            </Typography>
            
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#424242', mb: 3 }}>
              <strong>The Wright Brothers Story:</strong> Originally "Wright Lawn Care", we became "Wright Brothers Lawn Care" through their father's guidance - a nod to their partnership and the historic Wright Brothers. Since 2011, we've operated on biblical principles of loving neighbors as ourselves. We aim to provide exceptional customer care, maintain properties with integrity, and reflect Christ's love in all interactions. We welcome questions about faith or community needs - contact us for more than just lawn care.
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={6}
          >
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/jeremiahandjosiah.jpg"
              alt="Wright Brothers Lawn Care Team"
              sx={{
                width: '100%',
                maxWidth: '800px', // Set a max width to contain the image
                height: '400px', // Set desired height
                objectFit: 'cover', // This maintains aspect ratio
                objectPosition: 'center', // Centers the image
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Box>

          <Typography
            variant="body1"
            component="div"
            sx={{
              mb: 6,
              textAlign: 'center',
              color: '#424242',
              fontSize: '1.1rem',
              maxWidth: '900px',
              mx: 'auto',
              lineHeight: 1.8
            }}
          >
            <Box sx={{ mb: 3 }}>
              <strong>Welcome to Wright Brothers Lawn and Landscaping!</strong> Before showcasing our work, we want to highlight the brothers who brought this company to life - Josiah and Jeremiah Wright.
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
              <Box sx={{ textAlign: 'left', maxWidth: '800px' }}>
                <ul style={{ listStylePosition: 'inside' }}>
                  <li>While Josiah is older, Jeremiah is often confused as the elder due to his stature!</li>
                  <li>Together they combine 12+ years of industry experience</li>
                  <li>Providing expert lawn care and landscaping services throughout Eastern North Carolina</li>
                  <li>Committed to quality service and customer satisfaction</li>
                </ul>
              </Box>
            </Box>
          </Typography>

          {/* Jeremiah Section */}
          <TeamMemberLeft
            photo="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Jeremiah.jpeg"
            name="Jeremiah Wright"
            title="Landscaping Expert & Co-Owner"
            description={
              <>
              Jeremiah just recently married his bride, Rachel, on Valentine’s Day this past year! When he is not at work, he enjoys spending time with his new wife and serving in the local church and community. Some of his favorite pastimes are golfing, fishing, hiking, and exploring new parts of our state. He also enjoys taking day trips to quaint towns or spending the day relaxing on the beach with his wife.
              </>
            }
          />

          <Divider sx={{ my: 4 }} />

          {/* Josiah Section */}
          <TeamMemberRight
            photo="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/JOSIAH.jpeg"
            name="Josiah Wright"
            title="Lawn Care Specialist & Co-Owner"
            description={
              <>
                Josiah is married to Brittany, and together they have a precious 2-year-old son . When Josiah is not at work, he enjoys spending time with family or serving at his local church. Some of his favorite pastimes with family are traveling to new cities, trying new cuisine, attending sporting events, and taking his son to parks and aquariums. And If he is not with his family, you can find him on a golf course or at a local coffee shop!
              </>
            }
          />

          
        </Container>
      </Box>
    </Main>
  );
};

export default MeetTheTeam;
