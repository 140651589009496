// CommercialCallToAction.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * A call-to-action prompting business owners or property managers
 * to get a free commercial landscaping estimate or consultation.
 */
const CallToAction = () => {
  return (
    <>
      {/* Basic SEO metadata */}
      <Helmet>
        <title>Transform Your Commercial Property | Wayne & Johnston County</title>
        <meta
          name="description"
          content="Enhance your business environment with our commercial landscaping services. Contact us for a free site assessment and consultation in Wayne & Johnston Counties."
        />
        <meta
          name="keywords"
          content="Commercial Landscaping, Business Lawn Care, Free Estimate, Wayne County, Johnston County"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="commercial-cta"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          textAlign: 'center',
        }}
      >
        <Container>
          <Typography
            id="commercial-cta"
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            Ready to Elevate Your Commercial Property?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
            Contact us for a free site assessment and let our experts create
            a professional, welcoming environment for your customers and employees.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/contact"
          >
            Get a Free Commercial Estimate
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default CallToAction;
