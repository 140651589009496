// ContactCTA.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const ContactCTA = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Industry-Specific Commercial Landscaping</title>
        <meta
          name="description"
          content="Ready to discuss your business or property's unique landscaping requirements? Contact us to learn more about our industry-specific solutions."
        />
        <meta
          name="keywords"
          content="Commercial Landscaping, Industry-Specific, Contact, Wayne County, Johnston County"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="contact-cta"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.15),
          textAlign: 'center',
        }}
      >
        <Container>
          <Typography
            id="contact-cta"
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            Ready to Get Started?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
            Contact us to learn how our industry-specific solutions can elevate
            your commercial property.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/contact"
          >
            Contact Us
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default ContactCTA;
