import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import { Hero, Services, CommunityHighlight, WhyChooseUs, RelatedLocations } from '../Location/components';
import { useTheme } from "@mui/material/styles";
import { Typography, Container, Grid, Button, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Dedicated page for Mount Olive lawn care and landscaping services
 * with rich content specific to this location for improved SEO.
 */
const MountOliveLocation = () => {
  const theme = useTheme();
  const locationName = "mount-olive";
  
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>Professional Lawn Care & Landscaping Services in Mount Olive, NC | Wright Brothers Lawn Care</title>
        <meta 
          name="description" 
          content="Expert lawn care and landscaping in Mount Olive, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." 
        />
        <meta 
          name="keywords" 
          content="lawn care Mount Olive NC, landscaping Mount Olive NC, sod installation Wayne County, commercial landscaping Mount Olive, turfcare services Mount Olive, lawn maintenance Mount Olive, landscape design Wayne County, lawn fertilization Mount Olive, weed control Mount Olive, professional lawn service Wayne County, commercial lawn care Mount Olive, residential landscaping Mount Olive, Wright Brothers Lawn Care" 
        />
        <link rel="canonical" href="https://wrightbrotherslawncare.com/mount-olive" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wrightbrotherslawncare.com/mount-olive" />
        <meta property="og:title" content="Professional Lawn Care & Landscaping Services in Mount Olive, NC | Wright Brothers Lawn Care" />
        <meta property="og:description" content="Expert lawn care and landscaping in Mount Olive, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." />
        <meta property="og:image" content="https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg" />
        <meta property="og:site_name" content="Wright Brothers Lawn Care" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Lawn Care & Landscaping Services in Mount Olive, NC | Wright Brothers Lawn Care" />
        <meta name="twitter:description" content="Expert lawn care and landscaping in Mount Olive, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation." />
        <meta name="twitter:image" content="https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content="Mount Olive" />
        <meta name="geo.position" content="35.1968;-78.0661" />
        <meta name="ICBM" content="35.1968, -78.0661" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Wright Brothers Lawn Care - Mount Olive",
            "image": "https://wrightbrotherslawncare.com/images/mount-olive-landscaping.jpg",
            "description": "Professional lawn care and landscaping services in Mount Olive, NC. We offer lawn maintenance, landscape design, turfcare, sod installation, and commercial landscaping services.",
            "priceRange": "$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2562 US-70",
              "addressLocality": "Goldsboro",
              "addressRegion": "NC",
              "postalCode": "27530",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "35.1968",
              "longitude": "-78.0661"
            },
            "url": "https://wrightbrotherslawncare.com/mount-olive",
            "telephone": "+1-919-252-9130",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "areaServed": [
              {
                "@type": "City",
                "name": "Mount Olive",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Dudley",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Faison",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              }
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Lawn Care Services in Mount Olive",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Lawn Maintenance in Mount Olive",
                    "description": "Regular mowing, edging, and lawn care to keep your Mount Olive property looking its best."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Landscape Design in Mount Olive",
                    "description": "Custom landscape designs that enhance your Mount Olive property's beauty and value."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sod Installation in Mount Olive",
                    "description": "Expert sod installation for a lush, green lawn in Mount Olive that lasts for years."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Commercial Landscaping in Mount Olive",
                    "description": "Professional commercial landscaping services for businesses in Mount Olive."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Turfcare in Mount Olive",
                    "description": "Specialized turfcare services including fertilization and weed control for Mount Olive lawns."
                  }
                }
              ]
            },
            "sameAs": [
              "https://www.facebook.com/wrightbrotherslawncare",
              "https://www.instagram.com/wrightbrotherslawncare",
              "https://twitter.com/wblawncare"
            ],
            "potentialAction": {
              "@type": "ReserveAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://wrightbrotherslawncare.com/contact",
                "inLanguage": "en-US",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.org/AndroidPlatform"
                ]
              },
              "result": {
                "@type": "Reservation",
                "name": "Request a Free Quote"
              }
            }
          })}
        </script>
      </Helmet>

      <Hero location={locationName} />

      <Box sx={{ py: 4, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '2rem', md: '2.5rem' },
              mb: 4
            }}
          >
            Lawn Care & Landscaping in Mount Olive, NC
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Mount Olive's rich agricultural history and vibrant community create unique landscaping opportunities and challenges. Home to the famous Mt. Olive Pickle Company and the University of Mount Olive, this charming town balances historic character with modern development, requiring versatile landscaping approaches.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            The local climate, with warm, humid summers and mild winters, supports a wide variety of plant life but also presents challenges like increased pest activity and disease pressure. Our landscaping solutions for Mount Olive properties incorporate native plants that thrive in these conditions, requiring less maintenance while providing year-round beauty and environmental benefits.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Mount Olive's soil composition varies across the area, with many properties featuring the sandy loam typical of eastern North Carolina. Our lawn care programs address the specific soil needs of each property, incorporating targeted amendments and irrigation strategies that promote deep root growth and drought resistance. Whether maintaining a historic property near downtown or designing landscapes for newer developments, we create outdoor spaces that enhance Mount Olive's unique blend of small-town charm and forward-thinking community spirit.
          </Typography>
        </Container>
      </Box>

      <Services location={locationName} />
      <Box bgcolor={theme.palette.primary.main}>
        <CommunityHighlight location={locationName} />
      </Box>
      <WhyChooseUs location={locationName} />
      
      {/* Add Related Locations section for internal linking */}
      <RelatedLocations location={locationName} />
      
      <Box sx={{ py: 6, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h2" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              mb: 5
            }}
          >
            Contact Our Mount Olive Team
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', borderRadius: 2 }}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13019.48522541622!2d-78.07882892195423!3d35.19697749769767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89abf642edea3c57%3A0x4f8cb31a7cc2b89!2sMount%20Olive%2C%20NC%2028365!5e0!3m2!1sen!2sus!4v1649289871624!5m2!1sen!2sus" 
                  width="100%" 
                  height="350" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  title="Mount Olive, NC Map"
                ></iframe>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 2, background: theme.palette.background.paper }}>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: theme.palette.primary.main
                  }}
                >
                  Contact Us for Mount Olive Lawn Care
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    2562 US-70, Goldsboro, NC 27530
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    <a href="tel:+19192529130" style={{ color: 'inherit', textDecoration: 'none' }}>
                      (919) 252-9130
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AccessTimeIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    Mon-Fri: 8AM-6PM
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  href="/contact"
                  size="large"
                  sx={{ 
                    py: 1.5, 
                    fontWeight: 600,
                    fontSize: '1.05rem',
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  Request a Free Quote in Mount Olive
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default MountOliveLocation; 