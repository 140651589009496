import React from 'react';
import { Box, Typography, Container, Grid, Link, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

/**
 * RelatedLocations component displays nearby locations to create
 * a network of internal links that improves SEO and user navigation.
 * 
 * @param {Object} props
 * @param {string} props.location - Current location name
 */
const RelatedLocations = ({ location }) => {
  const theme = useTheme();
  
  // Location data with slug, display name, and related locations
  const locationData = {
    'goldsboro': {
      name: 'Goldsboro',
      county: 'Wayne County',
      related: ['mount-olive', 'pikeville', 'la-grange', 'smithfield']
    },
    'mount-olive': {
      name: 'Mount Olive',
      county: 'Wayne County',
      related: ['goldsboro', 'pikeville', 'la-grange']
    },
    'pikeville': {
      name: 'Pikeville',
      county: 'Wayne County',
      related: ['goldsboro', 'mount-olive', 'la-grange']
    },
    'la-grange': {
      name: 'La Grange',
      county: 'Lenoir County',
      related: ['goldsboro', 'mount-olive', 'pikeville']
    },
    'clayton': {
      name: 'Clayton',
      county: 'Johnston County',
      related: ['smithfield', 'selma', 'four-oaks', 'princeton']
    },
    'smithfield': {
      name: 'Smithfield',
      county: 'Johnston County',
      related: ['clayton', 'selma', 'four-oaks', 'princeton']
    },
    'selma': {
      name: 'Selma',
      county: 'Johnston County',
      related: ['clayton', 'smithfield', 'four-oaks', 'princeton']
    },
    'four-oaks': {
      name: 'Four Oaks',
      county: 'Johnston County',
      related: ['clayton', 'smithfield', 'selma', 'princeton']
    },
    'princeton': {
      name: 'Princeton',
      county: 'Johnston County',
      related: ['clayton', 'smithfield', 'selma', 'four-oaks']
    }
  };

  // Helper function to get display name from location slug
  const getLocationName = (slug) => {
    if (slug === 'four-oaks') return 'Four Oaks';
    if (slug === 'la-grange') return 'La Grange';
    if (slug === 'mount-olive') return 'Mount Olive';
    // Capitalize first letter of each word for other locations
    return slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Get the current location data or default
  const currentLocation = locationData[location] || {
    name: getLocationName(location),
    county: 'North Carolina',
    related: ['goldsboro', 'clayton']
  };

  // Get related locations (max 4)
  const relatedLocations = currentLocation.related.slice(0, 4);

  return (
    <Box component="section" py={6} bgcolor={theme.palette.primary.main} color="white">
      <Container>
        <Typography 
          variant="h4" 
          component="h2" 
          align="center" 
          gutterBottom
          sx={{ fontWeight: 700, mb: 4, color: 'white' }}
        >
          We Also Serve These Nearby Areas
        </Typography>
        
        <Grid container spacing={3} justifyContent="center">
          {relatedLocations.map((relatedLoc) => {
            const relatedData = locationData[relatedLoc] || {
              name: getLocationName(relatedLoc),
              county: 'North Carolina'
            };
            
            return (
              <Grid item xs={12} sm={6} md={3} key={relatedLoc}>
                <Paper 
                  elevation={2}
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 6,
                      bgcolor: 'rgba(255, 255, 255, 0.2)'
                    }
                  }}
                >
                  <LocationOnIcon 
                    sx={{ fontSize: 40, mb: 1.5, color: 'white' }}
                  />
                  <Typography 
                    variant="h6" 
                    component="h3" 
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    <Link 
                      component={RouterLink} 
                      to={`/${relatedLoc}`}
                      underline="hover"
                      sx={{ color: 'white', '&:hover': { color: 'rgba(255, 255, 255, 0.8)' } }}
                    >
                      {relatedData.name}
                    </Link>
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="white"
                    align="center"
                  >
                    Lawn Care in {relatedData.county}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        
        {/* <Box mt={4} textAlign="center">
          <Typography variant="body1" color="white">
            Need lawn care or landscaping services in another area? 
            <Link 
              component={RouterLink} 
              to="/contact" 
              sx={{ ml: 1, fontWeight: 600, color: 'white', textDecoration: 'underline' }}
            >
              Contact us
            </Link> to learn about all areas we serve.
          </Typography>
        </Box> */}
      </Container>
    </Box>
  );
};

export default RelatedLocations; 