// src/components/Layout/components/SidebarNav.js

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import NavItem from './components/NavItem'; // Ensure NavItem is correctly implemented and imported
import logo from '../../../../../../image.png'; // Adjust path as needed

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  // Destructure the pages object into the correct sections
  const {
    about,
    serviceAreas,
    services,
  } = pages;

  return (
    <Box>
      {/* Logo Section */}
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display="flex"
          component="a"
          href="/"
          title="Wright Brothers Lawn Care - Premier Lawn Services in North Carolina"
          sx={{
            width: { xs: 100, sm: 120 }, // Responsive logo width
            mb: { xs: 2, sm: 0 }, // Margin bottom on small screens
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Wright Brothers Lawn Care Logo - Premier Lawn and Landscaping Services in North Carolina"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2, // Rounded corners
              objectFit: 'contain',
            }}
          />
        </Box>
      </Box>

      {/* Navigation Sections */}
      <Box paddingX={2} paddingY={2}>
        {/* Landings Section */}
        <Box marginBottom={2}>
          <NavItem title="About" items={about} />
        </Box>

        {/* Secondary Section */}
        <Box marginBottom={2}>
          <NavItem title="Service Areas" items={serviceAreas} />
        </Box>

        {/* Company Section */}
        <Box marginBottom={2}>
          <NavItem title="Services" items={services} />
        </Box>

        <Box marginTop={4}>
          <Button
            size="large"
            variant="text"
            color="secondary"
            fullWidth
            component="a"
            target="_blank"
            href="/pay" // Replace with relevant link or remove if not needed
            sx={{
              textTransform: 'none',
              color: theme.palette.primary.main,
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            Pay Your Bill
          </Button>
        </Box>

        {/* Action Buttons */}
        <Box marginTop={4}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component="a"
            href="/contact"
            sx={{
              textTransform: 'none',
              bgcolor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              '&:hover': {
                bgcolor: theme.palette.secondary.dark,
              },
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.shape({
    landings: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    secondary: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    company: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    account: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    portfolio: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    blog: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default SidebarNav;
