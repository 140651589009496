import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Approach = () => {
  return (
    <Box
      component="section"
      aria-labelledby="design-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/landscape_desing.jpeg"
              alt="Professional landscape design and planning"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 4,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Creating Your Perfect Outdoor Space
            </Typography>
            <Typography variant="body1" paragraph>
              Good landscape design helps you maximize the use of your outdoor space. Whether you want a functional space for entertaining, a quiet retreat for relaxation, or an area for kids and pets to play, thoughtful landscape design helps you create the right atmosphere and make the most of your yard.
            </Typography>
            <Typography variant="body1">
              Whether you are looking to install a new landscape around your property, update an existing, or deciding to go out with the old and in with the new, We are here to help and assist you through the entire process from design, removal, installation, care and maintenance for customized and sustainable landscape that meets your needs.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Approach; 