import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Link } from 'react-router-dom';

const mock = [
  {
    name: 'Jeremiah Wright',
    title: 'Owner',
    avatar: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Jeremiah.jpeg',
  },
  {
    name: 'Josiah Wright',
    title: 'Owner',
    avatar: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/JOSIAH.jpeg',
  },
];

const Team = () => {
  const theme = useTheme();

  return (
    <Box component="section" aria-label="Meet Our Landscaping Team">
      {/* SEO Metadata */}
      <Helmet>
        <title>Meet Our Team - Landscaping & Lawn Care Experts</title>
        <meta
          name="description"
          content="Meet the passionate landscaping professionals serving Wayne and Johnston Counties. Our team specializes in lawn care, garden design, and more."
        />
        <meta
          name="keywords"
          content="landscaping, lawn care, Wayne County, Johnston County, garden design"
        />
      </Helmet>

      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          align={'center'}
          component="h2"
        >
          Meet the Owners
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Experts in Landscaping & Lawn Care
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color="#424242"
          paragraph
        >
          We proudly serve Wayne County and Johnston County, turning ordinary
          yards into extraordinary outdoor living spaces. Our passion for
          creating beautiful landscapes drives everything we do.
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              width={1}
              height={1}
              component={Card}
              boxShadow={0}
              variant={'outlined'}
              bgcolor={'alternate.main'}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
              onClick={() => window.location.href = '/meet-the-team'}
            >
              <CardContent sx={{ padding: 3 }}>
                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  <ListItemAvatar sx={{ marginRight: 3 }}>
                    <Avatar
                      src={item.avatar}
                      variant={'rounded'}
                      sx={{ width: 100, height: 100, borderRadius: 2 }}
                      alt={`${item.name} - ${item.title}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={item.name}
                    secondary={item.title}
                    primaryTypographyProps={{ variant: 'h6', fontWeight: 700 }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: '#424242',
                    }}
                  />
                </ListItem>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
