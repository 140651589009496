import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '../../../components/Container';
import { useTheme } from '@mui/material/styles';

const CallToAction = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        position: 'relative',
        py: { xs: 6, md: 10 },
        backgroundColor: theme.palette.primary.main,
        color: 'common.white',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              textTransform: 'uppercase',
            }}
          >
            Ready to Transform Your Landscape?
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              mb: 4,
              maxWidth: 800,
              mx: 'auto',
            }}
          >
            Join our satisfied customers and experience the Wright Brothers difference. 
            Our team is ready to provide you with exceptional lawn care and landscaping services.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href="/contact"
            sx={{
              py: 1.5,
              px: 4,
              fontSize: '1.1rem',
              fontWeight: 600,
              textTransform: 'uppercase',
              boxShadow: 3,
              '&:hover': {
                boxShadow: 5,
              },
            }}
          >
            Get Your Free Quote
          </Button>
        </Box>
      </Container>
      
      {/* Background pattern/decoration */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '30%',
          opacity: 0.1,
          background: `linear-gradient(180deg, transparent 0%, ${theme.palette.common.white} 100%)`,
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default CallToAction; 