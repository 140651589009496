// src/components/Layout/components/Sidebar.js

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { useTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";

const Sidebar = ({ pages, open, variant, onClose }) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
          backgroundColor: 'white',
          color: theme.palette.text.primary,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 2, // Uniform padding
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Navigation Items */}
        <SidebarNav pages={pages} />

        {/* Optional: Add any additional elements like social media links or contact info here */}

      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.shape({
    services: PropTypes.array,
    about: PropTypes.array,
    gallery: PropTypes.array,
    testimonials: PropTypes.array,
    blog: PropTypes.array,
    contact: PropTypes.array,
  }).isRequired,
};

export default Sidebar;
