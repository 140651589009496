/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from 'react-router-dom';

// Import MUI Icons
import GrassIcon from '@mui/icons-material/Grass';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import YardIcon from '@mui/icons-material/Yard';
import SpaIcon from '@mui/icons-material/Spa';
import ForestIcon from '@mui/icons-material/Forest';

import Container from '../../../../components/Container';

// Services data with links
const services = [
  {
    title: 'Lawn Care',
    subtitle: 'Professional lawn maintenance to keep your yard healthy and beautiful.',
    icon: <GrassIcon fontSize="large" />,
    link: '/services/lawn-maintenance',
  },
  {
    title: 'Landscaping',
    subtitle: 'Complete landscaping services to enhance your outdoor living space.',
    icon: <LandscapeIcon fontSize="large" />,
    link: '/services/landscape-maintenance',
  },
  {
    title: 'Landscape Design',
    subtitle: 'Custom design services to create your dream outdoor environment.',
    icon: <ArchitectureIcon fontSize="large" />,
    link: '/services/landscape-design',
  },
  {
    title: 'Sod Installation',
    subtitle: 'Quality sod installation for an instant, beautiful lawn.',
    icon: <YardIcon fontSize="large" />,
    link: '/services/sod-installation',
  },
  {
    title: 'Turfcare',
    subtitle: 'Specialized care to maintain healthy, vibrant turf year-round.',
    icon: <SpaIcon fontSize="large" />,
    link: '/services/turf-care',
  },
  {
    title: 'Mulch & Pinestraw',
    subtitle: 'Premium mulch and pinestraw installation for beautiful, low-maintenance beds.',
    icon: <ForestIcon fontSize="large" />,
    link: '/services/landscape-maintenance',
  },
];

const ServicesSection = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box>
        {/* Header Section */}
        <Box marginBottom={6} textAlign="center">
          <Typography
            sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
            gutterBottom
            color="text.primary"
            style={{ color: '#2D2D2D' }}
          >
            Our Services
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 700, color: 'text.primary' }}>
            Comprehensive Lawn Care Solutions
          </Typography>
          <Typography variant="h6" style={{ color: '#2D2D2D' }}>
            We offer a wide range of services to keep your outdoor spaces vibrant
            and well-maintained all year long.
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mt: 2, 
              color: '#2D2D2D',
              fontStyle: 'italic',
              maxWidth: '800px',
              mx: 'auto',
              pb: 1
            }}
          >
            Proudly serving communities from Wayne County to Clayton with premium lawn care and landscaping services.
          </Typography>
        </Box>

        {/* Services Grid - 3 columns on desktop, 2 on tablet, 1 on mobile */}
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 100}
              sx={{ mb: 2 }}
            >
              <Card 
                sx={{ 
                  height: '100%',
                  maxWidth: '320px',
                  mx: 'auto',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <CardActionArea 
                  component={Link} 
                  to={service.link}
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <CardContent sx={{ p: 3, width: '100%' }}>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 2
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          width: 60,
                          height: 60,
                          mb: 2,
                        }}
                      >
                        {service.icon}
                      </Avatar>
                      <Typography 
                        variant="h6" 
                        component="h3" 
                        sx={{ 
                          fontWeight: 700,
                          textAlign: 'center',
                          mb: 1,
                          color: '#1A1A1A'
                        }}
                      >
                        {service.title}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        textAlign: 'center',
                        color: '#2D2D2D',
                        lineHeight: 1.5,
                        fontSize: '0.875rem'
                      }}
                    >
                      {service.subtitle}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ServicesSection;
