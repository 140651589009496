import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';

const mock = [
  {
    feedback:
      'They are reliable, do high quality work, for a fair price. I have used them for several years.',
    name: 'Richard Raburn',
    title: 'January 27, 2023',
    avatar: 'https://assets.maccarianagency.com/avatars/img1.jpg',
  },
  {
    feedback:
      'Beautiful work! We’re so pleased with our new fire pit we had installed by Wright Brothers Lawn Care! The process was very simple and hassle free. We also have them do our lawn every 2 weeks and our bushes! Very trustworthy and honest company! Thank you again!',
    name: 'Nicole K',
    title: 'February 27, 2021',
    avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
  },
  {
    feedback:
      'Great service. Quality work. Exactly what I asked for. Great price and fast. Would definitely recommend them to anyone needing a Big Job. Thank you Wright Brothers and Team.',
    name: 'Nicole Cunningham',
    title: 'January 27, 2023',
    avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
  },
];

const Testimonials = () => {
  const theme = useTheme();

  return (
<>
      <Box marginBottom={4} textAlign="center">
        <Typography
          variant="h4"
          color={theme.palette.primary.main}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Rated 4.8 out of 5 stars on Google!
        </Typography>
        <Typography variant="body2" color={'#424242'} sx={{ mb: 2 }}>
          Based on over 100 reviews
        </Typography>
        <Typography variant="h6" color={'#424242'}>
          Families throughout North Carolina have trusted Wright Brother Lawncare for top-notch lawn care, consistently sharing fantastic experiences.
          Here's what they have to say.
        </Typography>
      </Box>


      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Card
              sx={{
                height: '100%', // Ensure all cards have equal height
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.grey[800], // Darker card background for readability
                color: 'white', // White text for contrast
                borderRadius: 2, // Rounded corners
                boxShadow: 3, // Subtle shadow for depth
                transition: 'transform 0.3s, box-shadow 0.3s', // Smooth hover transitions
                '&:hover': {
                  transform: 'translateY(-8px)', // Lift effect on hover
                  boxShadow: 6, // Enhanced shadow on hover
                },
              }}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              {/* Card Content */}
              <CardContent sx={{ flexGrow: 1 }}>
                {/* Rating */}
                <Box display="flex" justifyContent="center" mb={2}>
                  <Rating name="read-only" value={5} readOnly precision={0.5} sx={{ color: 'gold' }} />
                </Box>
                {/* Feedback Text */}
                <Typography variant="body1" sx={{ mb: 2 }}>
                  "{item.feedback}"
                </Typography>
              </CardContent>

              {/* Divider */}
              <Divider sx={{ backgroundColor: theme.palette.grey[700], marginX: 2 }} />

              {/* Card Actions: Avatar and User Info */}
              <Box sx={{ padding: 2 }}>
                <Box display="flex" alignItems="center">
                  {/* <Avatar src={item.avatar} alt={item.name} sx={{ marginRight: 2 }} /> */}
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="grey.500">
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
  </>
  );
};

export default Testimonials;
