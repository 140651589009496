import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const PruningSection = () => {
  return (
    <Box
      component="section"
      aria-labelledby="pruning-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Left Column: Content */}
          <Grid item xs={12} md={6}>
            <Typography
              id="pruning-approach"
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Bush & Tree Pruning
            </Typography>
            <Typography variant="body1" paragraph>
              Pruning bushes and trees provides numerous benefits, including enhancing their health, appearance, and overall longevity. Regular pruning removes dead, diseased, or damaged branches, preventing the spread of pests and infections while promoting strong, healthy growth. It also improves air circulation and sunlight penetration, allowing the plant to thrive and encouraging better flowering and fruit production.
            </Typography>
            <Typography variant="body1">
              Additionally, pruning helps maintain an attractive shape, prevents overgrowth, and reduces safety hazards by keeping branches clear of walkways, driveways, and your home. Well-maintained trees and shrubs are more resilient to harsh weather conditions, ensuring long-term stability in the landscape.
            </Typography>
          </Grid>

          {/* Right Column: Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/bush_and_tree.jpeg"
              alt="Professional tree and bush pruning services"
              sx={{
                width: '100%',
                height: '350px',
                borderRadius: 2,
                boxShadow: 4,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PruningSection; 