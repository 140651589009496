import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from '../../layouts/Main';
import Container from '../../components/Container';

// Importing the new/refactored components
import Hero from './components/Hero';
import About from './components/About';
import ServicesSection from './components/ServicesSection';
import OurMissionAndValues from './components/WhyChooseUs';
import Testimonials from './components/Testimonials';
import CallToActionBanner from './components/CallToActionBanner';
import splitter from '../../Horizontal Lawn Clipart copy.png'
import Team from './components/Team'; // If you still want to highlight your team

const Service = () => {
  const theme = useTheme();
  return (
    <Main>
      {/* Hero Section with Background Pattern */}
      <Box
  sx={{
    position: 'relative',
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    '&::after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), ${theme.palette.primary.light})`,
      zIndex: 2, // Ensure overlay is above background but below content
    },
  }}
>
  <Box position={'relative'} zIndex={3}>
    <Hero />
  </Box>
</Box>




      {/* About Section with Gradient */}

      <Box bgcolor={theme.palette.primary.main}>
        <Container>
          <OurMissionAndValues />
        </Container>
      </Box>

      <Divider />

    <Box
      sx={{
        background: '#fff'
      }}
    >
      <Container>
        <ServicesSection />
      </Container>
    </Box>

    <Box
      sx={{
        background: `linear-gradient(
          to bottom,
          ${theme.palette.primary.main} 0%,
          ${theme.palette.primary.main} 100%
        )`,
      }}
    >
      <Container>
        <About />
      </Container>
    </Box>

      {/*/!* Services Section *!/*/}
      {/*<Box bgcolor={theme.palette.primary?.main || '#f7faff'}>*/}
      {/*  <Container>*/}
      {/*    */}
      {/*  </Container>*/}
      {/*</Box>*/}

      {/* Why Choose Us Section */}
      

      {/* Testimonials Section */}
      <Box bgcolor={'#fff'}>
        <Container>
          <Testimonials />
        </Container>
      </Box>

      {/* Call to Action Banner */}
      <Box
      sx={{
        position: 'relative',
        py: { xs: 6, sm: 8, md: 10 }, // Responsive vertical padding
        textAlign: 'center',
        overflow: 'hidden', // To contain any absolutely positioned elements
        boxShadow: 3, // Adds a subtle shadow for depth
        bgcolor: theme.palette.primary.main,
      }}
    >
        <Container>
        <CallToActionBanner />
      </Container>
      </Box>


      {/* Team Section (Optional) */}
      <Divider />
      {/*<Box bgcolor={theme.palette.primary?.main || '#f7faff'}>*/}
      {/*  <Container>*/}
      {/*    <Team />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
    </Main>
  );
};

export default Service;
