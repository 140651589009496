// src/components/Layout/components/NavItem.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom'; // Ensure react-router-dom is installed

const NavItem = ({ title, items }) => {
  const theme = useTheme();
  const location = useLocation(); // Get current path from react-router
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // Safeguard: Ensure items is an array
  if (!items || !Array.isArray(items)) {
    console.warn(`NavItem: 'items' prop for "${title}" is undefined or not an array.`);
    return null;
  }

  const hasActiveLink = () => items.find((i) => i.href === activeLink);

  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
          sx={{ padding: 0 }}
        >
          <Typography
            fontWeight={hasActiveLink() ? 700 : 400}
            color={theme.palette.primary.main}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={1}>
            {items.map((p, i) => (
              <Grid item key={i} xs={12}>
                <Button
                  size="large"
                  component="a"
                  href={p.href}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    fontWeight: activeLink === p.href ? 700 : 400,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    },
                  }}
                >
                  {p.title}
                  {p.isNew && (
                    <Box
                      padding={0.5}
                      display="inline-flex"
                      borderRadius={1}
                      bgcolor="primary.main"
                      marginLeft={2}
                    >
                      <Typography
                        variant="caption"
                        sx={{ color: 'common.white', lineHeight: 1 }}
                      >
                        new
                      </Typography>
                    </Box>
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      isNew: PropTypes.bool,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default NavItem;
