import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

const HelpfulLinks = () => {
  // Expanded links for homeowner references
  const links = [
    {
      name: 'Wayne County Cooperative Extension',
      url: 'https://wayne.ces.ncsu.edu/',
      description:
        'Local agricultural and horticultural resources for Wayne County residents.',
    },
    {
      name: 'Johnston County Cooperative Extension',
      url: 'https://johnston.ces.ncsu.edu/',
      description:
        'Expert advice and community programs for Johnston County homeowners.',
    },
    {
      name: 'NC Department of Environmental Quality',
      url: 'https://deq.nc.gov/',
      description:
        'Regulations, water restrictions, and environmental guidelines in North Carolina.',
    },
    {
      name: 'NC State Master Gardener Program',
      url: 'https://ncstategardening.org/',
      description:
        'Educational opportunities, events, and resources for gardening enthusiasts statewide.',
    },
    {
      name: 'NC Green Industry Council',
      url: 'https://www.ncgreenindustrycouncil.com/',
      description:
        'Information on best practices and sustainable solutions for the green industry in North Carolina.',
    },
    {
      name: 'City Watering Restrictions',
      url: 'https://www.nc.gov/municipal-water-restrictions',
      description:
        'Check local regulations and watering restrictions for your area in Wayne & Johnston Counties.',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Helpful Links for Homeowners | Landscaping in Wayne & Johnston County</title>
        <meta
          name="description"
          content="Browse useful external links and local references for homeowners seeking landscaping, agricultural, or environmental resources in Wayne and Johnston Counties."
        />
        <meta
          name="keywords"
          content="Helpful Links, Homeowner Resources, Wayne County, Johnston County, Landscaping"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="helpful-links"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
        }}
      >
        <Container>
          <Typography
            id="helpful-links"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            Helpful Links & Resources
          </Typography>

          <Grid container spacing={4} alignItems="flex-start">
            {/* Left Column: List of links */}
            <Grid item xs={12} md={7}>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Check out these trusted resources for local regulations, expert advice,
                and more:
              </Typography>
              <List>
                {links.map((linkItem, idx) => (
                  <ListItem
                    key={idx}
                    sx={{ display: 'block', mb: 2, pl: 0 }}
                  >
                    <ListItemText
                      primary={
                        <a
                          href={linkItem.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#1976d2', textDecoration: 'none' }}
                        >
                          {linkItem.name}
                        </a>
                      }
                      // Override the default secondary text color
                      secondaryTypographyProps={{
                        sx: { color: 'text.primary' },
                      }}
                      secondary={linkItem.description}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>

            {/* Right Column: Image */}
            <Grid item xs={12} md={5}>
              <Box
                component="img"
                src="https://via.placeholder.com/800x600?text=Helpful+Links"
                alt="Helpful Links"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HelpfulLinks;
