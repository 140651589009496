// IndustrySegments.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

const IndustrySegments = () => {
  // Sample data array for industry segments
  const industries = [
    {
      title: 'Healthcare Facilities',
      description:
        'Maintaining a clean, welcoming landscape for hospitals, clinics, and long-term care centers.',
      image: 'https://via.placeholder.com/600x400?text=Healthcare+Landscape',
    },
    {
      title: 'Corporate Campuses',
      description:
        'Enhancing corporate offices and business parks with professional lawn care and design.',
      image: 'https://via.placeholder.com/600x400?text=Corporate+Landscape',
    },
    {
      title: 'Retail & Shopping Centers',
      description:
        'Creating inviting entryways and well-maintained exteriors for busy retail environments.',
      image: 'https://via.placeholder.com/600x400?text=Retail+Landscape',
    },
    {
      title: 'Hospitality & Hotels',
      description:
        'Designing relaxing, aesthetically pleasing landscapes for resorts, hotels, and conference centers.',
      image: 'https://via.placeholder.com/600x400?text=Hospitality+Landscape',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Industry Segments | Commercial Landscaping Solutions</title>
        <meta
          name="description"
          content="Discover our specialized commercial landscaping services across diverse industries, including healthcare, corporate campuses, retail, and hospitality."
        />
        <meta
          name="keywords"
          content="Commercial Landscaping, Industry Segments, Healthcare, Retail, Corporate, Hospitality"
        />
      </Helmet>

      <Box
        component="section"
        aria-labelledby="industry-segments"
        sx={{
          py: { xs: 4, md: 6 },
          backgroundColor: (theme) => alpha(theme.palette.grey[100], 0.5),
        }}
      >
        <Container>
          <Typography
            id="industry-segments"
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 700, mb: 3 }}
          >
            Industries We Serve
          </Typography>
          <Grid container spacing={4}>
            {industries.map((industry, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="180"
                    image={industry.image}
                    alt={industry.title}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {industry.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {industry.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default IndustrySegments;
