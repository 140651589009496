import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GradientOverlay from './GradientOverlay'; // Optional: For advanced gradient effects

const CallToActionBanner = () => {
  const theme = useTheme();

  return (
    <>
      {/* Optional: Gradient Overlay for Enhanced Visuals */}
    {/*<GradientOverlay />*/}

      <Container maxWidth="md">
        {/* Main Heading */}
        <Typography
          variant="h3"
          component="h2"
          sx={{
            color: '#ffffff',
            fontWeight: 700,
            mb: { xs: 2, sm: 3 },
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, // Responsive font sizes
          }}
        >
          Ready to Transform Your Lawn?
        </Typography>

        {/* Subheading */}
        <Typography
          variant="h6"
          sx={{
            mb: { xs: 4, sm: 6 },
            fontWeight: 400,
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, // Responsive font sizes
          }}
        >
          Contact us today for a free quote and let's get started on making your lawn the envy of the neighborhood.
        </Typography>

        {/* Call to Action Button */}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          endIcon={<ArrowForwardIcon />}
          href="/contact"
          sx={{
            px: 4, // Horizontal padding
            py: 1.5, // Vertical padding
            fontSize: { xs: '1rem', sm: '1.125rem' }, // Responsive font sizes
            borderRadius: 50, // Pill-shaped button
            textTransform: 'none', // Keeps the text casing as is
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Adds a more pronounced shadow
            transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition effects
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          Get a Free Estimate
        </Button>
      </Container>
    </>
  );
};

export default CallToActionBanner;
