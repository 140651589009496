import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
  Contact,
  Gallery,
  Partners,
  Story,
  Team,
  WhoWeAre,
} from './components';
import {useTheme} from "@mui/material/styles";
import Hero from "../../components/Hero";

const OurStory = () => {
    const theme = useTheme();
  return (
    <Main colorInvert={true}>
      <Hero
  heading="About Us"
  subheading="We're a faith-driven landscaping company serving Wayne and Johnston Counties with top-quality lawn care and genuine community focus."
  metaTitle="About Our Landscaping Company in Wayne & Johnston County"
  metaDescription="We provide top-quality lawn care services in Wayne County and Johnston County."
  metaKeywords="Landscaping, Lawn Care, Wayne County, Johnston County"
  backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
  showForm={false}
/>

      <Container>
        <Story />
      </Container>
      <Container paddingTop={'0 !important'}>
        <WhoWeAre />
      </Container>
      <Container maxWidth={800} paddingY={'0 !important'}>
        <Divider />
      </Container>
      <Box bgcolor={theme.palette.primary.main}>
      <Container>
        <Gallery />
      </Container>
      </Box>
      <Container>
        <Team />
      </Container>

        <Box bgcolor={theme.palette.primary.main}>
            
        <Contact />
        </Box>

    </Main>
  );
};

export default OurStory;
