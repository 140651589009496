import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';
import Approach from './components/Approach/Approach';
import Examples from './components/Examples/Examples';
import CTA from './components/CTA/CTA';

const OtherServices = () => {
  const theme = useTheme();
  return (
    <Main>
      <Hero 
        heading="Additional Landscaping Services" 
        subheading="From drainage solutions and yard grading to seeding services, we offer comprehensive landscaping solutions to address all your outdoor needs." 
        metaTitle="Professional Drainage, Grading & Seeding Services | Wayne & Johnston County" 
        metaDescription="Expert drainage solutions, yard grading, and seeding services. We provide comprehensive landscaping solutions for residential and commercial properties." 
        metaKeywords="French Drains, Yard Grading, Seeding Services, Drainage Solutions, Gutter Downspout, Wayne County, Johnston County" 
      />
      <Approach />
      <Box bgcolor={theme.palette.primary.main}>
        <Examples />
      </Box>
      <CTA />
    </Main>
  );
};

export default OtherServices; 