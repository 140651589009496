// create a page that has the info with image component and the info with image component has an image of a lawn on the left side

import React from 'react';
import { Approach, Examples, CTA } from './components';
import { Main } from '../../layouts';
import Hero from '../../components/Hero';

const LawnMaintenance = () => {
  return (
    <Main>
      <Hero 
        heading="Residential Lawn Maintenance"
        subheading="Professional lawn care services tailored to your needs, from weekly maintenance to specialized treatments. We take pride in keeping your lawn healthy and beautiful year-round."
        metaTitle="Residential Lawn Maintenance Services | Wayne & Johnston County"
        metaDescription="Professional lawn maintenance including mowing, edging, weedeating, and blowing. Weekly and biweekly service plans available in Wayne & Johnston County."
        metaKeywords="Lawn Maintenance, Residential Lawn Care, Mowing Services, Lawn Edging, Weed Control, Wayne County, Johnston County"
      />
      <Approach />
      <Examples />
      <CTA />
    </Main>
  );
};

export default LawnMaintenance;