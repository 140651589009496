import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const CareerInfo = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 6, md: 8 },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
              mb: 2,
            }}
          >
            Why Work With Us?
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              maxWidth: '800px', 
              mx: 'auto',
              color: '#666666'
            }}
          >
            Join a team that values growth, integrity, and a family-first approach
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="body1" paragraph sx={{ 
                fontSize: '1.1rem',
                lineHeight: 1.8 
              }}>
                Wright Brothers Lawn and Landscaping is a family-based company that believes their number one asset is their employees. We follow the famous quote by Richard Branson: "If you take care of your employees, they will take care of the clients."
              </Typography>
              <Typography variant="body1" sx={{ 
                fontSize: '1.1rem',
                lineHeight: 1.8 
              }}>
                Our number one priority is the well-being and care of the person along with the opportunity for growth and development. We are a growing organization that encourages growth and reward based off integrity, merit, effort, leadership and team first approach.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{
              backgroundColor: theme.palette.background.default,
              p: 4,
              borderRadius: 2,
              height: '100%'
            }}>
              <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  mb: 3
                }}
              >
                What We Offer:
              </Typography>
              <Grid container spacing={2}>
                {[
                  'Competitive compensation',
                  'Paid time off for tenured employees',
                  'Annual bonuses',
                  'Comprehensive training',
                  'Career growth opportunities',
                  'Team-focused work environment'
                ].map((benefit, i) => (
                  <Grid item xs={12} sm={6} key={i}>
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '1.1rem',
                      mb: 1
                    }}>
                      <Box
                        component="span"
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.primary.main,
                          mr: 2
                        }}
                      />
                      {benefit}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CareerInfo;