import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const MulchSection = () => {
  return (
    <Box
      component="section"
      aria-labelledby="mulch-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Left Column: Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/pinestraw.jpeg"
              alt="Professional mulch and pine straw installation"
              sx={{
                width: '100%',
                height: '350px',
                borderRadius: 2,
                boxShadow: 4,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>

          {/* Right Column: Content */}
          <Grid item xs={12} md={6}>
            <Typography
              id="mulch-approach"
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Mulch & Pine Straw
            </Typography>
            <Typography variant="body1" paragraph>
              Mulch and pine straw offer numerous benefits for gardens and landscapes beyond the simple fact of a fresh look! But it also helps by improving soil health, conserving moisture, and enhancing plant growth. They help retain soil moisture to combat evaporation, ensuring plants stay hydrated for longer periods. Mulch and pine straw also suppress weeds by blocking sunlight, helping to prevent them from sprouting and competing with plants for nutrients.
            </Typography>
            <Typography variant="body1">
              As they decompose, adding essential nutrients and improving soil structure, mulch also helps regulate soil temperature, keeping roots warm in winter and cool in summer. Beyond their functional benefits, mulch and pine straw enhance the aesthetic appeal of garden beds, creating a neat and well-maintained appearance.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MulchSection; 