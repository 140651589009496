import React from 'react';
import { Box, Typography, Container, Grid, Link, Paper } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LawnIcon from '@mui/icons-material/Grass';
import DesignIcon from '@mui/icons-material/Landscape';
import SodIcon from '@mui/icons-material/Layers';
import CommercialIcon from '@mui/icons-material/Business';
import TurfcareIcon from '@mui/icons-material/Spa';

/**
 * ServiceLinks component provides links to service-specific pages 
 * to improve internal linking structure for SEO.
 * 
 * @param {Object} props
 * @param {string} props.location - Current location name
 */
const ServiceLinks = ({ location }) => {
  // Services data
  const services = [
    {
      id: 'lawn-maintenance',
      name: 'Lawn Maintenance',
      description: 'Regular mowing, edging, and pruning services',
      icon: <LawnIcon sx={{ fontSize: 40 }} />,
      url: '/services/lawn-maintenance'
    },
    {
      id: 'landscape-design',
      name: 'Landscape Design',
      description: 'Custom designs to enhance your property',
      icon: <DesignIcon sx={{ fontSize: 40 }} />,
      url: '/services/landscape-design'
    },
    {
      id: 'sod-installation',
      name: 'Sod Installation',
      description: 'Transform your lawn with fresh sod',
      icon: <SodIcon sx={{ fontSize: 40 }} />,
      url: '/services/sod-installation'
    },
    {
      id: 'turf-care',
      name: 'Turf Care',
      description: 'Fertilization and weed control programs',
      icon: <TurfcareIcon sx={{ fontSize: 40 }} />,
      url: '/services/turf-care'
    },
    {
      id: 'commercial-services',
      name: 'Commercial Services',
      description: 'Specialized maintenance for businesses',
      icon: <CommercialIcon sx={{ fontSize: 40 }} />,
      url: '/services/commercial-services'
    }
  ];

  // Get location display name
  const getLocationDisplay = () => {
    if (location === 'four-oaks') return 'Four Oaks';
    if (location === 'la-grange') return 'La Grange';
    if (location === 'mount-olive') return 'Mount Olive';
    return location.charAt(0).toUpperCase() + location.slice(1);
  };

  return (
    <Box component="section" py={6} bgcolor="background.default">
      <Container>
        <Typography 
          variant="h4" 
          component="h2" 
          align="center" 
          gutterBottom
          sx={{ fontWeight: 700, mb: 3 }}
        >
          Our {getLocationDisplay()} Services
        </Typography>
        
        <Typography 
          variant="body1" 
          align="center"
          sx={{ mb: 5, maxWidth: 800, mx: 'auto' }}
        >
          We offer a comprehensive range of professional lawn care and landscaping 
          services to meet all your outdoor needs in {getLocationDisplay()}.
        </Typography>
        
        <Grid container spacing={3}>
          {services.map((service) => (
            <Grid item xs={12} sm={6} md={4} key={service.id}>
              <Paper 
                elevation={2}
                sx={{
                  p: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ color: 'primary.main', mr: 2 }}>
                    {service.icon}
                  </Box>
                  <Typography 
                    variant="h6" 
                    component="h3"
                    sx={{ fontWeight: 600 }}
                  >
                    <Link 
                      component={RouterLink} 
                      to={service.url}
                      underline="hover"
                      color="inherit"
                      sx={{ '&:hover': { color: 'primary.main' } }}
                    >
                      {service.name}
                    </Link>
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                  {service.description} tailored for {getLocationDisplay()} properties.
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Link 
                  component={RouterLink} 
                  to={service.url}
                  color="primary"
                  sx={{ fontWeight: 600, mt: 2 }}
                >
                  Learn More
                </Link>
              </Paper>
            </Grid>
          ))}
        </Grid>
        
        <Box mt={5} textAlign="center">
          <Typography variant="body1">
            Not sure which service you need? 
            <Link 
              component={RouterLink} 
              to="/contact" 
              color="primary"
              sx={{ ml: 1, fontWeight: 600 }}
            >
              Contact us
            </Link> for a free consultation.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default ServiceLinks; 