import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Approach = () => {
  return (
    <Box
      component="section"
      aria-labelledby="commercial-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/flowers_commercial.jpeg"
              alt="Professional commercial lawn maintenance"
              sx={{
                width: '100%',
                height: '400px',
                borderRadius: 2,
                boxShadow: 4,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Services Offered
            </Typography>
            <Typography variant="body1" paragraph>
            Our standard Commercial Lawn Maintenance contract includes mowing, edging, weedeating, blowing of all hard surfaces, cleanup of all trash, and leaf cleanup in the fall and winter. Depending on your needs, we can also include weed treatment, bush/tree trimming, and mulch/pine straw installation. We offer flexible frequency options, including weekly, biweekly, and even monthly services!
            
              </Typography>
            <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
              Why Choose Commercial Lawn Maintenance
            </Typography>
            <Typography variant="body1">
            A lawn maintenance contract with us provides a reliable, scheduled service, so you don't have to worry about managing the upkeep yourself or dealing with unpredictable service providers. We will ensure your property consistently looks well-kept and inviting, which can enhance curb appeal and create a positive first impression for clients, employees, and visitors.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Approach; 