import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import { Hero, Services, CommunityHighlight, WhyChooseUs, RelatedLocations } from '../Location/components';
import { useTheme } from "@mui/material/styles";
import { Typography, Container, Grid, Button, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Dedicated page for Princeton lawn care and landscaping services
 * with rich content specific to this location for improved SEO.
 */
const PrincetonLocation = () => {
  const theme = useTheme();
  const locationName = "princeton";
  
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>Professional Lawn Care & Landscaping Services in Princeton, NC | Wright Brothers Lawn Care</title>
        <meta 
          name="description" 
          content="Expert lawn care and landscaping in Princeton, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." 
        />
        <meta 
          name="keywords" 
          content="lawn care Princeton NC, landscaping Princeton NC, sod installation Johnston County, commercial landscaping Princeton, turfcare services Princeton, lawn maintenance Princeton, landscape design Johnston County, lawn fertilization Princeton, weed control Princeton, professional lawn service Johnston County, commercial lawn care Princeton, residential landscaping Princeton, Wright Brothers Lawn Care" 
        />
        <link rel="canonical" href="https://wrightbrotherslawncare.com/princeton" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wrightbrotherslawncare.com/princeton" />
        <meta property="og:title" content="Professional Lawn Care & Landscaping Services in Princeton, NC | Wright Brothers Lawn Care" />
        <meta property="og:description" content="Expert lawn care and landscaping in Princeton, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation with free quotes and 100% satisfaction guarantee." />
        <meta property="og:image" content="https://wrightbrotherslawncare.com/images/princeton-landscaping.jpg" />
        <meta property="og:site_name" content="Wright Brothers Lawn Care" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Lawn Care & Landscaping Services in Princeton, NC | Wright Brothers Lawn Care" />
        <meta name="twitter:description" content="Expert lawn care and landscaping in Princeton, NC. Family-owned company providing lawn maintenance, landscape design, turf care, and sod installation." />
        <meta name="twitter:image" content="https://wrightbrotherslawncare.com/images/princeton-landscaping.jpg" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="geo.region" content="US-NC" />
        <meta name="geo.placename" content="Princeton" />
        <meta name="geo.position" content="35.4673;-78.1606" />
        <meta name="ICBM" content="35.4673, -78.1606" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Wright Brothers Lawn Care - Princeton",
            "image": "https://wrightbrotherslawncare.com/images/princeton-landscaping.jpg",
            "description": "Professional lawn care and landscaping services in Princeton, NC. We offer lawn maintenance, landscape design, turfcare, sod installation, and commercial landscaping services.",
            "priceRange": "$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "2562 US-70",
              "addressLocality": "Goldsboro",
              "addressRegion": "NC",
              "postalCode": "27530",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "35.4673",
              "longitude": "-78.1606"
            },
            "url": "https://wrightbrotherslawncare.com/princeton",
            "telephone": "+1-919-252-9130",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "areaServed": [
              {
                "@type": "City",
                "name": "Princeton",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Pine Level",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              },
              {
                "@type": "City",
                "name": "Kenly",
                "containedInPlace": {
                  "@type": "State",
                  "name": "North Carolina"
                }
              }
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Lawn Care Services in Princeton",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Lawn Maintenance in Princeton",
                    "description": "Regular mowing, edging, and lawn care to keep your Princeton property looking its best."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Landscape Design in Princeton",
                    "description": "Custom landscape designs that enhance your Princeton property's beauty and value."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sod Installation in Princeton",
                    "description": "Expert sod installation for a lush, green lawn in Princeton that lasts for years."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Commercial Landscaping in Princeton",
                    "description": "Professional commercial landscaping services for businesses in Princeton."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Turfcare in Princeton",
                    "description": "Specialized turfcare services including fertilization and weed control for Princeton lawns."
                  }
                }
              ]
            },
            "sameAs": [
              "https://www.facebook.com/wrightbrotherslawncare",
              "https://www.instagram.com/wrightbrotherslawncare",
              "https://twitter.com/wblawncare"
            ],
            "potentialAction": {
              "@type": "ReserveAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://wrightbrotherslawncare.com/contact",
                "inLanguage": "en-US",
                "actionPlatform": [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.org/AndroidPlatform"
                ]
              },
              "result": {
                "@type": "Reservation",
                "name": "Request a Free Quote"
              }
            }
          })}
        </script>
      </Helmet>

      <Hero location={locationName} />

      <Box sx={{ py: 4, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '2rem', md: '2.5rem' },
              mb: 4
            }}
          >
            Lawn Care & Landscaping in Princeton, NC
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            Princeton's small-town charm and rural setting create unique opportunities for distinctive landscaping. The area's traditional agricultural background influences both soil quality and local landscaping preferences, with many properties featuring spacious yards that showcase the best of Southern garden design.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            The town's location in Johnston County's fertile farming region provides excellent growing conditions for a variety of turf grasses and ornamental plants. Our landscaping approach takes advantage of the well-draining sandy loam soil typical of the area, while addressing specific challenges such as seasonal temperature fluctuations and varying rainfall patterns.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              fontSize: '1.1rem', 
              lineHeight: 1.7,
              mb: 2 
            }}
          >
            As Princeton continues to grow while maintaining its close-knit community feel, we specialize in creating landscapes that blend traditional Southern elements with modern, sustainable practices. Our designs incorporate native plants that thrive in Princeton's specific climate, requiring less maintenance while providing year-round beauty. We pay special attention to proper drainage and irrigation solutions, ensuring lawns and gardens remain healthy through both dry spells and periods of heavy rain.
          </Typography>
        </Container>
      </Box>

      <Services location={locationName} />
      <Box bgcolor={theme.palette.primary.main}>
        <CommunityHighlight location={locationName} />
      </Box>
      <WhyChooseUs location={locationName} />
      
      {/* Add Related Locations section for internal linking */}
      <RelatedLocations location={locationName} />
      
      <Box sx={{ py: 6, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h2" 
            gutterBottom 
            align="center"
            sx={{ 
              fontWeight: 700, 
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              mb: 5
            }}
          >
            Contact Our Princeton Team
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', borderRadius: 2 }}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12990.195018889168!2d-78.16814147180928!3d35.466987895542894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ab8f6ebbf3b0d9%3A0x9213d499c2bd4d23!2sPrinceton%2C%20NC!5e0!3m2!1sen!2sus!4v1649287051334!5m2!1sen!2sus" 
                  width="100%" 
                  height="350" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  title="Princeton, NC Map"
                ></iframe>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 2, background: theme.palette.background.paper }}>
                <Typography 
                  variant="h5" 
                  component="h3" 
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: theme.palette.primary.main
                  }}
                >
                  Contact Us for Princeton Lawn Care
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    2562 US-70, Goldsboro, NC 27530
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    <a href="tel:+19192529130" style={{ color: 'inherit', textDecoration: 'none' }}>
                      (919) 252-9130
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <AccessTimeIcon sx={{ mr: 2, color: theme.palette.primary.main, fontSize: 28 }} />
                  <Typography variant="body1" sx={{ fontSize: '1.05rem' }}>
                    Mon-Fri: 8AM-6PM
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  href="/contact"
                  size="large"
                  sx={{ 
                    py: 1.5, 
                    fontWeight: 600,
                    fontSize: '1.05rem',
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  Request a Free Quote in Princeton
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default PrincetonLocation; 