import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import Hero from '../../components/Hero';
import TestimonialCards from './components/TestimonialCards';
import CallToAction from './components/CallToAction';

const Testimonials2 = () => {
  return (
    <>
      <Helmet>
        <title>Customer Testimonials | Wright Brothers Lawn Care</title>
        <meta
          name="description"
          content="Read what our customers are saying about Wright Brothers Lawn Care. Real reviews from real customers in Wayne and Johnston Counties."
        />
        <meta
          name="keywords"
          content="lawn care reviews, landscaping testimonials, customer feedback, Wright Brothers Lawn Care, Wayne County, Johnston County"
        />
      </Helmet>
      <Main>
        <Hero
          heading="Customer Testimonials"
          subheading="Don't just take our word for it. See what our customers have to say about our lawn care and landscaping services in Wayne and Johnston Counties."
          metaTitle="Customer Testimonials | Wright Brothers Lawn Care"
          metaDescription="Read what our customers are saying about Wright Brothers Lawn Care. Real reviews from real customers in Wayne and Johnston Counties."
          metaKeywords="lawn care reviews, landscaping testimonials, customer feedback, Wright Brothers Lawn Care, Wayne County, Johnston County"
          showForm={false}
        />
        <TestimonialCards />
        <CallToAction />
      </Main>
    </>
  );
};

export default Testimonials2; 