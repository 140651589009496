// CallToAction.js
import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

/**
 * A call-to-action prompting homeowners to get a free estimate or consultation.
 */
const CallToAction = () => {
  return (
    <Box
      component="section"
      aria-labelledby="turf-care-cta"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography
          id="turf-care-cta"
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Get Your Custom Treatment Plan
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
          Let us analyze your yard's specific needs and create a tailored treatment plan that will keep your lawn healthy, vibrant, and weed-free all year round.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/contact"
        >
          Schedule a Soil Analysis
        </Button>
      </Container>
    </Box>
  );
};

export default CallToAction;
