import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Approach = () => {
  return (
    <Box
      component="section"
      aria-labelledby="other-services-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/grading.jpeg"
              alt="Professional landscaping additional services"
              sx={{
                width: '100%',
                height: '350px',
                borderRadius: 2,
                boxShadow: 4,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              id="other-services-approach"
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Comprehensive Landscaping Solutions
            </Typography>
            <Typography variant="body1" paragraph>
              Beyond traditional landscaping services, we offer specialized solutions to address common outdoor challenges. From water management through French drains and downspout systems to yard grading and seeding, our expert team provides comprehensive services to enhance your property's functionality and appearance.
            </Typography>
            <Typography variant="body1">
              Each service is tailored to your specific needs, considering factors like soil type, drainage patterns, and local climate conditions. We focus on long-term solutions that not only solve immediate issues but also prevent future problems, ensuring your outdoor space remains beautiful and functional throughout the seasons.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Approach; 