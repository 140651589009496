// LandscapeMaintenanceServices.js
import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const Examples = () => {
  const theme = useTheme();
  // Landscape maintenance services data array
  const maintenanceServices = [
    {
      title: 'Bush & Tree Pruning',
      description: 'Professional pruning to enhance health, appearance, and overall longevity. We remove dead, diseased, or damaged branches while promoting strong growth and better flowering.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/bush_and_tree_top.jpeg',
    },
    {
      title: 'Mulch Installation',
      description: 'Premium mulch and pine straw installation to improve soil health, conserve moisture, and enhance plant growth while preventing weed growth.',
      image: 'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/mulch_example.jpeg',
    },
  ];

  return (
    <Box
      component="section"
      aria-labelledby="landscape-maintenance-services"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: 'white',
      }}
    >
      <Container>
        <Typography
          id="landscape-maintenance-services"
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 700, mb: 3, textAlign: 'center' }}
        >
          Professional Landscape Maintenance Services
        </Typography>
        <Typography 
          variant="h6" 
          component="p" 
          sx={{ 
            mb: 4, 
            textAlign: 'center', 
            color: '#424242'
          }}
        >
          Comprehensive Care for Your Outdoor Space
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {maintenanceServices.map((service, index) => (
            <Grid item xs={12} sm={10} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="350"
                  image={service.image}
                  alt={service.title}
                  sx={{ objectPosition: 'center bottom' }}
                />
                <CardContent sx={{ flexGrow: 1, padding: 3 }}>
                  <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000000' }}>
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Examples;