/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      {/* Inject SEO metadata into <head> */}
      <Helmet>
        <title>Our Story - Serving Wayne & Johnston Counties</title>
        <meta
          name="description"
          content="Learn about our story, our mission, and how we help businesses in Wayne County (Goldsboro, Mount Olive, Fremont) and Johnston County (Smithfield, Clayton, Selma) bridge the digital gap."
        />
        <meta
          name="keywords"
          content="digital marketing, web design, Wayne County, Johnston County, Goldsboro, Mount Olive, Fremont, Smithfield, Clayton, Selma"
        />
      </Helmet>

      <Box
        component="section"
        aria-label="Company Story and Mission"
        sx={{ paddingY: 4 }}
      >
        <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
          {/* Left section: Text content */}
          <Grid item container alignItems={'center'} xs={12} md={6}>
            <Box>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{ fontWeight: 700 }}
                component="h1" // Best practice: Use an h1 or h2 for top-level headings
              >
                Our Mission
              </Typography>
              <Typography component={'p'} paragraph>
              We are a family and faith-based company that always wants to value our customers, others and the community. This approach is directly tied to Jesus in the Bible in Matthew 7:12, “so in everything, do to others what you would have them do to you.” Our goal is provide a customer first approach and a stress and worry free process. We offer a 100% satisfaction guarantee. We are dedicated to getting the job done the right way, so if ever our work is not up to the standards of our customers, we are happy to correct any issues until you are satisfied. 
              </Typography>
              <Typography component={'p'} paragraph>
                Located in the heart of <strong>Wayne County</strong> and <strong>Johnston County</strong>,
                we proudly serve local businesses throughout <strong>Goldsboro</strong>, <strong>Mount Olive</strong>,
                <strong> Fremont</strong>, <strong>Smithfield</strong>, <strong>Clayton</strong>, <strong>Selma</strong>,
                and beyond. Our focus is always on providing top-notch landscaping services that enhance
                the beauty and functionality of your outdoor spaces, while creating a loyal customer base.
              </Typography>
            </Box>
          </Grid>

          {/* Right section: Image */}
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={6}
          >
            <Box maxWidth={500} width={1}>
              <Box
                component={'img'}
                src={
                  'https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/personalPic.jpeg'
                }
                alt="Illustration showcasing creative consulting and digital marketing concepts"
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Story;
