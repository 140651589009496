  // TurfCareApproach.js
import React from 'react';
import { Box, Grid, Typography, Container, useMediaQuery } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

const TurfCareApproach = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      component="section"
      aria-labelledby="turf-approach"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: (theme) => alpha(theme.palette.grey[200], 0.3),
      }}
    >
      <Container>
        {/* Herbicide/Pesticide Section */}
        <Grid 
          container 
          spacing={4} 
          alignItems="center" 
          sx={{ mb: 6 }}
        >
          {/* Left Column: Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/weed_control.jpeg"
              alt="Professional herbicide and pesticide applications"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 4,
              }}
            />
          </Grid>

          {/* Right Column: Content */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Herbicide/Pesticide Care
            </Typography>
            <Typography variant="body1" paragraph>
              We offer herbicide care for lawns to help control and eliminate those pesky and unwanted weeds in your yard. We provide spraying throughout the year but with a focus in fall and spring applications. Our applications are catered to handle the specific needs of the yard and turf. An application for a more sandy lawn soil with centipede in Walnut Creek will be much different than application in Clayton on a clay based Bermuda yard.
            </Typography>
            <Typography variant="body1" paragraph>
              In addition to herbicide, we also offer pesticides for common yard pests, including fire ants and army worms!
            </Typography>
          </Grid>
        </Grid>

        {/* Fertilizer Section - Reversed Layout */}
        <Grid 
          container 
          spacing={4} 
          alignItems="center" 
          direction={isMd ? 'row' : 'column-reverse'}
        >
          {/* Left Column: Content (on desktop) */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Why Fertilize?
            </Typography>
            <Typography variant="body1" paragraph>
              Just like every human being is unique, every yard is as well. That's why before tackling the deficiencies in the yard we recommend a soil sample of your yard and information about nutrient levels, pH balance, and soil composition, then use the data to determine what your yard needs to thrive. This way we can ensure your yard has the right nutrients in the right amounts!
            </Typography>
            <Typography variant="body1">
              Fertilizer provides essential nutrients that promote healthy, lush, and vibrant grass growth. Fertilizers supply key elements like nitrogen, phosphorus, and potassium, which enhance root development, improve resistance to disease, and encourage thick, green lawns. Regular fertilization helps grass recover from foot traffic, drought, and seasonal stress, making it strong and resilient.
            </Typography>
          </Grid>

          {/* Right Column: Image (on desktop) */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/fert_example.jpeg"
              alt="Professional fertilizer application"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 4,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TurfCareApproach;