import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import Hero from '../../components/Hero';
import CareerInfo from './components/CareerInfo/CareerInfo';
import ApplicationForm from './components/ApplicationForm/ApplicationForm';
import { useTheme } from '@mui/material/styles';

const Careers = () => {
  const theme = useTheme();

  return (
    <Main>
      <Helmet>
        <title>Careers at Wright Brothers Lawn Care | Join Our Team</title>
        <meta
          name="description"
          content="Join our faith-driven landscaping team in Wayne & Johnston Counties. We offer competitive benefits, growth opportunities, and a team-first approach."
        />
        <meta
          name="keywords"
          content="landscaping careers, lawn care jobs, Wayne County NC, Johnston County NC, landscaping employment"
        />
      </Helmet>

      <Hero
        heading="Join Our Team"
        subheading="Be part of a faith-driven company that values its employees and promotes growth through integrity, merit, and leadership."
        metaTitle="Landscaping Careers in Wayne & Johnston County"
        metaDescription="Join our landscaping team. We offer competitive benefits and growth opportunities."
        metaKeywords="Landscaping Jobs, Careers, Wayne County, Johnston County"
        backgroundImage="https://elasticbeanstalk-us-west-2-713881798821.s3.us-west-2.amazonaws.com/Man+Mowing+Golf+Course.jpg"
        showForm={false}
      />

      <Box component="section">
        <CareerInfo />
        <ApplicationForm />
      </Box>
    </Main>
  );
};

export default Careers; 