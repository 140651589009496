import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Container from '../../../components/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const testimonials = [
  {
    id: 1,
    name: 'Kaylyn Wilkins',
    rating: 5,
    date: '1 month ago',
    text: 'Josiah and Brandon were great to work with for our front landscaping! Josiah came the same day I submitted my inquiry and gave a reasonable quote with a fast turn around. Although he said 2 weeks, they came the following week and got it done.'
  },
  {
    id: 2,
    name: 'Michael A. Bell',
    rating: 5,
    date: '3 months ago',
    text: 'Wright Brothers did an incredible job on our landscaping. They removed all of the overgrown shrubs and weeds from our front yard. Installed new landscaping that helped to improve the appearance of our home. They were easy to work with, quick to respond, and had extremely competitive pricing. Highly recommend Wright Brothers.'
  },
  {
    id: 3,
    name: 'Justin Shine',
    rating: 5,
    date: '12 months ago',
    text: 'I wanted to share my experience with Wright Brothers Lawn and Landscaping. I called the main office and received a quick call back to set up a quote. The next day, Josiah came out to my house to talk about what I wanted done. He was fair.'
  },
  {
    id: 4,
    name: 'Rachel Lee Brady',
    rating: 5,
    date: '9 months ago',
    text: 'Huge thanks to the Wright Brothers team for an outstanding sod install and grassy job at our Wake Forest home! They were quick to respond, fair on price, and did a beautiful job. 100% honest and hard working professionals. You won\'t be disappointed.'
  },
  {
    id: 5,
    name: 'Jennifer Chaney',
    rating: 5,
    date: '8 months ago',
    text: 'My yard is ready for spring thanks to the wonderful work done by Wright Brothers! They did an outstanding job and were so professional! Thank you Wright Brothers for making my yard look beautiful!',
    ownerReply: {
      text: 'Thank you for trusting us to complete this work for you!',
      date: '10 months ago'
    }
  },
  {
    id: 6,
    name: 'Candace L. Harding',
    rating: 5,
    date: '3 weeks ago',
    text: 'Wright Brothers Lawn has done a great job with my lawn and water system. They tell the truth and give the best estimates around. They will tell you exactly why you are having trouble with grass growing, such!'
  },
  {
    id: 7,
    name: 'Marc Holloman',
    rating: 5,
    date: '1 year ago',
    text: 'I want to thank Wright Brothers for the exceptional service provided by the Wright Brothers. They efficiently installed my fence, removed pine straw, and expertly trimmed my Crepe Myrtle trees. Their attention to detail was evident, and the results...'
  },
  {
    id: 8,
    name: 'Teresa Cox',
    rating: 5,
    date: '1 year ago',
    text: 'I was at a light fix trying to get my yard straight. I actually found one of their cards in the road when I was driving down the street. I called him and told him my dilemma about my yard. He called me shortly there after with an estimate.',
    ownerReply: {
      text: 'Thank you Teresa! It was a pleasure to complete this job for you! Merry Christmas!',
      date: '1 year ago'
    }
  },
  {
    id: 9,
    name: 'Ken Strickland',
    rating: 5,
    date: '9 months ago',
    text: 'Amazing transformation of our property that has gotten more difficult as we get older. Wright Brothers did an outstanding job of clearing all the unwanted underbrush. The trimming and mulching make the yard look amazing. Thank you for the incredible work.'
  }
];

const TestimonialCards = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 8 },
        backgroundColor: 'background.paper',
      }}
    >
      <Container>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            What Our Customers Say
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            sx={{ mb: 4, color: '#444444' }}
          >
            Real reviews from our satisfied customers in Wayne and Johnston Counties
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {testimonials.map((testimonial) => (
            <Grid item xs={12} sm={6} md={4} key={testimonial.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 2,
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: 4,
                  },
                }}
              >
                <Box sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor: theme.palette.primary.main,
                      mr: 2,
                    }}
                  >
                    {testimonial.name.charAt(0)}
                  </Avatar>
                  <Box>
                    <Typography variant="h6" fontWeight={600}>
                      {testimonial.name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Rating
                        value={testimonial.rating}
                        readOnly
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <Typography variant="body2" sx={{ color: '#444444' }}>
                        {testimonial.date}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <CardContent sx={{ flexGrow: 1, pt: 0 }}>
                  <Typography variant="body1" paragraph sx={{ color: '#444444' }}>
                    {testimonial.text}
                  </Typography>
                  {testimonial.ownerReply && (
                    <Box
                      sx={{
                        mt: 2,
                        p: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight={600}>
                        Response from the owner
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1, color: '#444444' }}>
                        {testimonial.ownerReply.date}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#444444' }}>
                        {testimonial.ownerReply.text}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <Typography
            component="a"
            href="https://www.google.com/search?sca_esv=a166db37d6a3a008&sxsrf=AHTn8zo6F-OZdy82lfXm0aHEDOcNfS-ilg:1741057905263&q=Wright+Brothers+Lawn+%26+Landscaping+LLC+Reviews&sa=X&ved=2ahUKEwji2-ePuu-LAxWIGVkFHR1YA1YQ0bkNegQIMhAE&biw=1900&bih=962&dpr=2#lrd=0x89ac016bdca1d805:0xd5543183652b1113,1,,,,"
            target="_blank"
            rel="noopener noreferrer"
            variant="h6"
            color="primary"
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            See All Reviews on Google
            <Box
              component="svg"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width={20}
              height={20}
              marginLeft={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </Box>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialCards; 