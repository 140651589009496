// src/pages/About/About.js

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

// Import MUI Icons
import PeopleIcon from '@mui/icons-material/People';
import GrassIcon from '@mui/icons-material/Grass';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const About = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Box marginBottom={4} textAlign="center">
        <Typography
          variant="h4"
          color="white"
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          About Wright Brothers Lawn Care
        </Typography>
      </Box>

      {/* Split Layout with Divider */}
      <Grid container spacing={4} alignItems="center">
        {/* Left Side - Company History */}
        <Grid item xs={12} md={5}>
          <Box>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, color: 'white' }}>
              Our Story
            </Typography>
            <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
              At Wright Brothers Lawn Care, we have years of experience providing reliable and affordable lawn care services throughout Wayne County and Clayton.
            </Typography>
            <Typography variant="body1" color="rgba(255, 255, 255, 0.9)" paragraph>
              Our dedicated team is committed to delivering personalized solutions that keep your lawn lush, green, and healthy. We treat your property as if it were our own, ensuring quality care and exceptional customer service.
            </Typography>
            
            {/* Company Name Origin Quote */}
            <Box 
              sx={{ 
                mt: 3, 
                p: 3, 
                bgcolor: 'rgba(255, 255, 255, 0.1)', 
                borderRadius: 2,
                position: 'relative'
              }}
            >
              <FormatQuoteIcon 
                sx={{ 
                  position: 'absolute', 
                  top: -15, 
                  left: 10, 
                  color: theme.palette.primary.light,
                  fontSize: 40 
                }} 
              />
              <Typography variant="body1" color="rgba(255, 255, 255, 0.95)" sx={{ fontStyle: 'italic' }}>
                Did you know? Wright Brothers Lawn Care was not their original name when it first started. It was originally named "Wright Lawn Care" but through their dad's guidance, they changed the name to "Wright Brothers Lawn Care" given they are "the Wright Brothers".
              </Typography>
            </Box>
          </Box>
        </Grid>
        
        {/* Center Divider */}
        <Grid item xs={12} md={2} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
          <Divider orientation="vertical" sx={{ 
            height: '100%', 
            minHeight: '300px',
            borderColor: 'rgba(255, 255, 255, 0.5)', 
            borderRightWidth: 2,
            mx: 'auto'
          }} />
        </Grid>
        
        {/* Right Side - Values and Image */}
        <Grid item xs={12} md={5}>
          <Box>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, color: 'white' }}>
              Our Values
            </Typography>
            
            {/* Values Grid */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.light, mr: 2 }}>
                    <PeopleIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="white" fontWeight={600}>
                      Expert Team
                    </Typography>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.9)">
                      Our team consists of certified professionals with years of experience.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.light, mr: 2 }}>
                    <GrassIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="white" fontWeight={600}>
                      Quality Craftsmanship
                    </Typography>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.9)">
                      We take pride in our attention to detail and commitment to excellence in every job.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ bgcolor: theme.palette.primary.light, mr: 2 }}>
                    <ThumbUpIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="white" fontWeight={600}>
                      Customer Focused
                    </Typography>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.9)">
                      We prioritize our customers' needs, ensuring personalized service.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
